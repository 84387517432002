import React, { createContext, useEffect, useReducer, useState } from 'react'

import PropTypes from 'prop-types'
import { authReducer } from './authReducer'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'

const jwt = require('jsonwebtoken')

const DO_LOGIN = gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password)
  }
`

export const AuthContext = createContext(null)

export const AuthProvider = ({ children, navigate }) => {
  const [executeLogin, { data, error }] = useMutation(DO_LOGIN)

  // Holds authentication state
  const [state, dispatch] = useReducer(authReducer, {
    user: {},
    expiresAt: null,
    isAuthenticating: false
  })

  const createAuthResult = token => {
    const payload = jwt.decode(token)
    if (!payload) {
      throw new Error('Unauthorized')
    }
    if (Date.now() >= payload.exp * 1000) {
      throw new Error('Unauthorized')
    }
    return {
      token,
      ...payload
    }
  }

  const checkSession = () => {
    // get token from window.localStorage and call account API
    // return token && WTD decoded token as in https://jwt.io
    const token = localStorage.getItem('authorization')
    if (!token) {
      throw new Error('Unauthorized')
    }
    return createAuthResult(token)
  }

  const login = ({ email, password, token }) => {
    //    console.log('login', { email, password, token })
    doDispatch({ type: 'setIsAuthenticating' })
    if (token) {
      dispatch({
        type: 'login',
        authResult: createAuthResult(token)
      })
      return doDispatch({ type: 'clearIsAuthenticating' })
    }
    if (email && password) {
      executeLogin({ variables: { email, password } })
    } else {
      doDispatch({ type: 'clearIsAuthenticating' })
      dispatch({ type: 'error', error: 'username or password not provided' })
    }
  }
  const logout = () => {}

  const authApi = {
    login,
    logout,
    checkSession
  }
  const [contextValue, setContextValue] = useState({
    state,
    dispatch,
    navigate,
    authApi
  })

  const doDispatch = dispatch

  // Update context value and trigger re-render
  // This patterns avoids unnecessary deep renders
  // https://reactjs.org/docs/context.html#caveats
  /* eslint-disable */
  useEffect(() => {
    setContextValue({
      ...contextValue,
      state,
      authApi
    })
  }, [state])

  // Verify user is logged-in on AuthProvider mount
  // Avoids storing sensitive data in local storage
  /* eslint-disable */
  useEffect(() => {
    // dispatch({
    //   type: 'toggleAuthenticating'
    // })
    try {
      const authResult = checkSession()
      // dispatch({
      //   type: 'toggleAuthenticating'
      // })
      dispatch({
        type: 'login',
        authResult
      })
    } catch (err) {
      // console.error(err)
      dispatch({
        type: 'error',
        errorType: 'checkSession',
        error: err
      })
    }
  }, [])

  useEffect(() => {
    if (error) {
      dispatch({
        type: 'error',
        errorType: 'login',
        error
      })
      dispatch({
        type: 'clearIsAuthenticating'
      })
    } else if (data) {
      const authResult = createAuthResult(data.login)
      dispatch({
        type: 'clearIsAuthenticating'
      })
      dispatch({
        type: 'login',
        authResult
      })
    }
  }, [data, error])

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  )
}

AuthProvider.propTypes = {
  children: PropTypes.element,
  navigate: PropTypes.func
}
