import React from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  table: {
    overflow: 'visible'
    // minWidth: 650
  },
  indent: {
    paddingLeft: 30
  },
  headerCell: { fontWeight: 'bold' }
})

export default function DataTable({ data, fields, alignHeaders }) {
  const classes = useStyles()
  function renderField(field) {
    const getValue = () =>
      field.attr.split('.').reduce((acc, attr) => acc && acc[attr], data)

    return (
      <TableRow key={field.label}>
        <TableCell
          className={clsx(
            classes.headerCell,
            field.indent ? classes.indent : ''
          )}
          colSpan={field.subtitle ? 2 : 1}
          align={alignHeaders}
        >
          {field.subtitle || field.label}
        </TableCell>
        {!field.subtitle && <TableCell>{getValue()}</TableCell>}
      </TableRow>
    )
  }
  return (
    <>
      <Table className={classes.table} aria-label='profile table'>
        <TableBody>{fields.map(renderField)}</TableBody>
      </Table>
    </>
  )
}
