const { XDN_PLANS } = require('./plans')

const regionToDORegionMap = {
  nyc: 'nyc3',
  ams: 'ams3',
  sf: 'sfo3',
  sgp: 'sgp1',
  london: 'lon1',
  fra: 'fra1',
  tor: 'tor1',
  blr: 'blr1'
}

const statusEnum = [
  'unlaunched',
  'launching',
  // Terraform status
  'pending', 'planning', 'planned_and_finished', 'planned', 'confirmed', 'applying', 'applied', 'errored',
  // Stream Manager status
  'configuring', 'starting', 'launched', 'restarting']

const statusMap = (list => {
  let map = {}
  list.forEach(item => map[item] = item)
  return map
})(statusEnum)

module.exports = {
  statuses: statusMap,
  regionToDigitalOceanRegion: (region) => {
    return regionToDORegionMap[region]
  },
  generateXDNTag: () => {
    return `xdn-${Math.floor(Math.random() * 0x10000).toString(16)}`
  },
  instanceTypeFromRecommendation: (rec) => {
    const lowerRec = rec.toLowerCase()
    if (lowerRec === XDN_PLANS.advanced.title ||
      lowerRec === XDN_PLANS.custom.title) {
      return 'c-4'
    }
    return 'c-2'
  },
  trafficVarianceFromRecommendation: (rec) => {
    const lowerRec = rec.toLowerCase()
    if (lowerRec === XDN_PLANS.advanced.title ||
      lowerRec === XDN_PLANS.custom.title) {
      return 'high'
    }
    return 'medium'
  },
  xdnStatusIsUnlaunched: (xdn) => {
    return xdn.status === 'unlaunched'
  }
}
