import { Error, Loading } from '../components/Toast'
import {
  GET_RESET_PASSWORD_TOKEN_STATUS,
  RESET_PASSWORD
} from '../models/account-api'
import React, { useState } from 'react'
import { useMutation, useQuery } from '@apollo/react-hooks'

import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import { Redirect } from 'react-router-dom'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { useToasts } from '../components/toasts'

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: "128px",
    marginLeft: "25%",
    marginRight: "auto",
    width: "20vw",
    padding: theme.spacing(2)
  },
  header: {
    borderBottom: `1px solid ${theme.palette.secondary[100]}`
  },
  actions: {
    display: 'flex',
    flexDirection: 'column'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '100%'
  },
  dense: {
    marginTop: 19
  },
  button: {
    margin: theme.spacing(1)
  }
}))

export default function PasswordReset({ match }) {
  const { addToast } = useToasts()
  const classes = useStyles({})
  const { email, token } = match.params
  const { loading, error, data } = useQuery(GET_RESET_PASSWORD_TOKEN_STATUS, {
    variables: { email: decodeURIComponent(email), token }
  })
  const [resetPassword, resetPasswordResponse] = useMutation(RESET_PASSWORD)
  const [password, setPassword] = useState('')
  const [password2, setPassword2] = useState('')

  if (loading || resetPasswordResponse.loading)
    return <Loading open={loading} />
  if (error) return <Error message={error.message} open={true} />
  if (resetPasswordResponse.error)
    return <Error message={resetPasswordResponse.error} open={true} />

  if (!data.getPasswordResetTokenStatus) {
    // invalid reset token
    return <Redirect to='/login' />
  }
  if (resetPasswordResponse.data) {
    console.log('password changed')
    addToast({
      message: 'Password changed',
      variant: 'success',
      open: true,
      horizontal: 'right',
      vertical: 'top'
    })
    return <Redirect to='/login' />
  }
  function handleSaveClicked() {
    if(!/^(\w+\S+)$/.test(password) || !/^(\w+\S+)$/.test(password2)) {
      alert("Fields can not be left blank")
    }else if(password !== password2){
      alert("Passwords must match")
    }
    else {
      resetPassword({
        variables: {
          email: decodeURIComponent(email),
          token,
          password
        }
      })
    }

  }
  return (
    <Card className={classes.container}>
      <CardContent>
        <Box className={classes.header}>
          <Typography variant='h5'>Reset Password</Typography>
        </Box>
        <form noValidate autoComplete='off'>
          <TextField
            label='New password'
            value={password}
            onChange={e => setPassword(e.target.value)}
            className={classes.textField}
            margin='normal'
            type='password'
            required={true}
          />
          <TextField
            label='Confirm new password'
            value={password2}
            onChange={e => setPassword2(e.target.value)}
            className={classes.textField}
            margin='normal'
            type='password'
            required={true}
          />
        </form>
      </CardContent>
      <CardActions className={classes.actions}>
        <Button
          variant='contained'
          color='primary'
          fullWidth
          className={classes.button}
          onClick={handleSaveClicked}
        >
          Reset password
        </Button>
      </CardActions>
    </Card>
  )
}
