import { Error, Info, Loading } from '../../components/Toast'
import { GET_USER, UPDATE_USER } from '../../models/account-api'
import React, { useState } from 'react'
import { useMutation, useQuery } from '@apollo/react-hooks'

import Box from '@material-ui/core/Box'
import DataView from '../DataView'
import Typography from '@material-ui/core/Typography'
import { useAuth } from '../../auth/useAuth'

const R = require('ramda')

export default function ProfileView() {
  const { user } = useAuth()
  const [error, setError] = useState()
  const [updateUser, updateUserResponse] = useMutation(UPDATE_USER)
  const getUserResponse = useQuery(GET_USER, {
    variables: { id: user.id }
  })
  if (getUserResponse.loading) return <Loading open={getUserResponse.loading} />

  const fields = [
    {
      label: 'First Name',
      attr: 'firstName',
      required: true
    },
    {
      label: 'Last Name',
      attr: 'lastName',
      required: true
    },
    {
      label: 'Email',
      attr: 'email',
      required: true
    },
    {
      label: 'Organization name',
      attr: 'organization.name',
      readOnly: true
    },
    {
      label: 'Organization website',
      attr: 'organization.website',
      readOnly: true
    }
  ]
  const passwordFields = [
    {
      label: 'New password',
      attr: 'password',
      required: true,
      type: 'password'
    },
    {
      label: 'Confirm new password',
      attr: 'password2',
      required: true,
      type: 'password'
    },
    {
      label: 'Your old password',
      attr: 'oldPassword',
      required: true,
      type: 'password'
    }
  ]

  function handleOnSave(profile) {
    updateUser({
      variables: {
        ...R.omit(['__typename'], profile)
      }
    })
  }
  function handleUpdatePassword({ password, password2, oldPassword }) {
    console.log('Updating password')
    if (password !== password2) {
      setError({ message: "Passwords don't match" })
    } else {
      setError(null)
      const user = getUserResponse.data.user
      updateUser({
        variables: {
          id: user.id,
          email: user.email,
          password
        }
      })
    }
  }

  if (updateUserResponse.error) {
    return (
      <Error
        message={`Failed to update: ${updateUserResponse.error.message}`}
        open={!!updateUserResponse.error}
      />
    )
  }
  if (updateUserResponse.data) {
    console.log('user updated', updateUserResponse.data.updateUser)
  }
  return (
    <div>
      <Typography variant='h5' gutterBottom>
        Profile
      </Typography>

      <Box mt={3}>
        <DataView
          data={getUserResponse.data.user}
          fields={fields}
          onSave={handleOnSave}
        />
        <Box mt={2}>
          <DataView
            title='Password'
            onlyEdit={true}
            edit={!!error}
            data={{}}
            fields={passwordFields}
            onSave={handleUpdatePassword}
            editLabel='Change password'
            saveLabel='Save new password'
          />
        </Box>
        <Error
          message={`Oops! Something went wrong: ${getUserResponse.error}`}
          open={!!getUserResponse.error}
        />
        <Error
          message={`${error && error.message}`}
          open={!!error}
          onClose={() => setError(null)}
        />
        <Info message='Saving...' open={updateUserResponse.loading} />
      </Box>
    </div>
  )
}
