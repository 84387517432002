import React from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import { getValue } from '../../models/utils'
import { makeStyles } from '@material-ui/core/styles'

function EnhancedTableHead({ headCells }) {
  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell align={headCell.align || 'left'} key={headCell.attr}>
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(2),
    padding: 0
  },
  table: {},
  tableWrapper: {
    overflowX: 'auto'
  }
}))

export default function PagedTable({
  rows,
  headCells,
  page,
  setPage,
  perPage,
  totalRows
}) {
  const classes = useStyles()

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }
  const emptyRows = 1
  // const emptyRows =
  //   rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage)

  return (
    <div className={classes.root}>
      <div className={classes.tableWrapper}>
        <Table
          className={classes.table}
          aria-labelledby='tableTitle'
          size={'medium'}
        >
          <EnhancedTableHead headCells={headCells} />
          <TableBody>
            {rows.map((row, index) => {
              return (
                <TableRow hover role='checkbox' tabIndex={-1} key={row._id}>
                  {headCells.map(h => (
                    <>
                      <TableCell align={h.align || 'left'}>
                        {h.render
                          ? h.render({
                              value: getValue(h.attr, row),
                              id: row._id
                            })
                          : getValue(h.attr, row)}
                      </TableCell>
                    </>
                  ))}
                </TableRow>
              )
            })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <TablePagination
        component='div'
        count={totalRows}
        rowsPerPageOptions={[]}
        rowsPerPage={perPage}
        page={page}
        backIconButtonProps={{
          'aria-label': 'previous page'
        }}
        nextIconButtonProps={{
          'aria-label': 'next page'
        }}
        onChangePage={handleChangePage}
      />
    </div>
  )
}
