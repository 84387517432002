import React, {useRef, useEffect, useState} from "react";
import {Grid, Button} from '@material-ui/core/';
import {red} from '@material-ui/core/colors';
import {Box} from "@material-ui/core";
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import {makeStyles, withStyles} from "@material-ui/core/styles";
import Switch from '@material-ui/core/Switch';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import { Redirect } from 'react-router-dom'
import { XDN_PLANS } from '../../models/plans'

// Assets as components imports
// import {ReactComponent as OnePerson} from "../../assets/img/svg/Single Person.svg";
// import {ReactComponent as ManyPerson} from "../../assets/img/svg/Many Person.svg";
// import {ReactComponent as Cloud} from "../../assets/img/svg/Group 48289.svg";
import {ReactComponent as Three20} from "../../assets/img/svg/320.svg";
import {ReactComponent as Six40} from "../../assets/img/svg/640.svg";
import {ReactComponent as Seven20} from "../../assets/img/svg/720.svg";
import {ReactComponent as Ten80} from "../../assets/img/svg/1080.svg";
import {ReactComponent as FourK} from "../../assets/img/svg/4k.svg";

import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from "@material-ui/core/FormLabel";

import "./xdnStyles.css";
import PricingCell from "../../components/PricingPageComponents/PricingCell";
import TableSpacer from "../../components/PricingPageComponents/TableSpacer";
import FlowProgressBar from "../../components/FlowProgressBar";
const RedCheckbox = withStyles({
    root: {
        color: red[400],
        '&$checked': {
            color: red[600],
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

const RedSwitch = withStyles({
    switchBase: {
        color: "#f9f9f9",
        '&$checked': {
            color: red[600],
        },
        '&$checked + $track': {
            backgroundColor: red[500],
        },
    },
    checked: {},
    track: {},
})(Switch);

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 400,
        fontSize: theme.typography.pxToRem(20),
        border: '1px solid #DB1F29',
    },
}))(Tooltip);

const tooltipTitles = [
    'Price per month annually',
    'Approximate for 720p video',
    'Maximum',
    'Gigabytes',
    'Per GB, beyond plan include',
    'Per hour',
    'Per Gigabyte',
    'Per Region',
]

const StarterPrices = [
    '$99',
    '30',
    '1,500',
    '100',
    '10',
    '1,000',
    '1',
    '✓',
    '✓',
    '✓',
    '-',
    '-',
    '-',
    '-',
    '-',
    '-',
    '✓',
    '-',
    '-',
    '-',
    '$0.09',
    'Not Available',
    '$0.25',
    '$349',
    '-',
    '-',
]

const StandardPrices = [
    '$279',
    '100',
    '5,000',
    '250',
    '25',
    '3,000',
    '4',
    '✓',
    '✓',
    '✓',
    '✓',
    '✓',
    '✓',
    '-',
    '-',
    '-',
    '✓',
    '✓',
    '-',
    '-',
    '$0.07',
    '$0.11',
    '$0.16',
    '$349',
    '-',
    '$79',
]

const AdvancedPrices = [
    '$499',
    '300',
    '15,000',
    'Unlimited',
    'Unlimited',
    '10,000',
    '7',
    '✓',
    '✓',
    '✓',
    '✓',
    '✓',
    '✓',
    '-',
    '-',
    '-',
    '✓',
    '✓',
    '✓',
    '-',
    '$0.05',
    '$0.10',
    '$0.10',
    '$349',
    '$495',
    '$69',
]

const CustomPrices = [
    '$1,999',
    '1,500',
    '75,000',
    'Unlimited',
    'Unlimited',
    '50,000',
    '18',
    '✓',
    '✓',
    '✓',
    '✓',
    '✓',
    '✓',
    '✓',
    '✓',
    '✓',
    '✓',
    '✓',
    '✓',
    '✓',
    '$0.045',
    '$0.08',
    '$0.09',
    '$349',
    '$495',
    '$59',
]

const useStyles = makeStyles({
    arrow: {
        fontSize: 20,
        color: "#DB1F29",
        "&::before": {
            backgroundColor: "#DB1F29",
            border: "2px solid #DB1F29"
        }
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: "#F1F1F1",
        border: '2px solid #000',
        boxShadow: "0px 5.41176px 37.8824px rgba(0, 0, 0, 0.1)",
        padding: "2%",
    },
    backgroundGrey: {
        background: "#FAFAFA",
        width: "100vw !important",
    },
    width: {
        paddingTop: "10px",
        background: "#FAFAFA",
        width: "100vw !important",
    },
    pricingP: {
        borderBottom: "1px solid rgba(196, 196, 196, .25)",
        padding: "5% 10% 5% 10%",
        margin: "auto 10% auto 10%",
        fontFamily: "'Lato', sans-serif !important",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "16px",
        lineHeight: "32px",
        letterSpacing: "0.02em",
        textTransform: "capitalize",
        color: "#000000",
    },
    buttonStyle1: {
        // margin: "0 auto 0 calc((650px - 550px) / 2)",
        marginTop: "10px",
        width: "80%",
        backgroundColor: "#DB1F26",
        border: "#DB1F26",
        fontFamily: "Lato",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "16px",
        lineHeight: "40px",
        textAlign: "center",
        letterSpacing: "0.05em",
        textTransform: "uppercase",
        color: "#FFFFFF",
        '&:hover': {
            backgroundColor: "rgba(219, 31, 38, .8)",
            border: "rgba(219, 31, 38, .8)",
        }
    },
    buttonStyle3: {
        marginTop: "10px",
        marginLeft: "5%",
        width: "90%",
        backgroundColor: "transparent",
        border: "transparent",
        color: "transparent",
        fontFamily: "Lato",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "16px",
        lineHeight: "40px",
        textAlign: "center",
        letterSpacing: "0.05em",
        textTransform: "uppercase",
        '&:hover': {
            backgroundColor: "rgba(219, 31, 38, .8)",
            border: "rgba(219, 31, 38, .8)",
        }
    },
    buttonStyleInternal: {
        width: "100%",
        height: "100%",
        backgroundColor: "#DB1F26",
        border: "#DB1F26",
        fontFamily: "Lato",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "16px",
        lineHeight: "40px",
        textAlign: "center",
        letterSpacing: "0.05em",
        textTransform: "uppercase",
        color: "#FFFFFF",
        '&:hover': {
            backgroundColor: "rgba(219, 31, 38, .8)",
            border: "rgba(219, 31, 38, .8)",
        }
    },
    sliderHyperlink: {
        fontFamily: "Lato",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "18px",
        lineHeight: "40px",
        color: "#DB1F26",
        cursor: "pointer",
        textDecoration: "underline",
        '&:hover': {
            color: "rgba(219, 31, 38, .8)",
        }
    }
});

let nodes = 1;
let egress = parseInt(localStorage.getItem("egress"));
let ingest = parseInt(localStorage.getItem("ingest"));
let bandwidthTotals = ingest + egress;

//scaled slider marks
const streamingMarks = [
    {
        value: 0,
        scaledValue: "1",
        label: '1',
    },
    {
        value: 25,
        scaledValue: "5",
        label: '5',
    },
    {
        value: 50,
        scaledValue: "10",
        label: '10',
    },
    {
        value: 75,
        scaledValue: "25",
        label: '25',
    },
    {
        value: 100,
        scaledValue: "50",
        label: '50',
    },
    {
        value: 125,
        scaledValue: "100",
        label: '100+',
    },
];

const durationMarks = [
    {
        value: 0,
        scaledValue: "5",
        label: '5min',
    },
    {
        value: 25,
        scaledValue: "30",
        label: '30min',
    },
    {
        value: 50,
        scaledValue: "60",
        label: '1hr',
    },
    {
        value: 75,
        scaledValue: "90",
        label: '1.5hr',
    },
    {
        value: 100,
        scaledValue: "120",
        label: '2hr',
    },
    {
        value: 125,
        scaledValue: "180",
        label: '3hr',
    },
    {
        value: 150,
        scaledValue: "240",
        label: '4hr',
    },
    {
        value: 175,
        scaledValue: "480",
        label: '8hr+',
    },
];

const pubMarks = [
    {
        value: 0,
        scaledValue: "1",
        label: '1',
    },
    {
        value: 25,
        scaledValue: "2",
        label: '2',
    },
    {
        value: 50,
        scaledValue: "3",
        label: '3',
    },
    {
        value: 75,
        scaledValue: "4",
        label: '4',
    },
    {
        value: 100,
        scaledValue: "5",
        label: '5',
    },
    {
        value: 125,
        scaledValue: "10",
        label: '10',
    },
    {
        value: 150,
        scaledValue: "25",
        label: '25',
    },
    {
        value: 175,
        scaledValue: "50",
        label: '50',
    },
    {
        value: 200,
        scaledValue: "100",
        label: '100+',
    },
];

const subMarks = [
    {
        value: 0,
        scaledValue: "1",
        label: '1',
    },
    {
        value: 25,
        scaledValue: "5",
        label: '5',
    },
    {
        value: 50,
        scaledValue: "100",
        label: '100',
    },
    {
        value: 75,
        scaledValue: "500",
        label: '500',
    },
    {
        value: 100,
        scaledValue: "1000",
        label: '1000',
    },
    {
        value: 125,
        scaledValue: "5000",
        label: '5000',
    },
    {
        value: 150,
        scaledValue: "10000",
        label: '10000',
    },
    {
        value: 175,
        scaledValue: "50000",
        label: '50000',
    },
    {
        value: 200,
        scaledValue: "100000",
        label: '100000+',
    },
];

let scaledDurationValue = 0;

//scaled value returns
const streamScale = value => {
    const previousMarkIndex = Math.floor(value / 25);
    const previousMark = streamingMarks[previousMarkIndex];
    const remainder = value % 25;
    if (remainder === 0) {
        return previousMark.scaledValue;
    }
    const nextMark = streamingMarks[previousMarkIndex + 1];
    const increment = (nextMark.scaledValue - previousMark.scaledValue) / 25;
    return remainder * increment + previousMark.scaledValue;
};

const durationScale = value => {
    const previousMarkIndex = Math.floor(value / 25);
    const previousMark = durationMarks[previousMarkIndex];
    const remainder = value % 25;
    if (remainder === 0) {
        return previousMark.scaledValue;
    }
    const nextMark = durationMarks[previousMarkIndex + 1];
    const increment = (nextMark.scaledValue - previousMark.scaledValue) / 25;
    scaledDurationValue = remainder * increment + previousMark.scaledValue;
    return scaledDurationValue;
};

const pubScale = value => {
    const previousMarkIndex = Math.floor(value / 25);
    const previousMark = pubMarks[previousMarkIndex];
    const remainder = value % 25;
    if (remainder === 0) {
        return previousMark.scaledValue;
    }
    const nextMark = pubMarks[previousMarkIndex + 1];
    const increment = (nextMark.scaledValue - previousMark.scaledValue) / 25;
    return remainder * increment + previousMark.scaledValue;
};

const subScale = value => {
    const previousMarkIndex = Math.floor(value / 25);
    const previousMark = subMarks[previousMarkIndex];
    const remainder = value % 25;
    if (remainder === 0) {
        return previousMark.scaledValue;
    }
    const nextMark = subMarks[previousMarkIndex + 1];
    const increment = (nextMark.scaledValue - previousMark.scaledValue) / 25;
    return remainder * increment + previousMark.scaledValue;
};

//formatted text values
function streamingText(value) {
    return `${value}`;
}

function durationText(scaledDurationValue) {
    return `${scaledDurationValue}`;
}

function pubText(value) {
    return `${value}`;
}

function subText(value) {
    return `${value}`;
}

function valueLabel(scaledDurationValue) {

    let minHours = ""
    let hours = Math.floor(scaledDurationValue / 60);
    let minutes = scaledDurationValue % 60;

    if (minutes === 0) {
        minHours = hours + "hr"
    } else {
        minHours = hours + "hr " + minutes
    }

    return `${minHours}`
}

function strToBool(str) {
    let boolStr = (str === 'true');
    return boolStr;
}

export default function PricingPage({history}) {

    const yes = true
    const no = false

    const [checked320, setChecked320] = React.useState(no)
    const [checked640, setChecked640] = React.useState(no)
    const [checked720, setChecked720] = React.useState(yes)
    const [checked1080, setChecked1080] = React.useState(no)
    const [checked4k, setChecked4k] = React.useState(no)
    const [abr, setAbr] = React.useState(strToBool(localStorage.getItem("abr")))
    const [sdk, setSdk] = React.useState(strToBool(localStorage.getItem("sdk")))
    const [archive, setArchive] = React.useState(strToBool(localStorage.getItem("archive")))
    const [mixer, setMixer] = React.useState(strToBool(localStorage.getItem("mixer")))
    const [recommendedPlan, setRecommendedPlan] = React.useState(localStorage.getItem("recommendedPlan"))
    const [streamingEvents, setStreamingEvents] = React.useState(parseInt(localStorage.getItem("streamingEvents")))
    const [duration, setDuration] = React.useState(parseInt(localStorage.getItem("duration")))
    const [publishers, setPublishers] = React.useState(parseInt(localStorage.getItem("publishers")))
    const [subscribers, setSubscribers] = React.useState(parseInt(localStorage.getItem("subscribers")))
    const [liveStreamingHours, setLiveStreamingHours] = React.useState(parseInt(localStorage.getItem("liveStreamingHours")))
    const [viewerHours, setViewerHours] = React.useState(parseInt(localStorage.getItem("viewerHours")))
    const [bandwidthTotal, setBandwidthTotal] = React.useState(parseInt(localStorage.getItem("bandwidthTotal")))
    const [egress, setEgress] = React.useState(parseInt(localStorage.getItem("egress")))
    const [ingest, setIngest] = React.useState(parseInt(localStorage.getItem("ingest")))
    const [basicFee, setBasicFee] = React.useState(parseInt(localStorage.getItem("basicFee")))
    const [additionalHours, setAdditionalHours] = React.useState(parseInt(localStorage.getItem("additionalHours")))
    const [additionalBandwidth, setAdditionalBandwidth] = React.useState(parseInt(localStorage.getItem("additionalBandwidth")))
    const [additionalEgress, setAdditionalEgress] = React.useState(parseInt(localStorage.getItem("additionalEgress")))
    const [sdkPrice, setSdkPrice] = React.useState(parseInt(localStorage.getItem("sdkPrice")))
    const [archivePrice, setArchivePrice] = React.useState(parseInt(localStorage.getItem("archivePrice")))
    const [mixerPrice, setMixerPrice] = React.useState(parseInt(localStorage.getItem("mixerPrice")))
  const [cost, setCost] = React.useState(parseInt(localStorage.getItem("cost")))

    //REQUIRED FOR PROGRESS BAR
    const progressArrowArray = [true, false, false, false, false, false, false]
    const progressActiveArray = [1, 0, 0, 0, 0, 0]

    const error = [checked320, checked640, checked720, checked1080, checked4k].filter((v) => v).length < 1;

  const [readyToRegister, setReadyToRegister] = useState(false)

  useEffect(() => {
    if (!readyToRegister) {
      recommendedPlanCalc()
    }
  })

  const onRegister = plan => {
    const selectedPlan = plan || recommendedPlan
    setRecommendedPlan(selectedPlan)
    storageUpdate('recommendedPlan', plan)
    setReadyToRegister(true)
  }

    const handleChecked320 = (event) => {
        if (checked320) {
            setChecked320(no)

        } else {
            setChecked320(yes)

        }
        recommendedPlanCalc()
    };
    const handleChecked640 = (event) => {
        if (checked640) {
            setChecked640(no)

        } else {
            setChecked640(yes)

        }
        recommendedPlanCalc()
    };
    const handleChecked720 = (event) => {
        if (checked720) {
            setChecked720(no)

        } else {
            setChecked720(yes)

        }
        recommendedPlanCalc()

    };
    const handleChecked1080 = (event) => {
        if (checked1080) {
            setChecked1080(no)

        } else {
            setChecked1080(yes)

        }
        recommendedPlanCalc()
    };
    const handleChecked4k = (event) => {
        if (checked4k) {
            setChecked4k(no)

        } else {
            setChecked4k(yes)

        }
        recommendedPlanCalc()

    };
    const handleAbr = (event) => {
        if (abr === false) {
            setAbr(yes)
            storageUpdate("abr", true)
            liveStreamingHourCalc()

        } else {
            setAbr(no)
            storageUpdate("abr", false)
            liveStreamingHourCalc()

        }
        recommendedPlanCalc()
    };
    const handleSdk = (event) => {
        if (sdk === true) {
            setSdk(no)
            storageUpdate("sdk", false)
            setSdkPrice(0)
            storageUpdate("sdkPrice", "0")

        } else {
            setSdk(yes)
            storageUpdate("sdk", true)
            setSdkPrice(349)
            storageUpdate("sdkPrice", "349")
        }
        recommendedPlanCalc()
    };

    function archiveNumCalc(archiveNum) {
        if (archive) {
            if (recommendedPlan.toLowerCase() === XDN_PLANS.starter.title) {
                archiveNum = ingest *.25
                setArchivePrice(archiveNum)
                storageUpdate("archivePrice", "" + archivePrice)
            } else if (recommendedPlan.toLowerCase() === XDN_PLANS.standard.title) {
                archiveNum = ingest *.16
                setArchivePrice(archiveNum)
                storageUpdate("archivePrice", "" + archivePrice)
            } else if (recommendedPlan.toLowerCase() === XDN_PLANS.advanced.title) {
                archiveNum = ingest *.1
                setArchivePrice(archiveNum)
                storageUpdate("archivePrice", "" + archivePrice)
            } else {
                archiveNum = ingest *.09
                setArchivePrice(archiveNum)
                storageUpdate("archivePrice", "" + archivePrice)
            }
        } else {
            archiveNum = 0
            setArchivePrice(archiveNum)
            storageUpdate("archivePrice", "" + archivePrice)
        }
        return archiveNum;
    }

    const handleArchive = (event) => {
        let archiveNum = 0;
        if (archive === true) {
            setArchive(no)
            storageUpdate("archive", false)
            setArchivePrice(archiveNum)
            storageUpdate("archivePrice", "0")
        } else {
            setArchive(yes)
            storageUpdate("archive", true)
            archiveNum = archiveNumCalc(archiveNum);
            setArchivePrice(archiveNum)
            storageUpdate("archivePrice", ""+archiveNum)
            setCost(cost+archiveNum)
        }
        recommendedPlanCalc()
    };
    const handleMixer = (event) => {
        if (mixer === true) {
            setMixer(no)
            storageUpdate("mixer", false)
            setMixerPrice(0)
            storageUpdate("mixerPrice", "0")
        } else {
            setMixer(yes)
            storageUpdate("mixer", true)
            setMixerPrice(495)
            storageUpdate("mixerPrice", "495")
        }
        recommendedPlanCalc()
    };

    const checkStorage = () => {
        if (localStorage.getItem("abr") === "true") {
            setAbr(yes)
        } else {
            setAbr(no)
        }
        if (localStorage.getItem("sdk") === "true") {
            setSdk(yes)
        } else {
            setSdk(no)
        }
        if (localStorage.getItem("archive") === "true") {
            setArchive(yes)
        } else {
            setArchive(no)
        }
        if (localStorage.getItem("mixer") === "true") {
            setMixer(yes)
        } else {
            setMixer(no)
        }
    }

    if (error) {
        alert("Please select one resolution!")
    }

    const storageUpdate = (key, value) => {
        localStorage.removeItem(key)
        localStorage.setItem(key, value)
    };

    const recommendEvents = (event, value) => {
        setStreamingEvents(value)
        storageUpdate("streamingEvents", value)

        recommendedPlanCalc()
    }

    const recommendDuration = (event, value) => {
        setDuration(value)
        storageUpdate("duration", value)

        recommendedPlanCalc()
    }

    const recommendPub = (event, value) => {
        setPublishers(value)
        storageUpdate("publishers", value)

        recommendedPlanCalc()
    }

    const recommendSub = (event, value) => {
        setSubscribers(value)
        storageUpdate("subscribers", value)
        recommendedPlanCalc()
    }

    const liveStreamingHourCalc = () => {

        let transferRate = .0001875
        if (checked320 === true) {
            transferRate = .000025
        }
        if (checked640 === true) {
            transferRate = .0000625
        }
        if (checked720 === true) {
            transferRate = .0001875
        }
        if (checked1080 === true) {
            transferRate = .0005625
        }
        if (checked4k === true) {
            transferRate = .001875
        }

        storageUpdate("bandwidthHour", transferRate)

        let archiveNum = 0
        let durationNum = parseInt(durationScale(localStorage.getItem("duration")))
        let streamingEventsNum = parseInt(streamScale(localStorage.getItem("streamingEvents")))
        let publishersNum = parseInt(pubScale(localStorage.getItem("publishers")))
        let subscribersNum = parseInt(subScale(localStorage.getItem("subscribers")))
        // let CDNBandwidths = 0

        let liveStreamingHoursNum = streamingEventsNum * durationNum

        let hours = liveStreamingHoursNum / 60;
        let viewerHoursNum = hours * subscribersNum
        let egress = Math.ceil(liveStreamingHoursNum * publishersNum * subscribersNum * 3600 * transferRate);
        let ingest = Math.ceil(liveStreamingHoursNum * publishersNum * 3600 * transferRate);
        // console.log(abr)
        if (abr) {
            ingest *= 3
        }
        if (archive) {
            archiveNum = archiveNumCalc(archiveNum)
        }
        storageUpdate("egress", egress)
        setEgress(egress)
        storageUpdate("ingest", ingest)
        setIngest(ingest)

        setArchivePrice(archiveNum)
        storageUpdate("archiveNum", "" + archiveNum)

        bandwidthTotals = egress + ingest
        setBandwidthTotal(bandwidthTotals)
        storageUpdate("bandwidthTotal", bandwidthTotal)

        setLiveStreamingHours(hours)
        storageUpdate("liveStreamingHours", hours)
        setViewerHours(viewerHoursNum)
        storageUpdate("viewerHours", viewerHoursNum)

    }

  const setPlans = (plan, feeNum, costNum) => {
        storageUpdate("sdkPrice", sdkPrice)
        storageUpdate("archivePrice", archivePrice)
        storageUpdate("mixerPrice", mixerPrice)
        storageUpdate("recommendedPlan", plan)
        storageUpdate("basicFee", feeNum)
        storageUpdate("cost", costNum)
        setRecommendedPlan(plan)
        setBasicFee(feeNum)
        // setBfNum(feeNum)
        setCost(costNum)
        // setCostNum(costNum)
    }

    const recommendedPlanCalc = () => {
        checkStorage()
        liveStreamingHourCalc()
        // const maxNumber = Math.pow(10, 1000);
        let overallCost = parseInt(localStorage.getItem("basicFee"))
            + parseInt(localStorage.getItem("additionalHours"))
            + parseInt(localStorage.getItem("additionalBandwidth"))
            + parseInt(localStorage.getItem("additionalEgress"))
            + parseInt(localStorage.getItem("sdkPrice")) + parseInt(localStorage.getItem("mixerPrice"));

        if(archive === true) {
            overallCost += parseInt(localStorage.getItem("archivePrice"))
        }

        if (abr === false && mixer === false && publishers <= 5 && subscribers <= 1000) {
            setPlans(XDN_PLANS.starter.title, 99, overallCost)
            nodes = 1
        } else if (mixer === true || parseInt(subScale(parseInt(localStorage.getItem("subscribers")))) > 5000
            || parseInt(pubScale(parseInt(localStorage.getItem("publishers")))) > 25) {
            setPlans(XDN_PLANS.advanced.title, 499, overallCost)
            nodes = 7
        } else if (abr === true ||
            (parseInt(subScale(parseInt(localStorage.getItem("subscribers")))) > 1000 && parseInt(subScale(parseInt(localStorage.getItem("subscribers")))) <= 5000)
            || (parseInt(pubScale(parseInt(localStorage.getItem("publishers")))) > 5 && parseInt(pubScale(parseInt(localStorage.getItem("publishers")))) <= 25)) {
            setPlans(XDN_PLANS.standard.title, 279, overallCost)
            nodes = 4
        } else {
            setPlans(XDN_PLANS.starter.title, 99, overallCost)
            nodes = 1
        }
    }

    const classes = useStyles();

    const sliderRef = useRef()


    function handleSliderClick() {
        sliderRef.current.scrollIntoView({behavior: 'smooth'})
    }

  if (readyToRegister) {
    const location = `/register/${recommendedPlan}/year`
    return <Redirect to={location}/>
  }

    return (
        <div>
            <FlowProgressBar active={progressActiveArray} arrows={progressArrowArray}></FlowProgressBar>
            <Grid container
                  className={classes.width}
                  alignItems="center"
                  justify="center"
            >
                <Box
                    display="flex" flexDirection="row" justifyContent="center"
                    style={{width: "100vw", height: "70px", marginTop: "10vh",}}
                >
                    <h3 className="h3Style"><span style={{color: "#DB1F26"}}>RED5</span> Pricing</h3>
                </Box>
                <Box style={{height: "70px"}} display="flex" flexDirection="row" justifyContent="center">
                    <h5
                        className="h5Style"
                    >
                        Smart pricing to choose the best solution for you
                    </h5>
                </Box>


                {/*  Pricing Section */}
                <Grid container
                      className={classes.backgroundGrey}
                      style={{marginBottom: "15vh"}}
                    // alignItems="center"
                      justify="center"
                >

                    <div style={{marginTop: "4px"}}>
                        <p className="pricingPTitle" style={{textAlign: "center"}}><br/></p>
                        <Button className="hidden-spacer-buttons"
                                style={{color: "white"}} variant="primary"
                                size="lg" block disabled>
                            &nbsp;&nbsp;
                        </Button>
                        <table className="pricingTableCol" style={{borderSpacing: "0px"}}>
                            <tr>
                                <td className="pricingP"
                                    style={{borderBottom: "1px solid rgba(196, 196, 196, .25)"}}>Subscription
                                    <HtmlTooltip placement="right" classes={{arrow: classes.arrow}}
                                                 title={tooltipTitles[0]} arrow>
                                        <Button className="hoverIcon"><InfoOutlinedIcon style={{marginBottom: "-5px"}}
                                                                                        fontSize="small"
                                                                                        color="disabled"/></Button>
                                    </HtmlTooltip>

                                </td>
                            </tr>
                            <tr>
                                <td className="pricingP"
                                    style={{borderBottom: "1px solid rgba(196, 196, 196, .25)"}}>Streaming
                                    Hours
                                    <HtmlTooltip placement="right" classes={{arrow: classes.arrow}}
                                                 title={tooltipTitles[1]} arrow>
                                        <Button className="hoverIcon"><InfoOutlinedIcon style={{marginBottom: "-5px"}}
                                                                                        fontSize="small"
                                                                                        color="disabled"/></Button>
                                    </HtmlTooltip>
                                </td>
                            </tr>
                            <tr>
                                <td className="pricingP"
                                    style={{borderBottom: "1px solid rgba(196, 196, 196, .25)"}}>Viewing
                                    Hours
                                    <HtmlTooltip placement="right" classes={{arrow: classes.arrow}}
                                                 title={tooltipTitles[1]} arrow>
                                        <Button className="hoverIcon"><InfoOutlinedIcon style={{marginBottom: "-5px"}}
                                                                                        fontSize="small"
                                                                                        color="disabled"/></Button>
                                    </HtmlTooltip>
                                </td>
                            </tr>
                            <tr>
                                <td className="pricingP"
                                    style={{borderBottom: "1px solid rgba(196, 196, 196, .25)"}}>Concurrent
                                    Viewers
                                    <HtmlTooltip placement="right" classes={{arrow: classes.arrow}}
                                                 title={tooltipTitles[2]} arrow>
                                        <Button className="hoverIcon"><InfoOutlinedIcon style={{marginBottom: "-5px"}}
                                                                                        fontSize="small"
                                                                                        color="disabled"/></Button>
                                    </HtmlTooltip>
                                </td>
                            </tr>
                            <tr>
                                <td className="pricingP"
                                    style={{borderBottom: "1px solid rgba(196, 196, 196, .25)"}}>Concurrent
                                    Publishers

                                    <HtmlTooltip placement="right" classes={{arrow: classes.arrow}}
                                                 title={tooltipTitles[2]} arrow>
                                        <Button className="hoverIcon"><InfoOutlinedIcon style={{marginBottom: "-5px"}}
                                                                                        fontSize="small"
                                                                                        color="disabled"/></Button>
                                    </HtmlTooltip>
                                </td>
                            </tr>
                            <tr>
                                <td className="pricingP"
                                    style={{borderBottom: "1px solid rgba(196, 196, 196, .25)"}}>Bandwidth
                                    Included
                                    <HtmlTooltip placement="right" classes={{arrow: classes.arrow}}
                                                 title={tooltipTitles[3]} arrow>
                                        <Button className="hoverIcon"><InfoOutlinedIcon style={{marginBottom: "-5px"}}
                                                                                        fontSize="small"
                                                                                        color="disabled"/></Button>
                                    </HtmlTooltip>
                                </td>
                            </tr>
                            <tr>
                                <td className="pricingP"
                                    style={{borderBottom: "1px solid rgba(196, 196, 196, .25)"}}>Nodes
                                    Included
                                </td>
                            </tr>

                        </table>
                    </div>

                    <div>
                        <p className="pricingPTitle" style={{textAlign: "center"}}>Starter</p>
                        <Button className="hidden-spacer-buttons"
                                style={{
                                    color: "white",
                                }} variant="primary"
                                size="lg" block disabled>
                            &nbsp;&nbsp;
                        </Button>
                        <table className="pricingBox"
                               style={{border: "3px solid transparent", borderSpacing: "0px"}}>
                            <PricingCell data={StarterPrices[0]} bold={true} bottom={false}/>
                            <PricingCell data={StarterPrices[1]} bold={false} bottom={false}/>
                            <PricingCell data={StarterPrices[2]} bold={false} bottom={false}/>
                            <PricingCell data={StarterPrices[3]} bold={false} bottom={false}/>
                            <PricingCell data={StarterPrices[4]} bold={false} bottom={false}/>
                            <PricingCell data={StarterPrices[5]} bold={false} bottom={false}/>
                            <PricingCell data={StarterPrices[6]} bold={false} bottom={true}/>
                        </table>
                        {!error &&
                        <Button className="gray-button" variant="primary" size="lg" block onClick={() => onRegister('starter')}>
                          Free Trial - Sign Up
                        </Button>
                        }
                    </div>

                    <div>
                        <p className="pricingPTitle" style={{textAlign: "center"}}>Standard</p>

                        <table className="pricingBox"
                               style={{border: "3px solid #DB1F26", borderSpacing: "0px"}}>
                            <Button className="buttonStyleInternal"
                                    style={{color: "white", width: "100%", borderRadius: "0px"}}
                                    variant="primary" size="lg" block disabled>
                                Most Popular!
                            </Button>
                            <PricingCell data={StandardPrices[0]} bold={true} bottom={false}/>
                            <PricingCell data={StandardPrices[1]} bold={false} bottom={false}/>
                            <PricingCell data={StandardPrices[2]} bold={false} bottom={false}/>
                            <PricingCell data={StandardPrices[3]} bold={false} bottom={false}/>
                            <PricingCell data={StandardPrices[4]} bold={false} bottom={false}/>
                            <PricingCell data={StandardPrices[5]} bold={false} bottom={false}/>
                            <PricingCell data={StandardPrices[6]} bold={false} bottom={true}/>
                        </table>
                        {!error &&
                        <Button className="red-button" variant="primary" size="lg" block onClick={() => onRegister('standard')}>
                            Free Trial - Sign Up
                        </Button>
                        }
                    </div>

                    <div>
                        <p className="pricingPTitle" style={{textAlign: "center"}}>Advanced</p>
                        <Button className="hidden-spacer-buttons"
                                style={{color: "white"}} variant="primary"
                                size="lg" block disabled>
                            &nbsp;&nbsp;
                        </Button>
                        <table className="pricingBox"
                               style={{border: "3px solid transparent", borderSpacing: "0px"}}>
                            <PricingCell data={AdvancedPrices[0]} bold={true} bottom={false}/>
                            <PricingCell data={AdvancedPrices[1]} bold={false} bottom={false}/>
                            <PricingCell data={AdvancedPrices[2]} bold={false} bottom={false}/>
                            <PricingCell data={AdvancedPrices[3]} bold={false} bottom={false}/>
                            <PricingCell data={AdvancedPrices[4]} bold={false} bottom={false}/>
                            <PricingCell data={AdvancedPrices[5]} bold={false} bottom={false}/>
                            <PricingCell data={AdvancedPrices[6]} bold={false} bottom={true}/>
                        </table>
                        {!error &&
                        <Button className="gray-button" variant="primary" size="lg" block  onClick={() => onRegister('advanced')}>
                            Free Trial - Sign Up
                        </Button>
                        }
                    </div>
                    <div>
                        <p className="pricingPTitle" style={{textAlign: "center"}}>Custom</p>
                        <Button className="hidden-spacer-buttons"
                                style={{color: "white"}} variant="primary"
                                size="lg" block disabled>
                            &nbsp;&nbsp;
                        </Button>
                        <table className="pricingBox"
                               style={{border: "3px solid transparent", borderSpacing: "0px"}}>
                            <PricingCell data={CustomPrices[0]} bold={true} bottom={false}/>
                            <PricingCell data={CustomPrices[1]} bold={false} bottom={false}/>
                            <PricingCell data={CustomPrices[2]} bold={false} bottom={false}/>
                            <PricingCell data={CustomPrices[3]} bold={false} bottom={false}/>
                            <PricingCell data={CustomPrices[4]} bold={false} bottom={false}/>
                            <PricingCell data={CustomPrices[5]} bold={false} bottom={false}/>
                            <PricingCell data={CustomPrices[6]} bold={false} bottom={true}/>
                        </table>
                        {!error &&

                        <Button className="gray-button" variant="primary" size="lg" bloc onClick={() => onRegister('custom')}k>
                            Free Trial - Sign Up
                        </Button>
                        }
                    </div>

                    {/* Custom/Slider Section */}
                    <div style={{width: "100vw", margin: "10px 25% auto 25%", textAlign: "center"}}>
                        <a className={classes.sliderHyperlink} onClick={handleSliderClick}>Not sure what plan is best
                            for you? Estimate your usage.</a>
                    </div>
                </Grid>
                <div ref={sliderRef} style={{width: "35%", margin: "0 5% 0 10%"}}>
                    <div style={{
                        width: "90%",
                        margin: "10% auto 10% 5%",
                        height: "900px",
                        background: "#FFFFFF",
                        boxShadow: "0px 4px 9px rgba(0, 0, 0, 0.05)",
                        textAlign: "center"
                    }}>
                        <Typography className="slider-text" id="discrete-slider" gutterBottom
                                    style={{paddingTop: "10%"}}>
                            How many Streaming Events per month?
                        </Typography>
                        <Slider
                            // style={{width: "448px"}}
                            id="streamingEvents"
                            defaultValue={parseInt(localStorage.getItem("streamingEvents"))}
                            getAriaValueText={streamingText}
                            aria-labelledby="discrete-slider"
                            // valueLabelDisplay="on"
                            step={null}
                            onChange={recommendEvents}
                            marks={streamingMarks}
                            scale={streamScale}
                            min={0}
                            max={125}
                        />

                        <Typography className="slider-text" id="discrete-slider" gutterBottom>
                            Events Duration
                        </Typography>
                        <Slider
                            defaultValue={parseInt(localStorage.getItem("duration"))}
                            getAriaValueText={durationText}
                            aria-labelledby="discrete-slider"
                            // valueLabelDisplay="on"
                            step={null}
                            onChange={recommendDuration}
                            marks={durationMarks}
                            scale={durationScale}
                            min={0}
                            max={175}
                            valueLabelFormat={valueLabel}
                        />

                        <Typography className="slider-text" id="discrete-slider" gutterBottom>
                            How Many Concurrent Publishers?
                        </Typography>
                        <Slider
                            // style={{width: "448px"}}
                            defaultValue={parseInt(localStorage.getItem("publishers"))}
                            getAriaValueText={pubText}
                            aria-labelledby="discrete-slider"
                            // valueLabelDisplay="on"
                            step={null}
                            onChange={recommendPub}
                            marks={pubMarks}
                            scale={pubScale}
                            min={0}
                            max={200}
                        />

                        <Typography className="slider-text" id="discrete-slider" gutterBottom>
                            How Many Concurrent Subscribers?
                        </Typography>
                        <Slider
                            // style={{width: "448px"}}
                            defaultValue={parseInt(localStorage.getItem("subscribers"))}
                            getAriaValueText={subText}
                            aria-labelledby="discrete-slider"
                            // valueLabelDisplay="on"
                            step={null}
                            onChange={recommendSub}
                            marks={subMarks}
                            scale={subScale}
                            min={0}
                            max={200}
                        />
                        {/*<Typography className="slider-text" id="discrete-slider" gutterBottom>*/}
                        {/*    Adaptive Framerate?*/}
                        {/*</Typography>*/}
                        {/*<Slider*/}
                        {/*    // style={{width: "448px"}}*/}
                        {/*    defaultValue={parseInt(localStorage.getItem("subscribers"))}*/}
                        {/*    getAriaValueText={frameText}*/}
                        {/*    aria-labelledby="discrete-slider"*/}
                        {/*    // valueLabelDisplay="on"*/}
                        {/*    step={null}*/}
                        {/*    onChange={recommendFrame}*/}
                        {/*    marks={framerateMarks}*/}
                        {/*    min={0}*/}
                        {/*    max={1}*/}
                        {/*/>*/}
                        <div style={{paddingTop: "10%"}}>
                            <FormControlLabel
                                style={{width: "40%"}}
                                control={<RedSwitch checked={abr} onChange={handleAbr} name="abr"/>}
                                label="Adaptive Bitrate"
                                labelPlacement="top"
                            />
                            <FormControlLabel
                                style={{width: "40%"}}
                                control={<RedSwitch checked={mixer} onChange={handleMixer} name="mixer"/>}
                                label="Mixer"
                                labelPlacement="top"
                            /></div>
                        <div style={{paddingTop: "10%", width: "100%"}}>
                            <FormControlLabel
                                style={{width: "40%"}}
                                control={<RedSwitch checked={archive} onChange={handleArchive} name="archive"/>}
                                label="Archive"
                                labelPlacement="top"
                            />
                            <FormControlLabel
                                style={{width: "40%"}}
                                control={<RedSwitch checked={sdk} onChange={handleSdk} name="sdk"/>}
                                label="Mobile SDK"
                                labelPlacement="top"
                            /></div>
                        {/*</div>*/}
                        <div style={{
                            height: "50px",
                            width: "80%",
                            margin: "5% 10% 0% auto",
                        }}>
                            <FormControl component="fieldset">
                                {error === true &&
                                <FormLabel style={{margin: "10px 0 10px 0"}} required error={error} component="legend">Select
                                    Resolution</FormLabel>
                                }

                                <FormGroup aria-label="position" row style={{width: "100%"}}>
                                    <FormControlLabel className="resolution-image"
                                                      value="top"
                                                      control={<RedCheckbox checked={checked320}
                                                                            onChange={handleChecked320}
                                                                            name="checked320"/>}
                                                      label={<Three20/>}
                                                      labelPlacement="top"
                                    />
                                    <FormControlLabel className="resolution-image"
                                                      value="top"
                                                      control={<RedCheckbox checked={checked640}
                                                                            onChange={handleChecked640}
                                                                            name="checked640"/>}
                                                      label={<Six40/>}
                                                      labelPlacement="top"
                                    />
                                    <FormControlLabel className="resolution-image"
                                                      value="top"
                                                      control={<RedCheckbox checked={checked720}
                                                                            onChange={handleChecked720}
                                                                            name="checked720"/>}
                                                      label={<Seven20/>}
                                                      labelPlacement="top"
                                    />
                                    <FormControlLabel className="resolution-image"
                                                      value="top"
                                                      control={<RedCheckbox checked={checked1080}
                                                                            onChange={handleChecked1080}
                                                                            name="checked1080"/>}
                                                      label={<Ten80/>}
                                                      labelPlacement="top"
                                    />
                                    <FormControlLabel className="resolution-image"
                                                      value="top"
                                                      control={<RedCheckbox checked={checked4k}
                                                                            onChange={handleChecked4k}
                                                                            name="checked4k"/>}
                                                      label={<FourK/>}
                                                      labelPlacement="top"
                                    />
                                </FormGroup>
                            </FormControl>
                        </div>
                    </div>
                </div>
                <div style={{
                    width: "35%", margin: "0 10% 0 5%"
                }}>
                    <div className="sign-up" style={{
                        width: "90%",
                        margin: "10% 5% 10% auto",
                        height: "900px",
                        background: "#FFFFFF",
                        boxShadow: "0px 4px 9px rgba(0, 0, 0, 0.05)",
                        textAlign: "center"
                    }}>
                        <h4 className="recommended">Recommended
                            Plan <span style={{color: "#DB1F26",}}>"{recommendedPlan.charAt(0).toUpperCase() + recommendedPlan.slice(1)}"</span>
                        </h4>
                        <div className="monthly">
                            <p className="estimated">Estimated Monthly Usage*</p>
                            <div className="leftRecommendedText">
                                <p className="recommended-plan-p-tags">Live streaming hours:
                                </p>
                                <p className="recommended-plan-p-tags">Viewer hours:
                                </p>
                                <p className="recommended-plan-p-tags">Bandwidth - Viewers (Egress):
                                </p>
                                <p className="recommended-plan-p-tags">Bandwidth - Publishers (Ingest):
                                </p>
                                <p className="recommended-plan-p-tags">Bandwidth Total:
                                </p>
                                {/*<p className="recommended-plan-p-tags">Nodes:*/}
                                {/*</p>*/}
                            </div>
                            <div className="rightRecommendedText">
                                <p className="recommended-plan-p-tags">{liveStreamingHours.toLocaleString(undefined, {maximumFractionDigits: 2})} Hours
                                </p>
                                <p className="recommended-plan-p-tags">{viewerHours.toLocaleString(undefined, {maximumFractionDigits: 2})} Hours
                                </p>
                                <p className="recommended-plan-p-tags">{egress.toLocaleString(undefined, {maximumFractionDigits: 2})} GB
                                </p>
                                <p className="recommended-plan-p-tags">{ingest.toLocaleString(undefined, {maximumFractionDigits: 2})} GB
                                </p>
                                <p className="recommended-plan-p-tags">{bandwidthTotal.toLocaleString(undefined, {maximumFractionDigits: 2})} GB
                                </p>
                                {/*<p className="recommended-plan-p-tags">{nodes}*/}
                                {/*</p>*/}
                            </div>
                        </div>
                        <div className="monthly">
                            <p className="estimated">Estimated Fees with Recommended Plan*</p>
                            <div style={{width: "40%", float: "left", textAlign: "left", marginLeft: "5px"}}>
                                <p className="recommended-plan-p-tags">Basic plan recurring fee:
                                </p>
                                <p className="recommended-plan-p-tags">
                                    Mobile SDK:
                                </p>
                                <p className="recommended-plan-p-tags">
                                    Mixer:
                                </p>
                                <p className="recommended-plan-p-tags">
                                    Archive:
                                </p>

                            </div>
                            <div style={{width: "40%", float: "right", textAlign: "right", marginRight: "5px"}}>
                                <p className="recommended-plan-p-tags">${basicFee.toLocaleString(undefined, {maximumFractionDigits: 2})}
                                </p>
                                <p className="recommended-plan-p-tags">
                                    ${sdkPrice.toLocaleString(undefined, {maximumFractionDigits: 2})}
                                </p>
                                <p className="recommended-plan-p-tags">
                                    ${mixerPrice.toLocaleString(undefined, {maximumFractionDigits: 2})}

                                </p>
                                <p className="recommended-plan-p-tags">
                                    ${archivePrice.toLocaleString(undefined, {maximumFractionDigits: 2})}
                                </p></div>
                            <div style={{width: "100%"}}>

                                <div className="priceLeft">
                                    <p className="estimated-price">Estimated Monthly Cost: </p>
                                </div>
                                <div className="priceRight">
                                    <p className="estimated-price">${cost.toLocaleString(undefined, {maximumFractionDigits: 2})} </p>
                                </div>
                            </div>
                        </div>
                        {!error &&
                        <Button className={classes.buttonStyle1} variant="primary" size="lg" block onClick={() => onRegister()}>
                            Sign Up
                        </Button>
                        }
                    </div>
                </div>
                <Grid container
                    // className={classes.backgroundGrey}
                      style={{
                          backgroundColor: "rgba(196, 196, 196, .35)",
                          padding: "5% 0 5% 0"
                      }}
                    // alignItems="center"
                      justify="center"
                >

                    <div className="small-screen-div">
                        <p className="pricingPTitle" style={{textAlign: "center"}}><br/></p>
                        <table className="pricingTableCol" style={{borderSpacing: "0px", paddingTop: "4px"}}>
                            <tr>
                                <td className="pricingP"
                                    style={{borderBottom: "1px solid rgba(196, 196, 196, .25)"}}>Subscription
                                    <HtmlTooltip placement="right" classes={{arrow: classes.arrow}}
                                                 title={tooltipTitles[0]} arrow>
                                        <Button className="hoverIcon"><InfoOutlinedIcon style={{marginBottom: "-5px"}}
                                                                                        fontSize="small"
                                                                                        color="disabled"/></Button>
                                    </HtmlTooltip>

                                </td>
                            </tr>
                            <tr>
                                <td className="pricingP"
                                    style={{borderBottom: "1px solid rgba(196, 196, 196, .25)"}}>Streaming
                                    Hours
                                    <HtmlTooltip placement="right" classes={{arrow: classes.arrow}}
                                                 title={tooltipTitles[1]} arrow>
                                        <Button className="hoverIcon"><InfoOutlinedIcon style={{marginBottom: "-5px"}}
                                                                                        fontSize="small"
                                                                                        color="disabled"/></Button>
                                    </HtmlTooltip>
                                </td>
                            </tr>
                            <tr>
                                <td className="pricingP"
                                    style={{borderBottom: "1px solid rgba(196, 196, 196, .25)"}}>Viewing
                                    Hours
                                    <HtmlTooltip placement="right" classes={{arrow: classes.arrow}}
                                                 title={tooltipTitles[1]} arrow>
                                        <Button className="hoverIcon"><InfoOutlinedIcon style={{marginBottom: "-5px"}}
                                                                                        fontSize="small"
                                                                                        color="disabled"/></Button>
                                    </HtmlTooltip>
                                </td>
                            </tr>
                            <tr>
                                <td className="pricingP"
                                    style={{borderBottom: "1px solid rgba(196, 196, 196, .25)"}}>Concurrent
                                    Viewers
                                    <HtmlTooltip placement="right" classes={{arrow: classes.arrow}}
                                                 title={tooltipTitles[2]} arrow>
                                        <Button className="hoverIcon"><InfoOutlinedIcon style={{marginBottom: "-5px"}}
                                                                                        fontSize="small"
                                                                                        color="disabled"/></Button>
                                    </HtmlTooltip>
                                </td>
                            </tr>
                            <tr>
                                <td className="pricingP"
                                    style={{borderBottom: "1px solid rgba(196, 196, 196, .25)"}}>Concurrent
                                    Publishers

                                    <HtmlTooltip placement="right" classes={{arrow: classes.arrow}}
                                                 title={tooltipTitles[2]} arrow>
                                        <Button className="hoverIcon"><InfoOutlinedIcon style={{marginBottom: "-5px"}}
                                                                                        fontSize="small"
                                                                                        color="disabled"/></Button>
                                    </HtmlTooltip>
                                </td>
                            </tr>
                            <tr>
                                <td className="pricingP"
                                    style={{borderBottom: "1px solid rgba(196, 196, 196, .25)"}}>Bandwidth
                                    Included
                                    <HtmlTooltip placement="right" classes={{arrow: classes.arrow}}
                                                 title={tooltipTitles[3]} arrow>
                                        <Button className="hoverIcon"><InfoOutlinedIcon style={{marginBottom: "-5px"}}
                                                                                        fontSize="small"
                                                                                        color="disabled"/></Button>
                                    </HtmlTooltip>
                                </td>
                            </tr>
                            <tr>
                                <td className="pricingP"
                                    style={{borderBottom: "1px solid rgba(196, 196, 196, .25)"}}>Nodes Included
                                </td>
                            </tr>
                            <tr>
                                <td className="pricingP"
                                    style={{borderBottom: "1px solid rgba(196, 196, 196, .25)"}}>Dedicated Hardware
                                </td>
                            </tr>
                            <tr>
                                <td className="pricingP"
                                    style={{borderBottom: "1px solid rgba(196, 196, 196, .25)"}}>Sub 500ms Latency
                                </td>
                            </tr>
                            <tr>
                                <td className="pricingP"
                                    style={{borderBottom: "1px solid rgba(196, 196, 196, .25)"}}>Fully Managed Service
                                </td>
                            </tr>
                            <tr>
                                <td className="pricingP"
                                    style={{borderBottom: "1px solid rgba(196, 196, 196, .25)"}}>Adaptive Bitrate
                                    Streaming
                                </td>
                            </tr>
                            <tr>
                                <td className="pricingP"
                                    style={{borderBottom: "1px solid rgba(196, 196, 196, .25)"}}>Full Access to Stream
                                    Manager
                                </td>
                            </tr>
                            <tr>
                                <td className="pricingP"
                                    style={{borderBottom: "1px solid rgba(196, 196, 196, .25)"}}>Multi-Region
                                    Geographies
                                </td>
                            </tr>
                            <tr>
                                <td className="pricingP"
                                    style={{borderBottom: "1px solid rgba(196, 196, 196, .25)"}}>Mixer functionality
                                    capability
                                </td>
                            </tr>
                            <tr>
                                <td className="pricingP"
                                    style={{borderBottom: "1px solid rgba(196, 196, 196, .25)"}}>High
                                    availability/Redundancy capability
                                </td>
                            </tr>
                            <tr>
                                <td className="pricingP"
                                    style={{borderBottom: "1px solid rgba(196, 196, 196, .25)"}}>Custom feature
                                    development
                                    capability
                                </td>
                            </tr>
                            <tr>
                                <td className="pricingP"
                                    style={{borderBottom: "1px solid rgba(196, 196, 196, .25)"}}>Support - Community
                                </td>
                            </tr>
                            <tr>
                                <td className="pricingP"
                                    style={{borderBottom: "1px solid rgba(196, 196, 196, .25)"}}>Support - Email
                                </td>
                            </tr>
                            <tr>
                                <td className="pricingP"
                                    style={{borderBottom: "1px solid rgba(196, 196, 196, .25)"}}>Support - Private Slack
                                    Channel
                                </td>
                            </tr>
                            <tr>
                                <td className="pricingP"
                                    style={{borderBottom: "1px solid rgba(196, 196, 196, .25)"}}>Support - Video
                                    Calls/Screenshare
                                </td>
                            </tr>
                            <tr>
                                <td className="pricingPAC"
                                    style={{borderBottom: "1px solid rgba(196, 196, 196, .25)",}}>Additional Charges
                                </td>
                            </tr>
                            <tr>
                                <td className="pricingP"
                                    style={{borderBottom: "1px solid rgba(196, 196, 196, .25)"}}>Bandwidth
                                    <HtmlTooltip placement="right" classes={{arrow: classes.arrow}}
                                                 title={tooltipTitles[4]} arrow>
                                        <Button className="hoverIcon"><InfoOutlinedIcon style={{marginBottom: "-5px"}}
                                                                                        fontSize="small"
                                                                                        color="disabled"/></Button>
                                    </HtmlTooltip>
                                </td>
                            </tr>
                            <tr>
                                <td className="pricingP"
                                    style={{borderBottom: "1px solid rgba(196, 196, 196, .25)"}}>Additional
                                    Nodes
                                    <HtmlTooltip placement="right" classes={{arrow: classes.arrow}}
                                                 title={tooltipTitles[5]} arrow>
                                        <Button className="hoverIcon"><InfoOutlinedIcon style={{marginBottom: "-5px"}}
                                                                                        fontSize="small"
                                                                                        color="disabled"/></Button>
                                    </HtmlTooltip>
                                </td>
                            </tr>
                            <tr>
                                <td className="pricingP"
                                    style={{borderBottom: "1px solid rgba(196, 196, 196, .25)"}}>Video
                                    Archive
                                    <HtmlTooltip placement="right" classes={{arrow: classes.arrow}}
                                                 title={tooltipTitles[6]} arrow>
                                        <Button className="hoverIcon"><InfoOutlinedIcon style={{marginBottom: "-5px"}}
                                                                                        fontSize="small"
                                                                                        color="disabled"/></Button>
                                    </HtmlTooltip>
                                </td>
                            </tr>
                            <tr>
                                <td className="pricingP"
                                    style={{borderBottom: "1px solid rgba(196, 196, 196, .25)"}}>Mobile SDK
                                </td>
                            </tr>
                            <tr>
                                <td className="pricingP"
                                    style={{borderBottom: "1px solid rgba(196, 196, 196, .25)"}}>Mixer nodes
                                </td>
                            </tr>
                            <tr>
                                <td className="pricingP" style={{borderBottom: "1px solid rgba(196, 196, 196, .25)"}}
                                    style={{width: "250px", borderBottom: "0px solid transparent"}}>Additional Regions
                                    <HtmlTooltip placement="right" classes={{arrow: classes.arrow}}
                                                 title={tooltipTitles[7]} arrow>
                                        <Button className="hoverIcon"><InfoOutlinedIcon style={{marginBottom: "-5px"}}
                                                                                        fontSize="small"
                                                                                        color="disabled"/></Button>
                                    </HtmlTooltip>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div className="small-screen-div">
                        <p className="pricingPTitle" style={{textAlign: "center"}}>Starter</p>
                        <table className="pricingBox"
                               style={{border: "3px solid transparent", borderSpacing: "0px"}}>
                            <PricingCell data={StarterPrices[0]} bold={true} bottom={false}/>
                            <PricingCell data={StarterPrices[1]} bold={false} bottom={false}/>
                            <PricingCell data={StarterPrices[2]} bold={false} bottom={false}/>
                            <PricingCell data={StarterPrices[3]} bold={false} bottom={false}/>
                            <PricingCell data={StarterPrices[4]} bold={false} bottom={false}/>
                            <PricingCell data={StarterPrices[5]} bold={false} bottom={false}/>
                            <PricingCell data={StarterPrices[6]} bold={false} bottom={false}/>
                            <PricingCell data={StarterPrices[7]} bold={false} bottom={false}/>
                            <PricingCell data={StarterPrices[8]} bold={false} bottom={false}/>
                            <PricingCell data={StarterPrices[9]} bold={false} bottom={false}/>
                            <PricingCell data={StarterPrices[10]} bold={false} bottom={false}/>
                            <PricingCell data={StarterPrices[11]} bold={false} bottom={false}/>
                            <PricingCell data={StarterPrices[12]} bold={false} bottom={false}/>
                            <PricingCell data={StarterPrices[13]} bold={false} bottom={false}/>
                            <PricingCell data={StarterPrices[14]} bold={false} bottom={false}/>
                            <PricingCell data={StarterPrices[15]} bold={false} bottom={false}/>
                            <PricingCell data={StarterPrices[16]} bold={false} bottom={false}/>
                            <PricingCell data={StarterPrices[17]} bold={false} bottom={false}/>
                            <PricingCell data={StarterPrices[18]} bold={false} bottom={false}/>
                            <PricingCell data={StarterPrices[19]} bold={false} bottom={false}/>
                            <TableSpacer/>
                            <PricingCell data={StarterPrices[20]} bold={true} bottom={false}/>
                            <PricingCell data={StarterPrices[21]} bold={false} bottom={false}/>
                            <PricingCell data={StarterPrices[22]} bold={false} bottom={false}/>
                            <PricingCell data={StarterPrices[23]} bold={false} bottom={false}/>
                            <PricingCell data={StarterPrices[24]} bold={false} bottom={false}/>
                            <PricingCell data={StarterPrices[25]} bold={false} bottom={true}/>
                        </table>
                        {!error &&
                        <Button className="red-button" variant="primary" size="lg" block onClick={() => onRegister('starter')}>
                            Free Trial - Sign Up
                        </Button>
                        }
                    </div>
                    <div className="small-screen-div">
                        <p className="pricingPTitle" style={{textAlign: "center"}}>Standard</p>

                        <table className="pricingBox"
                               style={{border: "3px solid transparent", borderSpacing: "0px"}}>
                            <PricingCell data={StandardPrices[0]} bold={true} bottom={false}/>
                            <PricingCell data={StandardPrices[1]} bold={false} bottom={false}/>
                            <PricingCell data={StandardPrices[2]} bold={false} bottom={false}/>
                            <PricingCell data={StandardPrices[3]} bold={false} bottom={false}/>
                            <PricingCell data={StandardPrices[4]} bold={false} bottom={false}/>
                            <PricingCell data={StandardPrices[5]} bold={false} bottom={false}/>
                            <PricingCell data={StandardPrices[6]} bold={false} bottom={false}/>
                            <PricingCell data={StandardPrices[7]} bold={false} bottom={false}/>
                            <PricingCell data={StandardPrices[8]} bold={false} bottom={false}/>
                            <PricingCell data={StandardPrices[9]} bold={false} bottom={false}/>
                            <PricingCell data={StandardPrices[10]} bold={false} bottom={false}/>
                            <PricingCell data={StandardPrices[11]} bold={false} bottom={false}/>
                            <PricingCell data={StandardPrices[12]} bold={false} bottom={false}/>
                            <PricingCell data={StandardPrices[13]} bold={false} bottom={false}/>
                            <PricingCell data={StandardPrices[14]} bold={false} bottom={false}/>
                            <PricingCell data={StandardPrices[15]} bold={false} bottom={false}/>
                            <PricingCell data={StandardPrices[16]} bold={false} bottom={false}/>
                            <PricingCell data={StandardPrices[17]} bold={false} bottom={false}/>
                            <PricingCell data={StandardPrices[18]} bold={false} bottom={false}/>
                            <PricingCell data={StandardPrices[19]} bold={false} bottom={false}/>
                            <TableSpacer/>
                            <PricingCell data={StandardPrices[20]} bold={true} bottom={false}/>
                            <PricingCell data={StandardPrices[21]} bold={false} bottom={false}/>
                            <PricingCell data={StandardPrices[22]} bold={false} bottom={false}/>
                            <PricingCell data={StandardPrices[23]} bold={false} bottom={false}/>
                            <PricingCell data={StandardPrices[24]} bold={false} bottom={false}/>
                            <PricingCell data={StandardPrices[25]} bold={false} bottom={true}/>
                        </table>
                        {!error &&
                        <Button className="red-button" variant="primary" size="lg" block onClick={() => onRegister('standard')}>
                            Free Trial - Sign Up
                        </Button>
                        }
                    </div>
                    <div className="small-screen-div">
                        <p className="pricingPTitle" style={{textAlign: "center"}}>Advanced</p>
                        <table className="pricingBox"
                               style={{border: "3px solid transparent", borderSpacing: "0px"}}>
                            <PricingCell data={AdvancedPrices[0]} bold={true} bottom={false}/>
                            <PricingCell data={AdvancedPrices[1]} bold={false} bottom={false}/>
                            <PricingCell data={AdvancedPrices[2]} bold={false} bottom={false}/>
                            <PricingCell data={AdvancedPrices[3]} bold={false} bottom={false}/>
                            <PricingCell data={AdvancedPrices[4]} bold={false} bottom={false}/>
                            <PricingCell data={AdvancedPrices[5]} bold={false} bottom={false}/>
                            <PricingCell data={AdvancedPrices[6]} bold={false} bottom={false}/>
                            <PricingCell data={AdvancedPrices[7]} bold={false} bottom={false}/>
                            <PricingCell data={AdvancedPrices[8]} bold={false} bottom={false}/>
                            <PricingCell data={AdvancedPrices[9]} bold={false} bottom={false}/>
                            <PricingCell data={AdvancedPrices[10]} bold={false} bottom={false}/>
                            <PricingCell data={AdvancedPrices[11]} bold={false} bottom={false}/>
                            <PricingCell data={AdvancedPrices[12]} bold={false} bottom={false}/>
                            <PricingCell data={AdvancedPrices[13]} bold={false} bottom={false}/>
                            <PricingCell data={AdvancedPrices[14]} bold={false} bottom={false}/>
                            <PricingCell data={AdvancedPrices[15]} bold={false} bottom={false}/>
                            <PricingCell data={AdvancedPrices[16]} bold={false} bottom={false}/>
                            <PricingCell data={AdvancedPrices[17]} bold={false} bottom={false}/>
                            <PricingCell data={AdvancedPrices[18]} bold={false} bottom={false}/>
                            <PricingCell data={AdvancedPrices[19]} bold={false} bottom={false}/>
                            <TableSpacer/>
                            <PricingCell data={AdvancedPrices[20]} bold={true} bottom={false}/>
                            <PricingCell data={AdvancedPrices[21]} bold={false} bottom={false}/>
                            <PricingCell data={AdvancedPrices[22]} bold={false} bottom={false}/>
                            <PricingCell data={AdvancedPrices[23]} bold={false} bottom={false}/>
                            <PricingCell data={AdvancedPrices[24]} bold={false} bottom={false}/>
                            <PricingCell data={AdvancedPrices[25]} bold={false} bottom={true}/>
                        </table>
                        {!error &&
                        <Button className="red-button" variant="primary" size="lg" block onClick={() => onRegister('advanced')}>
                            Free Trial - Sign Up
                        </Button>
                        }
                    </div>
                    <div className="small-screen-div">
                        <p className="pricingPTitle" style={{textAlign: "center"}}>Custom</p>
                        <table className="pricingBox"
                               style={{border: "3px solid transparent", borderSpacing: "0px"}}>
                            <PricingCell data={CustomPrices[0]} bold={true} bottom={false}/>
                            <PricingCell data={CustomPrices[1]} bold={false} bottom={false}/>
                            <PricingCell data={CustomPrices[2]} bold={false} bottom={false}/>
                            <PricingCell data={CustomPrices[3]} bold={false} bottom={false}/>
                            <PricingCell data={CustomPrices[4]} bold={false} bottom={false}/>
                            <PricingCell data={CustomPrices[5]} bold={false} bottom={false}/>
                            <PricingCell data={CustomPrices[6]} bold={false} bottom={false}/>
                            <PricingCell data={CustomPrices[7]} bold={false} bottom={false}/>
                            <PricingCell data={CustomPrices[8]} bold={false} bottom={false}/>
                            <PricingCell data={CustomPrices[9]} bold={false} bottom={false}/>
                            <PricingCell data={CustomPrices[10]} bold={false} bottom={false}/>
                            <PricingCell data={CustomPrices[11]} bold={false} bottom={false}/>
                            <PricingCell data={CustomPrices[12]} bold={false} bottom={false}/>
                            <PricingCell data={CustomPrices[13]} bold={false} bottom={false}/>
                            <PricingCell data={CustomPrices[14]} bold={false} bottom={false}/>
                            <PricingCell data={CustomPrices[15]} bold={false} bottom={false}/>
                            <PricingCell data={CustomPrices[16]} bold={false} bottom={false}/>
                            <PricingCell data={CustomPrices[17]} bold={false} bottom={false}/>
                            <PricingCell data={CustomPrices[18]} bold={false} bottom={false}/>
                            <PricingCell data={CustomPrices[19]} bold={false} bottom={false}/>
                            <TableSpacer/>
                            <PricingCell data={CustomPrices[20]} bold={true} bottom={false}/>
                            <PricingCell data={CustomPrices[21]} bold={false} bottom={false}/>
                            <PricingCell data={CustomPrices[22]} bold={false} bottom={false}/>
                            <PricingCell data={CustomPrices[23]} bold={false} bottom={false}/>
                            <PricingCell data={CustomPrices[24]} bold={false} bottom={false}/>
                            <PricingCell data={CustomPrices[25]} bold={false} bottom={true}/>
                        </table>
                        {!error &&
                        <Button className="red-button" variant="primary" size="lg" block onClick={() => onRegister('custom')}>
                            Free Trial - Sign Up
                        </Button>
                        }
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}
