import React from 'react';
import {Grid, Button} from "@material-ui/core";
import {makeStyles} from '@material-ui/core/styles';
import { useAuth } from '../../auth/useAuth'

const useStyles = makeStyles(theme => ({
    backgroundGrey: {
        background: "#FAFAFA",
        width: "100vw",
        height: '100%',
    },
    borderStyleLeft: {
        height: "1050px",
        backgroundColor: "white",
        margin: "2% 2% 0 auto",
        textAlign: "center",
        position: "relative",
    },
    borderStyleRight: {
        height: "1050px",
        backgroundColor: "white",
        margin: "2% auto 0 2%",
        textAlign: "center",
        position: "relative",
    },
    listStyle: {
        color: "#DB1F26",
        margin: "0% 0 0% 0",
        display: "inline-block",
    },
    liStyle: {
        // display: "inline-block",
    },
    h3Style: {
        fontFamily: "Lato",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "40px",
        lineHeight: "48px",
        letterSpacing: "0.02em",
        textTransform: "uppercase",
        color: "#000000",
        margin: "10% 5% 5% 5%",
        textAlign: "center"
    },
    h5Style: {
        fontFamily: "Work Sans",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "18px",
        lineHeight: "25px",
        color: "#000000",
        opacity: "0.7",
        margin: "0% 5% 3% 5%",
        textAlign: "center",
    },
    h6Style: {
        fontFamily: "Work Sans",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "16px",
        lineHeight: "18px",
        color: "#000000",
        opacity: "0.7",
        textAlign: "left",
    },
    topButton: {
        margin: "0 10% 5%",
        width: "80%",
        backgroundColor: "#DB1F26",
        border: "#DB1F26",
        fontFamily: "Lato",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "16px",
        lineHeight: "40px",
        textAlign: "center",
        letterSpacing: "0.05em",
        textTransform: "uppercase",
        color: "#FFFFFF",
        position: "absolute",
        right: "0",
        bottom: "75px",
        '&:hover': {
            backgroundColor: "rgba(219, 31, 38, .8)",
            border: "rgba(219, 31, 38, .8)",
        }
    },
    bottomButton: {
        margin: "0 10% 5%",
        width: "80%",
        backgroundColor: "#DB1F26",
        border: "#DB1F26",
        fontFamily: "Lato",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "16px",
        lineHeight: "40px",
        textAlign: "center",
        letterSpacing: "0.05em",
        textTransform: "uppercase",
        color: "#FFFFFF",
        position: "absolute",
        right: "0",
        bottom: "0",
        '&:hover': {
            backgroundColor: "rgba(219, 31, 38, .8)",
            border: "rgba(219, 31, 38, .8)",
        }
    },
    listDiv: {
        height: "65%",
        width: "50%",
        marginLeft: "25%",
        marginBottom: "10%"
    }
}))

const navProg = {
    marginTop: "64px",
    height: "64px",
    width: "100vw",
    color: "black",
    backgroundColor: "#E2E2E2",
};

const progLink = {
    fontFamily: "Work Sans",
    height: "64px",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "24px",
    textAlign: "center",
    color: "#A0A0A0",
    mixBlendMode: "normal",
    margin: "16px 0 auto 0",
};

const navDark = {
    height: "64px",
    width: "75%",
    backgroundColor: "#171717",
}

const arrowBox = {
    width: "0",
    height: "64px",
    borderTop: "32px solid transparent",
    borderBottom: "32px solid transparent",
    borderLeft: "32px solid #171717",
};

const progLinkActive = {
    color: "#DB1F26",
    fontFamily: "Work Sans",
    height: "64px",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "24px",
    textAlign: "center",
    mixBlendMode: "normal",
    margin: "16px 0 auto 0",
}

const dot = {
    transform: "translateY(20%)",
    marginRight: "5px",
    height: "14px",
    width: "14px",
    backgroundColor: "#DB1F26",
    borderRadius: "50%",
    display: "inline-block",
};

export default function Choice({history}) {

    const classes = useStyles()
    const { user } = useAuth()

  const checkFinished = () => {
        if (user && user.id && localStorage.getItem("finishedFlow") === "true") {
            history.push("/dashboard")
            return false
        }
        return true
    }

    return (
        <>
        {checkFinished() && <div>
            <Grid container style={navProg}>
                <Grid item xs={3} style={navDark}></Grid><div style={arrowBox}></div>
                <Grid item xs={1} sm={1} md={1} lg={1}>
                    <h6 style={progLinkActive}><span style={dot}></span>Registration</h6>
                </Grid>
                <Grid item xs={1} sm={1} md={1} lg={1}>
                    <h6 style={progLink}>Setup Service</h6>
                </Grid>
                <Grid item xs={1} sm={1} md={1} lg={1}>
                    <h6 style={progLink}>Deploy</h6>
                </Grid>
                <Grid item xs={1} sm={1} md={1} lg={1}>
                    <h6 style={progLink}>Test Streams</h6>
                </Grid>
                <Grid item xs={1} sm={1} md={1} lg={1}>
                    <h6 style={progLink}>Create Your App</h6>
                </Grid>
                <Grid item xs={1} sm={1} md={1} lg={1}>
                    <h6 style={progLink}>Done</h6>
                </Grid>
            </Grid>
            <div className={classes.backgroundGrey}>
                <Grid container>
                    <Grid item xs={12} sm={12} md={4}
                          className={classes.borderStyleLeft}
                    >
                        <h3 className={classes.h3Style}><span style={{color: "#DB1F26"}}>Red5</span> XDN</h3>
                        <h5 className={classes.h5Style}>Get started QUICK! Let us host your solution for you.</h5>
                        <div className={classes.listDiv}>
                            <ul className={classes.listStyle}>
                                <li className={classes.liStyle}>
                                    <h6 className={classes.h6Style}>Easy, hands-off, worry-free maintenance</h6>
                                </li>
                                <li className={classes.liStyle}>
                                    <h6 className={classes.h6Style}>Automatic updates</h6>
                                </li>
                                <li className={classes.liStyle}>
                                    <h6 className={classes.h6Style}>Hosted solution</h6>
                                </li>
                                <li className={classes.liStyle}>
                                    <h6 className={classes.h6Style}>Fast installation, fully pre-configured</h6>
                                </li>
                                <li className={classes.liStyle}>
                                    <h6 className={classes.h6Style}>Back-end server infrastructure maintained for
                                        you</h6>
                                </li>
                                <li className={classes.liStyle}>
                                    <h6 className={classes.h6Style}>“Plug and play”, ready to go solution, works “out of
                                        the box”</h6>
                                </li>
                                <li className={classes.liStyle}>
                                    <h6 className={classes.h6Style}>Pre-configured with optional settings</h6>
                                </li>
                            </ul>
                        </div>
                        <Button className={classes.topButton} variant="primary" size="lg" block>
                            <a style={{color: "white", textDecoration: "none",}} href="/register">GET STARTED WITH RED5
                                XDN (hosted)</a>
                        </Button>
                        <Button className={classes.bottomButton} variant="primary" size="lg" block>
                            <a style={{color: "white", textDecoration: "none",}} href="/xdn-pricing">View Pricing</a>
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}
                          className={classes.borderStyleRight}
                    >
                        <h3 className={classes.h3Style}><span style={{color: "#DB1F26"}}>Red5</span> Pro</h3>
                        <h5 className={classes.h5Style}>Host Red5 Pro yourself</h5>
                        <div className={classes.listDiv}>
                            <ul className={classes.listStyle}>
                                <li className={classes.liStyle}>
                                    <h6 className={classes.h6Style}>Self-hosted solution, hosted on your own
                                        servers</h6>
                                </li>
                                <li className={classes.liStyle}>
                                    <h6 className={classes.h6Style}>Choice between a full manual Install of the Red5 Pro
                                        software on your own servers, or an automatic install on AWS, Azure, GCP,
                                        DigitalOcean or Linode</h6>
                                </li>
                                <li className={classes.liStyle}>
                                    <h6 className={classes.h6Style}>Hosting agnostic (AWS, GCP, Azure, DigitalOcean, and
                                        other terraform supported providers)</h6>
                                </li>
                                <li className={classes.liStyle}>
                                    <h6 className={classes.h6Style}>Full customization</h6>
                                </li>
                                <li className={classes.liStyle}>
                                    <h6 className={classes.h6Style}>Less pre-configured than Red5 XDN</h6>
                                </li>
                            </ul>
                        </div>
                        <Button className={classes.topButton} variant="primary" size="lg" block>
                            <a style={{color: "white", textDecoration: "none",}}
                               href="https://account.red5pro.com/register">GET STARTED WITH RED5 Pro (self-hosted)</a>
                        </Button>
                        <Button className={classes.bottomButton} variant="primary" size="lg" block>
                            <a style={{color: "white", textDecoration: "none",}}
                               href="https://www.red5pro.com/red5-pro-license-pricing/">View Pricing</a>
                        </Button>
                    </Grid>
                </Grid>
            </div>
        </div>}
        </>
    )
}
