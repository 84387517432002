import {Error, Info} from '../../components/Toast'
import React, {useState} from 'react'
import {Redirect, useLocation} from 'react-router-dom'

import Box from '@material-ui/core/Box'
import {CREATE_USER} from '../../models/account-api'
import DataView from '../DataView'
import Typography from '@material-ui/core/Typography'
import {makeStyles, withStyles} from '@material-ui/core/styles'
import {useAuth} from '../../auth/useAuth'
import {useMutation} from '@apollo/react-hooks'

import Grid from '@material-ui/core/Grid';
import "./register.css";
import {set} from "ramda";
import {red} from "@material-ui/core/colors";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';


const R = require('ramda')

const useStyles = makeStyles(theme => ({
    image: {
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100vh',
        padding: 0
    },
    root: {
        position: 'relative',
        top: theme.spacing(5),
        width: 500,
        margin: '0 auto'
    },
    githubNote: {
        marginTop: theme.spacing(2),
        backgroundColor: theme.palette.primary[50]
    },
    title: {
        textAlign: 'center',
        fontSize: '2em'
    },
    backgroundGrey: {
        background: "#FAFAFA",
        height: '100%',
        width: "50vw",
    },
    borderStyle: {
        backgroundColor: "white",
        // border: "1px solid black",
        height: 'calc(106vh - 128px)',
        fallbacks: [
            {height: '-moz-calc(106vh - 128px)'},
            {height: '-webkit-calc(106vh - 128px)'},
            {height: '-o-calc(106vh - 128px)'}
        ],
        width: "50vw",
    },
    bulletTextStyle: {
        fontFamily: "Work Sans",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "16px",
        lineHeight: "22px",
        color: "#000000",
        opacity: "0.7",
        margin: "7% 5% 3% 5%",
        textAlign: "left",
    },
    listStyle: {
        color: "#DB1F26",
        margin: "10% 0 15% 0",
        textAlign: "left",
        width: "50%",
        position: "relative",
        left: "25%",
    },
    h4Style: {
        fontFamily: "Lato",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "27px",
        lineHeight: "56px",
        letterSpacing: "0.02em",
        textTransform: "uppercase",
        color: "#000000",
        margin: "5% 5% 5% 5%",
        textAlign: "left",
        width: "50%",
        position: "relative",
        left: "25%",
    },
    h3Style: {
        fontFamily: "Lato",
        fontStyle: "normal",
        fontWeight: "800",
        fontSize: "40px",
        lineHeight: "48px",
        letterSpacing: "0.02em",
        textTransform: "uppercase",
        color: "#000000",
        margin: "5% 5% 5% 5%",
        textAlign: "left",
        width: "50%",
        position: "relative",
        left: "25%",
    },
    h5Style: {
        fontFamily: "Lato",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "18px",
        lineHeight: "32px",
        letterSpacing: "0.02em",
        textTransform: "uppercase",
        color: "#000000",
        margin: "15% 5% 3% 5%",
        textAlign: "left",
    },
    textFieldStyle: {
        padding: "0 10% 0",
    },
    buttonStyle1: {
        margin: "0 10% 5%",
        width: "80%",
        backgroundColor: "#DB1F26",
        border: "#DB1F26",
        fontFamily: "Lato",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "16px",
        lineHeight: "40px",
        textAlign: "center",
        letterSpacing: "0.05em",
        textTransform: "uppercase",
        color: "#FFFFFF",
    },
    buttonStyle2: {
        margin: "0 10% 5%",
        width: "80%",
        backgroundColor: "#24292E",
        border: "#24292E",
        fontFamily: "Lato",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "16px",
        lineHeight: "40px",
        textAlign: "center",
        letterSpacing: "0.05em",
        textTransform: "uppercase",
        color: "#FFFFFF",
    },
    linkStyle: {
        fontFamily: "Work Sans",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "16px",
        lineHeight: "24px",
        textAlign: "center",
        color: "#0057FF",
    },
    marginNav: {
        background: "#FAFAFA",
        width: "100vw",
        height: '100%',
    },
}))

const RedCheckbox = withStyles({
    root: {
        color: red[400],
        '&$checked': {
            color: red[600],
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);


export default function RegisterView({match}) {
    const [error, setError] = useState()
    const [createUserErrorClosed, setCreateUserErrorClosed] = useState(false)
    const [createUser, createUserResponse] = useMutation(CREATE_USER)
    const [message, setMessage] = useState()
    const [state, setState] = React.useState({
        checkedTOS: false,
    });
    const handleChange = (event) => {
        setState({...state, [event.target.name]: event.target.checked});
    };

    const {
        login,
        // isAuthenticating,
        isAuthenticated
    } = useAuth()
    const location = useLocation()
    const classes = useStyles()
    const {plan, interval} = match.params

    const duplicateEmail = () =>
        createUserResponse &&
        createUserResponse.error &&
        createUserResponse.error.message.includes('Validation error')

    function passwordValue(value) {
        console.log(`${value}`);
        return `${value}`;
    }

    const fields = [
        {
            label: 'First Name *',
            attr: 'firstName',
            required: true
        },
        {
            label: 'Last Name *',
            attr: 'lastName',
            required: true
        },
        {
            label: 'Email *',
            attr: 'email',
            required: true,
            error: duplicateEmail()
        },
        {
            label: 'Password *',
            attr: 'password',
            required: true,
            type: 'password',
            getAriaLabelText: passwordValue(),
        },
        {
            label: 'Confirm Password *',
            attr: 'password2',
            required: true,
            type: 'password',
        },
    ]

    function displayError(message) {
        if (navigator.userAgent.indexOf("Chrome") !== -1) {
            setMessage(message);
        } else {
            setMessage(message);
            alert(message);
        }
    }

    function validateInput(profile) {
        if (!/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)/.test(profile.email)) {
            displayError("Email is invalid, try again");
            return false;
        } else if (!/^[a-zA-Z\u00c0-\u024f\u1e00-\u1eff]+$/g.test(profile.firstName)) {
            displayError("First name must be latin characters only");
            return false;
        } else if (!/^[a-zA-Z\u00c0-\u024f\u1e00-\u1eff]+$/g.test(profile.lastName)) {
            displayError("Last name must be latin characters only");
            return false;
        } else if (profile.password !== profile.password2) {
            displayError("Passwords must match");
            return false;
        } else if (!/^[a-zA-Z\u00c0-\u024f\u1e00-\u1eff]{1,64}$/.test(profile.firstName)) {
            displayError("First name must less than 64 characters");
            return false;
        } else if (!/^[a-zA-Z\u00c0-\u024f\u1e00-\u1eff]{1,64}$/.test(profile.lastName)) {
            displayError("Last name must less than 64 characters");
            return false;
        } else if (!/^(\w+\S+)$/.test(profile.firstName) && !/^(\w+\S+)$/.test(profile.lastName) && !/^(\w+\S+)$/.test(profile.email) && !/^(\w+\S+)$/.test(profile.password) && !/^(\w+\S+)$/.test(profile.password2)) {
            displayError("Fields can not be left blank");
            return false;
        } else if (!state.checkedTOS) {
            displayError("You must agree to the Terms of Service");
            return false;
        }
        return true;
    }

    function handleOnSave(profile) {
        if (validateInput(profile)) {
            createUser({
                variables: {
                    ...R.omit(['__typename'], profile)
                }
            })
            setCreateUserErrorClosed(false)
        } else {
            return setError(message)
            // alert("Passwords do not match")
        }
    }

    if (createUserResponse.data) {
        console.log('login', createUserResponse.data)
        const token = createUserResponse.data.createUser.sessionToken
        if (!token) {
            return setError('Signup failed')
        }
        login({token})
    }
    if (isAuthenticated()) {
      let selectedPlan = plan || 'standard'
      let selectedInterval = interval || 'year'
      const to =`/checkout/${selectedPlan}/${selectedInterval}`
      return <Redirect to={to}/>
    }
    console.log('state', location.state)
    const userStub = location.state && location.state.userStub

    function renderGithNote() {
        return (
            <Box className={classes.githubNote}>
                <Typography variant='body1'>GitHub linked successfully</Typography>
                {/* <Typography variant='body2'>
          Below you can find and edit the information we retrieved from Github.
          From now on, you will be able to use Github to login to your Red5 Pro
          account. You can optionally provide a password here, if you want to be
          able to use a password as an alternative way to login.
        </Typography> */}
            </Box>
        )
    }

    const getCreateUserError = error => {
        if (!error) return null
        console.error('error in signup', error)
        return duplicateEmail()
            ? 'Email address is already taken'
            : `Oops! Something went wrong: ${error.message}`
    }

    const navProg = {
        height: "64px",
        width: "100vw",
        color: "black",
        backgroundColor: "#E2E2E2",
        border: "1px solid black",
    };

    const progLink = {
        fontFamily: "Work Sans",
        height: "64px",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "24px",
        textAlign: "center",
        color: "#A0A0A0",
        mixBlendMode: "normal",
        margin: "16px 0 auto 0",
    };


    const navDark = {
        height: "64px",
        width: "75%",
        backgroundColor: "#171717",
    }

    const arrowBox = {
        width: "0",
        height: "64px",
        borderTop: "32px solid transparent",
        borderBottom: "32px solid transparent",
        borderLeft: "32px solid #171717",
    };

    const progLinkActive = {
        color: "#DB1F26",
        fontFamily: "Work Sans",
        height: "64px",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "24px",
        textAlign: "center",
        mixBlendMode: "normal",
        margin: "16px 0 auto 0",
    }

    const dot = {
        transform: "translateY(20%)",
        marginRight: "5px",
        height: "14px",
        width: "14px",
        backgroundColor: "#DB1F26",
        borderRadius: "50%",
        display: "inline-block",
    };


    return (
        // <div className={classes.image}>
        <Grid className={classes.marginNav} container spacing={2}>
            <Grid container style={navProg}>
                <Grid item xs={3} style={navDark}></Grid>
                <div style={arrowBox}></div>
                <Grid item xs={1} sm={1} md={1} lg={1}>
                    <h6 style={progLinkActive}><span style={dot}></span>Registration</h6>
                </Grid>
                <Grid item xs={1} sm={1} md={1} lg={1}>
                    <h6 style={progLink}>Setup Service</h6>
                </Grid>
                <Grid item xs={1} sm={1} md={1} lg={1}>
                    <h6 style={progLink}>Deploy</h6>
                </Grid>
                <Grid item xs={1} sm={1} md={1} lg={1}>
                    <h6 style={progLink}>Test Streams</h6>
                </Grid>
                <Grid item xs={1} sm={1} md={1} lg={1}>
                    <h6 style={progLink}>Create Your App</h6>
                </Grid>
                <Grid item xs={1} sm={1} md={1} lg={1}>
                    <h6 style={progLink}>Done</h6>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={6} className={classes.backgroundGrey}>
                <h3 className={classes.h3Style}>Ultra-Low-Latency Video Streaming</h3>
                <ul className={classes.listStyle}>
                    <li>
                        <h5 className={classes.h5Style}>Client-side Video Streaming SDKs</h5>
                        {/*<p className={classes.bulletTextStyle}>With Red5 Pro in your development toolset, build*/}
                        {/*    real-time*/}
                        {/*    video streaming apps for HTML5, Android</p>*/}
                    </li>
                    <li>
                        <h5 className={classes.h5Style}>Cross Platform Embeddable Player</h5>
                        {/*<p className={classes.bulletTextStyle}>With Red5 Pro in your development toolset, build*/}
                        {/*    real-time*/}
                        {/*    video streaming apps for HTML5, Android</p>*/}
                    </li>
                    <li>
                        <h5 className={classes.h5Style}>Live Video at Scale</h5>
                        {/*<p className={classes.bulletTextStyle}>With Red5 Pro in your development toolset, build*/}
                        {/*    real-time*/}
                        {/*    video streaming apps for HTML5, Android</p>*/}
                    </li>
                </ul>
            </Grid>
            <Grid item xs={12} sm={12} md={6} className={classes.borderStyle}>
                <h4 className={classes.h4Style}>Create your <span style={{color: "#DB1F26"}}>RED5</span> account</h4>
                <div>
                    <Box className={classes.root}>
                        <DataView
                            renderHeader={userStub && renderGithNote}
                            onlyEdit={true}
                            edit={true}
                            data={userStub || {}}
                            fields={fields}
                            onSave={handleOnSave}
                            cancel={false}
                            saveLabel='Proceed'
                            saveButtonVariant='contained'
                            alignHeaders='right'
                        />
                        <FormControlLabel style={{width: "90%", marginLeft: "5%"}}
                                          control={<RedCheckbox checked={state.checkedTOS} onChange={handleChange}
                                                                name="checkedTOS"/>}
                                          label={<p style={{
                                              fontFamily: "Work Sans",
                                              height: "64px",
                                              fontStyle: "normal",
                                              fontWeight: "normal",
                                              fontSize: "14px",
                                              lineHeight: "24px",
                                              textAlign: "center",
                                              paddingTop: "18px"
																					}}>By checking this box you agree to the <a href="/XDNLICENSE.txt" target="_blank"
                                                                                      style={{color: "#DB1F26"}}>Terms
                                              of Service</a></p>}
                        />
                    </Box>
                    <Error
                        message={getCreateUserError(createUserResponse.error)}
                        open={Boolean(createUserResponse.error) && !createUserErrorClosed}
                        onClose={() => setCreateUserErrorClosed(true)}
                    />
                    <Error
                        message={`${error && message}`}
                        open={!!error}
                        onClose={() => setError(null)}
                    />
                    <Info
                        message='Creating your new account...'
                        open={createUserResponse.loading}
                    />
                </div>
            </Grid>
            {/*</Row>*/}
        </Grid>
    )
}
