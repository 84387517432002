import {
  ADD_CARD,
  UPDATE_CUSTOMER,
  canChangeInterval
} from '../../models/account-api'
import { CountrySelect, EUCountrySelect } from '../../components/CountrySelect'
import { Error, Info, Loading } from '../../components/Toast'
import {
  GET_ACCOUNT,
  GET_ACCOUNT_BILLING_SUBSCRIPTIONS
} from '../../models/account-api'
import React, { useState } from 'react'
import { useMutation, useQuery } from '@apollo/react-hooks'

import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CreditCard from './CreditCard'
import CreditCardEdit from './CreditCardEdit'
import DataView from '../DataView'
import DateFormatted from '../DateFormatted'
import InvoiceList from '../../containers/billing/InvoiceList'
import Link from '@material-ui/core/Link'
import { Redirect } from 'react-router-dom'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { useAuth } from '../../auth/useAuth'
import { useStripe } from '../../components/StripeHookProvider'

const R = require('ramda')

const useStyles = makeStyles(theme => ({
  root: {},
  actions: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  button: {},
  card: {
    maxWidth: 500,
    overflow: 'visible',
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2)
  },
  subInfoBox: {
    margin: theme.spacing(2, 2, 2),
    paddingBottom: theme.spacing(1),
    borderBottom: '1px solid #aaa'
  },
  table: {
    // minWidth: 650
  },
  indent: {
    paddingLeft: 30
  },
  select: {
    border: 'none',
    paddingLeft: theme.spacing(3),
    marginTop: theme.spacing(1),
    paddingBottom: theme.spacing(2)
    // width: '100%'
  },
  cell: {
    border: 'none',
    paddingTop: 0,
    paddingBottom: 0
  },
  headerCell: {
    fontWeight: 'bold'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '100%'
  },
  mt0: {
    marginTop: 0
  },
  mt1: {
    marginTop: theme.spacing(1)
  }
}))

export default function BillingView() {
  const [vatNumberFormat, setVatNumberFormat] = useState(null)
  const stripe = useStripe()
  const [newCard, setNewCard] = useState(false)
  const { user } = useAuth()
  const classes = useStyles()
  const [updateCustomer, updateCustomerResponse] = useMutation(UPDATE_CUSTOMER)
  const [addCard, addCardResponse] = useMutation(ADD_CARD)
  // let [customer, setCustomer] = useState()
  const [saving, setSaving] = useState(false)
  const [name, setName] = useState('')
  const [stripeError, setStripeError] = useState()
  const [errorDismissed, setErrorDismissed] = useState(false)
  const [redirect, setRedirect] = useState()

  const { loading, error, data } = useQuery(GET_ACCOUNT_BILLING_SUBSCRIPTIONS, {
    variables: { userId: user.id }
  })
  if (loading) return <Loading open={loading} />
  if (redirect) return <Redirect to={redirect} />
  console.log('data', data)

  const gqlError =
    error || updateCustomerResponse.error || addCardResponse.error

  const billingSubs = data.account.billingSubscriptions
  const customer = data.account.customer
  const card = customer && customer.creditCard

  function handleSaveCustomer(customer) {
    const taxIdDetails = customer.taxId || {}
    const customerInput = {
      ...R.omit(['creditCard', 'id', '__typename'], customer),
      address: {
        ...R.omit(['id', '__typename'], customer.address)
      },
      taxId: taxIdDetails.id
        ? R.pick(['id', 'value'], taxIdDetails)
        : taxIdDetails.value
        ? { value: taxIdDetails.value }
        : undefined
    }
    updateCustomer({
      variables: { id: customer.id, customer: customerInput }
    })
    setErrorDismissed(false)
  }
  function handleCardEditCancelClicked() {
    setNewCard(false)
  }
  async function createCardToken() {
    try {
      const stripeResponse = await stripe.createToken({ name })
      console.log('stripe response', stripeResponse)
      return stripeResponse.token.id
    } catch (e) {
      console.error(e)
      setStripeError(e)
    }
  }
  function getErrorMessage(error) {
    return error
      ? error.message.includes('card was declined')
        ? 'Your card was declined. Please check your card details and try again.'
        : error.message
      : ''
  }
  async function handleCardFormSubmit(e) {
    e.preventDefault()
    setSaving(true)
    console.log('storing new credit card')
    const token = await createCardToken()
    setSaving(false)
    console.log('got card token', token)
    addCard({
      variables: {
        userId: user.id,
        token
      },
      refetchQueries: () => [
        { query: GET_ACCOUNT, variables: { userId: user.id } }
      ]
    })
    setNewCard(false)
  }
  const fields = [
    {
      label: 'Business Name',
      attr: 'name'
    },
    {
      label: 'Tax Id / VAT Number',
      attr: 'taxId.value',
      renderEditor: ({ data, value, setValue, setAttr }) => {
        return (
          <>
            <TableRow>
              <TableCell
                className={clsx(classes.cell, classes.headerCell)}
                scope='row'
              >
                EU Tax Country
              </TableCell>
              <TableCell className={classes.select} scope='row'>
                <EUCountrySelect
                  value={data && data.taxId ? data.taxId.country : ''}
                  onChange={option => {
                    setVatNumberFormat(option.format)
                    setAttr('taxId.country', option.id)
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                className={clsx(classes.cell, classes.headerCell)}
                scope='row'
              >
                Vat number
              </TableCell>
              <TableCell className={classes.cell} scope='row'>
                <TextField
                  // margin='dense'
                  id='vat-number'
                  placeholder={vatNumberFormat || 'Tax ID / VAT Number'}
                  className={clsx(classes.textField, classes.mt0)}
                  value={value}
                  onChange={e => setValue(e.target.value)}
                />
              </TableCell>
            </TableRow>
          </>
        )
      }
    },
    {
      subtitle: 'Business address'
    },
    {
      label: 'Address line 1',
      attr: 'address.line1',
      indent: true
    },
    {
      label: 'Address line 2',
      attr: 'address.line2',
      indent: true
    },
    {
      label: 'City',
      attr: 'address.city',
      indent: true
    },
    {
      label: 'State',
      attr: 'taxId.state',
      indent: true
    },
    {
      label: 'Postal Code / Zip',
      attr: 'address.postalCode',
      indent: true
    },
    {
      label: 'Country',
      attr: 'address.country',
      indent: true,
      renderEditor: ({ data, value, setValue }) => {
        return (
          <>
            <TableRow>
              <TableCell
                className={clsx(
                  classes.cell,
                  classes.indent,
                  classes.headerCell
                )}
                scope='row'
              >
                Country
              </TableCell>
              <TableCell className={classes.select} scope='row'>
                <CountrySelect
                  value={data && data.address ? data.address.country : ''}
                  onChange={option => {
                    setVatNumberFormat(option.format)
                    setValue(option.value)
                  }}
                />
              </TableCell>
            </TableRow>
          </>
        )
      }
    }
  ]
  function handleChangeInterval(extSubscriptionId, interval, e) {
    e.preventDefault()
    setRedirect(`/interval/${extSubscriptionId}/${interval}`)
  }

  const planDescs = new Map([
    ['startup', 'Startup Pro'],
    ['developer', 'Developer Pro'],
    ['autoscale', 'Growth Pro'],
    ['network', 'Network'],
    ['sdk', 'Mobile SDK']
  ])

  return (
    <div className={classes.root}>
      <Typography variant='h5' gutterBottom>
        Billing
      </Typography>

      <Box mt={3}>
        <DataView
          data={customer}
          fields={fields}
          onSave={handleSaveCustomer}
          edit={!!updateCustomerResponse.error}
        />

        <Box mt={5} mb={3}>
          <Typography variant='h5'>Credit Card</Typography>
        </Box>

        <form onSubmit={handleCardFormSubmit}>
          <Card className={classes.card}>
            <CardContent>
              {!newCard && card ? (
                <>
                  <CreditCard card={card} />
                </>
              ) : (
                <CreditCardEdit name={name} setName={setName} />
              )}
            </CardContent>

            <Box className={classes.actions}>
              {!newCard && (
                <Button
                  onClick={e => setNewCard(true)}
                  color='primary'
                  className={classes.button}
                >
                  Edit
                </Button>
              )}
              {newCard && (
                <>
                  <Button
                    onClick={e => handleCardEditCancelClicked()}
                    //   variant='contained'
                    color='secondary'
                    disabled={saving}
                    className={classes.button}
                  >
                    Cancel
                  </Button>
                  <Button
                    type='submit'
                    //   variant='contained'
                    color='primary'
                    disabled={saving}
                    className={classes.button}
                  >
                    Save card
                  </Button>
                </>
              )}
            </Box>
          </Card>
        </form>

        {billingSubs && billingSubs.length > 0 && (
          <>
            <Box mt={5} mb={2}>
              <Typography variant='h5'>Subscriptions</Typography>
            </Box>
            {billingSubs.map(sub => (
              <Card className={classes.card}>
                <Box className={classes.subInfoBox}>
                  <Typography variant='body1' gutterBottom>
                    Subscription for:{' '}
                    <strong>
                      {sub.items
                        .map(R.bind(planDescs.get, planDescs))
                        .join(', ')}
                    </strong>
                  </Typography>
                  <Typography variant='body2'>
                    Created:{' '}
                    <DateFormatted value={new Date(sub.created * 1000)} />
                  </Typography>
                  <Typography variant='body2'>
                    Current period ends:{' '}
                    <DateFormatted
                      value={new Date(sub.currentPeriodEnd * 1000)}
                    />
                  </Typography>
                  <Typography variant='body2' component='p'>
                    You are on a{' '}
                    <em>{sub.interval === 'month' ? 'monthly' : 'annual'}</em>{' '}
                    billing cycle.{' '}
                    {canChangeInterval(
                      // @ts-ignore
                      {
                        extSubscriptionId: sub.id,
                        subscriptions: data.account.subscriptions
                      }
                    ) && (
                      <Link
                        href='#'
                        onClick={R.partial(handleChangeInterval, [
                          sub.id,
                          sub.interval === 'month' ? 'year' : 'month'
                        ])}
                      >
                        Switch to{' '}
                        {sub.interval === 'month' ? 'annual' : 'monthly'}.
                      </Link>
                    )}
                  </Typography>
                </Box>
                <Box ml={2}>
                  <Typography variant='body1'>Invoices</Typography>
                </Box>
                <InvoiceList extSubscriptionId={sub.id} />
              </Card>
            ))}
          </>
        )}
        <Info
          message='Saving...'
          open={
            saving || updateCustomerResponse.loading || addCardResponse.loading
          }
        />
        <Error
          message={getErrorMessage(gqlError)}
          open={!!gqlError && !errorDismissed}
          onClose={() => setErrorDismissed(true)}
        />
        {stripeError && (
          <Info
            message={`Failed store credit dard: ${stripeError.message}`}
            open={!!stripeError && !errorDismissed}
            onClose={() => setErrorDismissed(true)}
          />
        )}
      </Box>
    </div>
  )
}
