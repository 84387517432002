import { regionToDigitalOceanRegion } from './xdn'

const version = process.env.REACT_APP_R5PRO_SM_API_VERSION

const GET = async (url) => {
  try {
    const response = await fetch(url)
    const json = await response.json()
    if (json.errorMessage) {
      throw new Error(json.errorMessage)
    }
    return json
  } catch (e) {
    throw e
  }
}

const POST = async (url, payload) => {
  try {
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/vnd.api+json'
      }
    })
    const json = await response.json()
    if (json.errorMessage) {
      throw new Error(json.errorMessage)
    }
    return json
  } catch (e) {
    throw e
  }
}

const DELETE = async (url, payload) => {
  try {
    const response = await fetch(url, {
      method: 'DELETE'
    })
    const json = await response.json()
    if (json.errorMessage) {
      throw new Error(json.errorMessage)
    }
    return json
  } catch (e) {
    throw e
  }
}

export const getNodeGroups = async (sm, token) => {
  const url = `${sm}/streammanager/api/${version}/admin/nodegroup?accessToken=${token}`
  return GET(url)
}

export const getGroupState = async (sm, token, groupName) => {
  const url = `${sm}/streammanager/api/${version}/admin/nodegroup/${groupName}?accessToken=${token}`
  return GET(url)
}

export const getNodeGroupOrigins = async (sm, token, groupName) => {
  const url = `${sm}/streammanager/api/${version}/admin/nodegroup/${groupName}/node/origin?accessToken=${token}`
  return GET(url)
}

export const getNodeGroupEdges = async (sm, token, groupName) => {
  const url = `${sm}/streammanager/api/${version}/admin/nodegroup/${groupName}/node/edge?accessToken=${token}`
  return GET(url)
}

export const getStreamStats = async (sm, token) => {
  const url = `${sm}/streammanager/api/${version}/event/list/stats` 
  return GET(url)
}

export const getAllUsageStats = async (sm, token) => {
  const url = `${sm}/streammanager/api/${version}/admin/event/usage?accessToken=${token}`
  return GET(url)
}

export const getLaunchConfigs = async (sm, token) => {
  const url = `${sm}/streammanager/api/${version}/admin/configurations/launchconfig?accessToken=${token}`
  return GET(url)
}

export const getScalePolicies = async (sm, token) => {
  const url = `${sm}/streammanager/api/${version}/admin/configurations/scalepolicy?accessToken=${token}`
  return GET(url)
}

export const createNewNodeGroup = async (sm, token, region) => {
  const launchConfigs = await getLaunchConfigs(sm, token)
  const scalePolicies = await getScalePolicies(sm, token)
  const payload = {
    launchConfig: launchConfigs[0],
    scalePolicy: scalePolicies[0],
    regions: [regionToDigitalOceanRegion(region)]
  }
  const url = `${sm}/streammanager/api/${version}/admin/nodegroup?accessToken=${token}`
  return POST(url, payload)
}

export const deleteNodeGroup = async (sm, token, groupName) => {
  const url = `${sm}/streammanager/api/${version}/admin/nodegroup/${groupName}?accessToken=${token}`
  return DELETE(url)
}

export const startOrGetOrigin = async (sm, token, groupName) => {
  try {
    const nodes = await GET(`${sm}/streammanager/api/${version}/admin/nodegroup/${groupName}/node?accessToken=${token}`)
    if (nodes && nodes.length > 0) {
      const origins = nodes.filter(n => n.role === 'origin')
      if (origins && origins.length > 0) {
        console.log(`Found existing origin at: ${origins[0].address}`)
        return origins[0]
      }
    }
  } catch (e) {
    // did not exist...
  }
  const url = `${sm}/streammanager/api/${version}/admin/nodegroup/${groupName}/node/origin?accessToken=${token}`
  return POST(url)
}
