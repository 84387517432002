import { amber, blue, green } from '@material-ui/core/colors'

import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CloseIcon from '@material-ui/icons/Close'
import ErrorIcon from '@material-ui/icons/Error'
import IconButton from '@material-ui/core/IconButton'
import InfoIcon from '@material-ui/icons/Info'
import PropTypes from 'prop-types'
import React from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import WarningIcon from '@material-ui/icons/Warning'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon
}

const useStyles1 = makeStyles(theme => ({
  main: {
    width: 200
  },
  success: {
    backgroundColor: green[600]
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  info: {
    backgroundColor: blue[700]
  },
  warning: {
    backgroundColor: amber[700]
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  message: {
    display: 'flex',
    alignItems: 'center'
  }
}))

export function Toast(props) {
  const classes = useStyles1()
  const {
    className,
    message,
    onClose = () => {},
    variant = 'info',
    open,
    horizontal = 'left',
    vertical = 'bottom',
    autoHideDuration = 6000,
    ...other
  } = props
  const Icon = variantIcon[variant]

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    onClose()
  }
  const anchorOrigin = {
    vertical,
    horizontal
  }

  return (
    <Snackbar
      anchorOrigin={anchorOrigin}
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
    >
      <SnackbarContent
        className={clsx(classes[variant], className)}
        aria-describedby='client-snackbar'
        message={
          <span id='client-snackbar' className={classes.message}>
            <Icon className={clsx(classes.icon, classes.iconVariant)} />
            {message}
          </span>
        }
        action={[
          <IconButton
            key='close'
            aria-label='close'
            color='inherit'
            onClick={handleClose}
          >
            <CloseIcon className={classes.icon} />
          </IconButton>
        ]}
        {...other}
      />
    </Snackbar>
  )
}

Toast.propTypes = {
  open: PropTypes.bool,
  className: PropTypes.string,
  message: PropTypes.string,
  horizontal: PropTypes.string,
  vertical: PropTypes.string,
  onClose: PropTypes.func,
  autoHideDuration: PropTypes.number,
  variant: PropTypes.oneOf(['error', 'info', 'success', 'warning']).isRequired
}

export const Loading = props => (
  <Toast
    variant='info'
    message='Loading...'
    vertical='top'
    horizontal='center'
    {...props}
  />
)

export const Error = props => (
  <Toast variant='error' vertical='top' horizontal='center' {...props} />
)

export const Info = props => (
  <Toast variant='info' vertical='bottom' horizontal='left' {...props} />
)
