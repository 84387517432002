const R = require('ramda')

const INTERVALS = {
  annual: 'annual',
  monthly: 'monthly'
}

const XDN_PLANS = {
  xdn: {
    title: 'xdn',
    monthly: {
      planId: 21,
      subscriptionName: 'xdn-beta-monthly'
    }
  },
  starter: {
    title: 'starter',
    monthly: {
      planId: 13,
      subscriptionName: 'xdn-starter-monthly'
    },
    annual: {
      planId: 14,
      subscriptionName: 'xdn_starter_annual'
    }
  },
  standard: {
    title: 'standard',
    monthly: {
      planId: 15,
      subscriptionName: 'xdn-standard-monthly'
    },
    annual: {
      planId: 16,
      subscriptionName: 'xdn_standard_annual'
    }
  },
  advanced: {
    title: 'advanced',
    monthly: {
      planId: 17,
      subscriptionName: 'xdn-advanced-monthly'
    },
    annual: {
      planId: 18,
      subscriptionName: 'xdn_advanced_annual'
    }
  },
  custom: {
    title: 'custom',
    monthly: {
      planId: 13,
      subscriptionName: 'xdn-enterprise-monthly'
    },
    annual: {
      planId: 14,
      subscriptionName: 'xdn_enterprise_annual'
    }
  }
}

const planFromRecommendation =  (rec, interval = INTERVALS.annual) => {
  const plan = XDN_PLANS[rec.toLowerCase()]
  if (plan) {
    return plan[interval]
  }
  return XDN_PLANS.xdn.monthly
}


const planIdFromRecommendation = (rec, interval = INTERVALS.annual) => {
  const plan = planFromRecommendation(rec, interval)
  return plan.planId
}

const isMonthlyOnly = plan => ['xdn', 'developer'].includes(plan)
const getPrice = type => R.find(R.propEq('type', type), R.values(prices))
const getPlan = R.pipe(getPrice, R.prop('plan'))
const planToSubscriptionTypeName = (plan, interval = INTERVALS.MONTHLY) => {
  return planFromRecommendation(plan, interval).subscriptionName
}

const prices = {
  starter_monthly: {
    id: XDN_PLANS.starter.monthly.planId,
    price: 0.00,
    priceDesc: '$119/month',
    name: 'Starter',
    plan: XDN_PLANS.starter.monthly.subscriptionName,
    type: XDN_PLANS.starter.monthly.planId
  },
  starter_annual: {
    id: XDN_PLANS.starter.annual.planId,
    price: 0.00,
    priceDesc: '$99/month',
    name: 'Starter',
    plan: XDN_PLANS.starter.annual.subscriptionName,
    type: XDN_PLANS.starter.annual.planId
  },
  standard_monthly: {
    id: XDN_PLANS.standard.monthly.planId,
    price: 0.00,
    priceDesc: '$299/month',
    name: 'Standard',
    plan: XDN_PLANS.standard.monthly.subscriptionName,
    type: XDN_PLANS.standard.monthly.planId
  },
  standard_annual: {
    id: XDN_PLANS.standard.annual.planId,
    price: 0.00,
    priceDesc: '$279/month',
    name: 'Standard',
    plan: XDN_PLANS.standard.annual.subscriptionName,
    type: XDN_PLANS.standard.annual.planId
  },
  advanced_monthly: {
    id: XDN_PLANS.advanced.monthly.planId,
    price: 0.00,
    priceDesc: '$599/month',
    name: 'Advanced',
    plan: XDN_PLANS.advanced.monthly.subscriptionName,
    type: XDN_PLANS.advanced.monthly.planId
  },
  advanced_annual: {
    id: XDN_PLANS.advanced.annual.planId,
    price: 0.00,
    priceDesc: '$499/month',
    name: 'Advanced',
    plan: XDN_PLANS.advanced.annual.subscriptionName,
    type: XDN_PLANS.advanced.annual.planId
  },
  custom_monthly: {
    id: XDN_PLANS.custom.monthly.planId,
    price: 0.00,
    priceDesc: '$2399/month',
    name: 'Custom',
    plan: XDN_PLANS.custom.monthly.subscriptionName,
    type: XDN_PLANS.custom.monthly.planId
  },
  custom_annual: {
    id: XDN_PLANS.custom.annual.planId,
    price: 0.00,
    priceDesc: '$1999/month',
    name: 'Custom',
    plan: XDN_PLANS.custom.annual.subscriptionName,
    type: XDN_PLANS.custom.annual.planId
  },
  xdn_monthly: {
    id: 21,
    price: 0.00,
    priceDesc: '$0/month',
    name: 'XDN',
    plan: 'xdn-beta-monthly',
    type: 21
  },
  developer_monthly: {
    id: 2,
    price: 29.99,
    priceDesc: '$29.99/month',
    additional: 'Additional instances $109/month',
    name: 'Developer',
    plan: 'developer',
    type: 5
  },
  autoscale_monthly: {
    id: 6,
    price: 299,
    priceDesc: '$299/month',
    additional: 'Additional instances $89/month',
    name: 'Growth',
    plan: 'autoscale',
    type: 9
  },
  autoscale_annual: {
    id: 7,
    price: 279,
    price_annual: 3348,
    priceDesc: '$279/month billed annually at $3348',
    additional: 'Additional instances $89/month',
    name: 'Growth',
    plan: 'autoscale',
    type: 10
  },
  startup_monthly: {
    id: 4,
    price: 119,
    priceDesc: '$119/month',
    additional: 'Additional instances $109/month',
    name: 'Startup',
    plan: 'startup',
    type: 7
  },
  startup_annual: {
    id: 5,
    price: 109,
    price_annual: 1308,
    priceDesc: '$109/month billed annually at $1308',
    additional: 'Additional instances $109/month',
    name: 'Startup',
    plan: 'startup',
    type: 8
  },
  sdk_monthly: {
    id: 9,
    price: 419,
    priceDesc: '$419/month',
    name: 'Mobile SDK',
    plan: 'sdk',
    type: 11
  },
  sdk_annual: {
    id: 8,
    price: 349,
    price_annual: 4188,
    priceDesc: '$349/month billed annually at $4188',
    name: 'Mobile SDK',
    plan: 'sdk',
    type: 4
  },
  trial_monthly: {
    id: 0,
    price: 0,
    priceDesc: 'Free for 30 days',
    name: 'Trial',
    plan: 'trial',
    type: 0
  },
  trial_annual: {
    id: 0,
    price: 0,
    priceDesc: 'Free for 30 days',
    name: 'Trial',
    plan: 'trial',
    type: 0
  }
}

const features = {
  developer: {
    features: [
      'Low Latency Live Streaming',
      'Live Recordings',
      'Video on Demand',
      'Multi-protocol Support'
    ],
    protocols: [
      'WebRTC',
      'RTMP',
      'RTSP',
      'HLS',
      'Adaptive Bit Rate',
      'Transcoding',
      'IP Camera'
    ],
    scalability: ['Single node deployment'],
    support: ['Community support']
  },
  autoscale: {
    features: ['All Startup features', 'Autoscaling for unlimited capacity'],
    protocols: [
      'WebRTC',
      'RTMP',
      'RTSP',
      'HLS',
      'Adaptive Bit Rate',
      'Transcoding',
      'IP Camera'
    ],
    scalability: [
      'Starts at 3 Instances',
      'Autoscaling for unlimited capacity',
      'AWS',
      'Google Cloud',
      'Azure'
    ],
    support: ['Private Slack Channel']
  },
  startup: {
    features: ['All Developer features', 'Unlimited connections'],
    protocols: [
      'WebRTC',
      'RTMP',
      'RTSP',
      'HLS',
      'Adaptive Bit Rate',
      'Transcoding',
      'IP Camera'
    ],
    scalability: ['Single node deployment'],
    support: ['Email support']
  }
}

module.exports = {
  INTERVALS,
  XDN_PLANS,
  prices,
  features,
  isMonthlyOnly,
  getPrice,
  getPlan,
  planToSubscriptionTypeName,
  planIdFromRecommendation
}
