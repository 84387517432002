import { FormControlLabel, FormHelperText } from '@material-ui/core/'

import Box from '@material-ui/core/Box'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import React from 'react'
import grey from '@material-ui/core/colors/grey'
import { makeStyles } from '@material-ui/core/styles'

const R = require('ramda')

const useStyles = makeStyles({
  card: {
    // maxWidth: 250
    margin: 0,
    marginLeft: "10%",
    padding: 0,
  },
  intervalSelect: {
    display: 'flex',
    flexDirection: 'row'
  },
  ml: {
    marginLeft: 0
  },
  formControl: {
    marginLeft: 0,
    color: props => (props.disabled ? grey[500] : grey[800])
  }
})

export default function BillingIntervalSelect({
  label = 'Show prices as',
  onIntervalSelected,
  value,
  disabled,
  helperText = null
}) {
  const classes = useStyles({ disabled })

  return (
    <Box className={classes.card}>
      <FormHelperText>{helperText}</FormHelperText>

      <FormControlLabel
        className={classes.formControl}
        value='billing-interval'
        labelPlacement='start'
        label={label}
        control={
          <div>
            <RadioGroup
              defaultValue={value}
              aria-label='Billing interval'
              name='billing-interval'
              className={classes.intervalSelect}
            >
              <FormControlLabel
                className={classes.ml}
                value='year'
                control={
                  <Radio
                    disabled={disabled}
                    color='primary'
                    onClick={R.partial(onIntervalSelected, ['year'])}
                  />
                }
                label='yearly'
              />
              <FormControlLabel
                value='month'
                control={
                  <Radio
                    disabled={disabled}
                    color='primary'
                    onClick={R.partial(onIntervalSelected, ['month'])}
                  />
                }
                label='monthly'
              />
            </RadioGroup>
          </div>
        }
      />
    </Box>
  )
}
