import { CountrySelect, EUCountrySelect } from '../components/CountrySelect'
import React, { useState } from 'react'

import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'
import TextField from '@material-ui/core/TextField'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'

const R = require('ramda')

const useStyles = makeStyles(theme => ({
  formField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '100%'
  },
  selectLabel: {
    marginTop: 10,
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: '100%'
  },
  select: {
    // marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '100%'
  },
  dense: {
    marginTop: 34
  },
  formLabel: {
    color: theme.palette.secondary[500]
  },
  mt0: {
    marginTop: 0
  }
}))

/*
      token,
      name,
      `Subscription for ${planNames}`,
      address,
      taxIdDetails

       input AddressInput {
    city: String
    country: String
    line1: String
    line2: String
    postalCode: String
    state: String
  }

*/

export default function BusinessInfo({
  values,
  onFieldChange,
  onAddressFieldChange,
    required
}) {
  console.log('values', values)
  const [vatNumberFormat, setVatNumberFormat] = useState(null)
  const classes = useStyles()

  function handleChange(field, e) {
    onFieldChange(field, e.target.value)
  }
  function handleAddressChange(field, e) {
    onAddressFieldChange(field, e.target.value)
  }

  return (
    <>
      <TextField
          autoComplete='off'
          autoComplete='off'
        id='business-name'
        label='Business name'
        placeholder='Business name'
        className={classes.formField}
        margin='normal'
        value={values.name}
        onChange={R.partial(handleChange, ['name'])}
      />
      <TextField
          autoComplete='off'
        id='line1'
        label='Address line 1'
        placeholder='Address line 1'
        className={classes.formField}
        margin='normal'
        value={values.address.line1}
        onChange={R.partial(handleAddressChange, ['line1'])}
        required={required}
      />
      <TextField
          autoComplete='off'
        id='line2'
        label='Address line 2'
        placeholder='Address line 2'
        className={classes.formField}
        margin='normal'
        value={values.address.line2}
        onChange={R.partial(handleAddressChange, ['line2'])}
      />
      <TextField
          autoComplete='off'
        id='city'
        label='City'
        placeholder='City'
        className={classes.formField}
        margin='normal'
        value={values.address.city}
        onChange={R.partial(handleAddressChange, ['city'])}
        required={required}
      />
      <TextField
          autoComplete='off'
        id='state'
        label='State'
        placeholder='State'
        className={classes.formField}
        margin='normal'
        value={values.address.state}
        onChange={R.partial(handleAddressChange, ['state'])}
        required={required}
      />
        <TextField
          autoComplete='off'
            id='zip'
            label='Zip/Postal Code'
            placeholder='Zip/Postal Code'
            className={classes.formField}
            margin='normal'
            value={values.address.postalCode}
            onChange={R.partial(handleAddressChange, ['postalCode'])}
            required={required}
        />
      <Grid container spacing={1} alignItems='flex-start'>
        <Grid item xs={12}>
          <div className={classes.select}>
            <InputLabel className={classes.selectLabel}>Country *</InputLabel>
            <CountrySelect
              value={values.address.country}
              onChange={option => onAddressFieldChange('country', option.value)}
              required={required}
            />
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.select}>
            <InputLabel className={classes.selectLabel}>
              EU Tax Country
            </InputLabel>
            <EUCountrySelect
              value={values.vatCountry}
              onChange={option => {
                setVatNumberFormat(option.format)
                onFieldChange('vatCountry', option.id)
              }}
            />
          </div>
        </Grid>
        <Grid item xs={12}>
          <InputLabel className={classes.selectLabel}>
            Tax ID / VAT Number
          </InputLabel>
          <TextField
          autoComplete='off'
            inputProps={{
              style: {
                height: 38,
                padding: '0 14px'
              }
            }}
            variant='outlined'
            margin='dense'
            id='vat-number'
            placeholder={vatNumberFormat || 'Tax ID / VAT Number'}
            className={clsx(classes.formField, classes.mt0)}
            value={values.vatNumber}
            onChange={R.partial(handleChange, ['vatNumber'])}
          />
        </Grid>
      </Grid>
    </>
  )
}
