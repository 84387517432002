import { Error, Loading } from '../components/Toast'
import React, { useEffect } from 'react'

import { GITHUB_LOGIN } from '../models/account-api'
import { Redirect } from 'react-router-dom'
import { useAuth } from './useAuth'
import { useLocation } from 'react-router-dom'
import { useMutation } from '@apollo/react-hooks'

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

export default function GithubAuthCallback() {
  const query = useQuery()
  const code = query.get('code')
  const [executeLogin, loginResponse] = useMutation(GITHUB_LOGIN)
  const {
    login,
    isAuthenticating,
    isAuthenticated,
    setAuthenticated
  } = useAuth()

  useEffect(() => {
    executeLogin({ variables: { code } })
  }, [code, executeLogin])

  if (!code) {
    return <Error message={`Auth code note specified`} open={true} />
  }
  if (loginResponse.error) {
    return (
      <Error
        message={`Something went wrong: ${loginResponse.error.message}`}
        open={loginResponse.error}
      />
    )
  }
  if (loginResponse.data && !loginResponse.data.githubLogin.sessionToken) {
    return (
      <Redirect
        to={{
          pathname: '/register',
          state: { userStub: loginResponse.data.githubLogin }
        }}
      />
    )
  }
  if (
    loginResponse.data &&
    loginResponse.data.githubLogin.sessionToken &&
    !isAuthenticating
  ) {
    console.log(
      'loggin in with auth token',
      loginResponse.data.githubLogin.sessionToken
    )
    login({ token: loginResponse.data.githubLogin.sessionToken })
    setAuthenticated()
  }
  if (isAuthenticated()) {
    return <Redirect to='/subscriptions' />
  }
  return <Loading message='Loading...' open={true} />
}
