import { Error, Loading } from '../../components/Toast'
import React, { useState } from 'react'

import { FormattedDate } from 'react-intl'
import { GET_INVOICES } from '../../models/account-api'
import Link from '@material-ui/core/Link'
import PagedTable from './PagedTable'
import { useQuery } from '@apollo/react-hooks'

const ROWS_PER_PAGE = 5

const formatMoney = amount =>
  amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')

const headCells = [
  {
    attr: 'created',
    label: 'Date',
    align: 'right',
    render: ({ value }) => (
      <FormattedDate
        value={value * 1000}
        year='numeric'
        month='short'
        day='numeric'
      />
    )
  },
  {
    attr: 'amountDue',
    label: 'Amount',
    align: 'right',
    render: ({ value }) => formatMoney(value / 100)
  },
  {
    attr: 'paid',
    label: 'Paid?',
    render: ({ value }) => (value ? 'Paid' : 'Unpaid')
  },
  {
    attr: 'invoicePdf',
    label: 'PDF',
    render: ({ value, id }) => (
      <Link underline='none' href={value}>
        Download
      </Link>
    )
  }
]

export default function InvoiceList({ extSubscriptionId }) {
  const [page, setPage] = useState(0)
  const [startingAfter, setStartingAfter] = useState(null)
  const { data, error, loading } = useQuery(GET_INVOICES, {
    variables: {
      extSubscriptionId,
      startingAfter,
      limit: ROWS_PER_PAGE
    }
  })
  function handlePageChange(page) {
    setPage(page)
    setStartingAfter(
      page === 0 ? null : data.getInvoices.invoices[page * ROWS_PER_PAGE - 1].id
    )
  }

  if (loading) return <Loading open={loading} />
  if (error)
    return (
      <Error message={`Oops! Something went wrong: ${error}`} open={error} />
    )
  return (
    <PagedTable
      page={page}
      setPage={handlePageChange}
      perPage={ROWS_PER_PAGE}
      rows={data.getInvoices.invoices}
      totalRows={data.getInvoices.totalCount}
      headCells={headCells}
    />
  )
}
