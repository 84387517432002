import React from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  table: {
    // minWidth: 650
  }
})

export default function CreditCard({ title = null, card, dense = false }) {
  const classes = useStyles()
  return (
    <>
      <Table
        className={classes.table}
        aria-label='credit card'
        size={dense ? 'small' : 'medium'}
      >
        <TableBody>
          {title && (
            <TableRow>
              <TableCell colSpan={3}>
                <Typography variant='subtitle1'>{title}</Typography>
              </TableCell>
            </TableRow>
          )}
          <TableRow>
            <TableCell>
              <Typography variant='subtitle1' color='primary'>
                {card.brand}
              </Typography>
            </TableCell>
            <TableCell>... {card.last4}</TableCell>
            <TableCell>
              {card.expMonth} / {card.expYear}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </>
  )
}
