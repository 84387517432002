import { Error, Loading } from '../components/Toast'
import { GET_ACCOUNT, activateSubscriptions } from '../models/account-api'
import React, { useState } from 'react'
import { useApolloClient, useQuery } from '@apollo/react-hooks'

import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import { Redirect } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { useAuth } from '../auth/useAuth'
import { useStripe } from '../components/StripeHookProvider'
import { useToasts } from '../components/toasts'

const useStyles = makeStyles(theme => ({
  purchaseForm: {
    width: 500,
    marginTop: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBottom: 20,
    overflow: 'visible'
  }
}))

function CheckoutForm({ paymentIntentSecret, invoiceId }) {
  const client = useApolloClient()
  const stripe = useStripe()
  const classes = useStyles({})
  const [isCardAuthInProgress, setCardAuthInProgress] = useState(false)
  const [error, setError] = useState(null)

  async function handleSubmit(e) {
    e.preventDefault()
    console.log('handleCardAuth ' + paymentIntentSecret, stripe)
    setCardAuthInProgress(true)
    const result = await stripe.handleCardPayment(paymentIntentSecret)
    if (result.error) {
      console.error(result.error)
      return setError(result.error)
    }
    console.log('result', result)
    await activateSubscriptions(client, invoiceId)

    setCardAuthInProgress(false)
  }
  if (isCardAuthInProgress) {
    return (
      <Loading
        message='Waiting for card authorization to complete...'
        open={isCardAuthInProgress}
      />
    )
  }
  if (error) {
    return <Error message='Unable to authenticate credit card.' open={true} />
  }

  return (
    <form onSubmit={handleSubmit}>
      <Card className={classes.purchaseForm}>
        <CardContent>
          <p>
            Your credit card needs further authentication before the subscription
            signup can be completed.
          </p>
        </CardContent>
        <CardActions>
          <Button type='submit' variant='contained' color='primary'>
            Proceed to Authorization
          </Button>
        </CardActions>
      </Card>
    </form>
  )
}

export default function CardAuth() {
  const { addToast } = useToasts()
  const { user } = useAuth()
  const { loading, error, data } = useQuery(GET_ACCOUNT, {
    variables: { userId: user.id }
  })
  if (loading) return <div>Loading...</div>
  if (error) {
    return <p>Error! {error.message}</p>
  }

  const subscription = data.account.subscriptions.find(
    s => s.paymentStatus === 'requires_source_action'
  )
  if (!subscription) {
    addToast({
      message: 'Your subscription was created successfully.',
      variant: 'success',
      open: true,
      horizontal: 'right',
      vertical: 'top'
    })
    return <Redirect to='/subscriptions' />
  }

  return (
    <CheckoutForm
      paymentIntentSecret={subscription.paymentIntentSecret}
      invoiceId={subscription.invoiceId}
    />
  )
}
