import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import React from 'react'

export default function FormDialog({
  title,
  confirmButtonTitle = 'OK',
  msg,
  open,
  onConfirm = () => {},
  onCancel = () => {},
  renderButtons = null,
  renderContent = () => <></>
}) {
  const handleConfirm = () => {
    onConfirm()
  }
  const handleCancel = () => {
    onCancel()
  }
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleConfirm}
        aria-labelledby='form-dialog-title'
        fullWidth={true}
        maxWidth='xs'
      >
        <DialogTitle id='form-dialog-title'>{title}</DialogTitle>
        <DialogContent>
          {msg && <DialogContentText>{msg}</DialogContentText>}
          {renderContent()}
        </DialogContent>
        <DialogActions>
          {!renderButtons && (
            <>
              <Button color='primary' onClick={handleCancel}>
                Cancel
              </Button>
              <Button color='primary' onClick={handleConfirm}>
                {confirmButtonTitle}
              </Button>
            </>
          )}
          {renderButtons && renderButtons()}
        </DialogActions>
      </Dialog>
    </div>
  )
}
