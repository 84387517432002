import {
//    CREATE_NETWORK_SUBSCRIPTION,
    CREATE_SUBSCRIPTIONS,
    GET_ACCOUNT,
    sortSubscriptions
} from '../models/account-api'
import {CardElement, Elements, injectStripe} from 'react-stripe-elements'
import {Error, Loading} from '../components/Toast'
import React, {useState, useEffect} from 'react'
import {
    INTERVALS,
    planToSubscriptionTypeName,
    isMonthlyOnly,
    prices
} from '../models/plans'
import {useMutation, useQuery} from '@apollo/react-hooks'

import BillingIntervalSelect from './BillingIntervalSelect'
import BusinessInfo from './BusinessInfo'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Collapse from '@material-ui/core/Collapse'
import CreditCard from './billing/CreditCard'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import OrderSummary from './OrderSummary'
import {Redirect} from 'react-router-dom'
import Switch from '@material-ui/core/Switch'
import TextField from '@material-ui/core/TextField'
import {Typography} from '@material-ui/core'
import clsx from 'clsx'
import {makeStyles} from '@material-ui/core/styles'
import {useAuth} from '../auth/useAuth'
import {useToasts} from '../components/toasts'
import Grid from "@material-ui/core/Grid";
import "./hosted/xdnStyles.css";
import "../index.css"
import FlowProgressBar from "../components/FlowProgressBar";
import "../components/componentStyles.css"

const R = require('ramda')

const useStyles = makeStyles(theme => ({
    purchaseForm: {
        width: "80%",
        margin: "10%",
    },
    formField: {
        width: '100%'
    },
    ccField: {
        marginTop: theme.spacing(2),
        border: `1px solid ${theme.palette.secondary[100]}`,
        padding: 10,
        borderRadius: 3
    },
    title: {
        fontSize: 14
    },
    pos: {
        marginBottom: 12
    },
    textButton: {
        width: 15,
        height: 15,
        padding: 0,
        label: {
            display: 'none'
        }
    },
    copyIcon: {
        width: 15,
        height: 15
    },
    btn: {
        textDecoration: 'none'
    },
    actions: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginRight: theme.spacing(1)
    },
    // marginNav: {
    //     marginTop: "64px",
    //     height: '100%',
    // },
    backgroundGrey: {
        background: "#FAFAFA",
        width: "100vw",
        height: '100%',
    },
    borderStyleLeft: {
        // border: "1px solid black",
        // height: "100%",
        width: "50vw",
    },
    borderStyleRight: {
        // border: "1px solid black",
        // height: "100%",
        // backgroundColor: "white",
        // margin: "10% 5% 10% 2%",
        // padding: "0 0 5% 0",
        width: "50vw",
    },
    h3Style: {
        fontFamily: "Lato",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "40px",
        lineHeight: "48px",
        letterSpacing: "0.02em",
        textTransform: "uppercase",
        color: "#000000",
        margin: "5% 5% 20% 5%",
        textAlign: "left",
    },
    h4Style: {
        fontFamily: "Lato",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "27px",
        lineHeight: "56px",
        display: "flex",
        alignItems: "center",
        letterSpacing: "0.02em",
        // textTransform: "uppercase",
        color: "#000000",
        margin: "10% 5% 10% 5%",
    },
    h5Style: {
        fontFamily: "Work Sans",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "16px",
        lineHeight: "24px",
        color: "#000000",
        opacity: "0.7",
        margin: "-10% 5% 3% 5%",
        textAlign: "left",
    },
    buttonStyle1: {
        margin: "0 10% 5%",
        width: "80%",
        backgroundColor: "#DB1F26",
        border: "#DB1F26",
        fontFamily: "Lato",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "16px",
        lineHeight: "40px",
        textAlign: "center",
        letterSpacing: "0.05em",
        textTransform: "uppercase",
        color: "#FFFFFF",
        '&:hover': {
            backgroundColor: "rgba(219, 31, 38, .8)",
            border: "rgba(219, 31, 38, .8)",
        }
    },
    buttonStyle2: {
        margin: "0 5% 5%",
        width: "90%",
        backgroundColor: "transparent",
        border: "1px solid #000000",
        fontFamily: "Lato",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "16px",
        lineHeight: "40px",
        textAlign: "center",
        letterSpacing: "0.05em",
        textTransform: "uppercase",
        color: "#000000",
    },
    textFieldStyle: {
        padding: "0 10% 0",
    },
    paymentBox: {
        // border: "1px solid black",
        backgroundColor: "white",
        width: "50%",
        margin: "10% 40% 10% auto",
        paddingBottom: "10px",
    },
    infoBox: {
        // border: "1px solid black",
        height: "50%",
        width: "50%",
        margin: "10% auto 10% 40%",
    },
    orderSummary: {
        marginLeft: "10%"
    },
    correctionClass : {
        marginTop: "-17px"
    }

}))

function getPriceItem(plan, interval) {
    const id = `${plan}_${
        !isMonthlyOnly(plan) && interval === 'year' ? 'annual' : 'monthly'
    }`
    return prices[id]
}

function findWithInterval(subs, interval) {
    return R.find(R.propEq('interval', interval), subs || [])
}

function CheckoutForm({stripe = null, account, plan, interval}) {
    const {addToast} = useToasts()
    const [selectedInterval, setInterval] = useState(interval)
    const [isCompany, setIsCompany] = useState(false)
    const [error, setError] = useState(null)
    const [errorDismissed, setErrorDismissed] = useState(false)
    const [values, setValues] = useState({
        customer: {address: {}},
        name: null
    })
    const [createLicenseSubscriptions, createSubsResponse] = useMutation(
        CREATE_SUBSCRIPTIONS
    )
    /*
    const [createNetworkSubscription, createNetworkSubResponse] = useMutation(
        CREATE_NETWORK_SUBSCRIPTION
    )
    */
    const classes = useStyles({})
    const priceItem = getPriceItem(plan, selectedInterval)

    const existingWithSameInterval = findWithInterval(
        account.subscriptions,
        selectedInterval
    )

    useEffect(() => {
      const rec = localStorage.getItem("recommendedPlan")
      if (rec !== plan) {
        localStorage.removeItem("recommendedPlan")
        localStorage.setItem("recommendedPlan", plan)
      }
    }, [])

    async function createCardToken() {
        if (card) return null
        try {
            const stripeResponse = await stripe.createToken({name: values.name})
            if (stripeResponse.error) {
                throw stripeResponse.error
            }
            return stripeResponse.token.id
        } catch (e) {
            console.log('stripe error', e)
            setError(e)
            throw e
        }
    }

    const card = account.customer && account.customer.creditCard
    let errorMsg = null

    async function handleSubmit(e) {
        let validated = false;
        e.preventDefault()
        console.log(stripe)
        setError(null)

      const desiredInterval = selectedInterval === 'month' ? INTERVALS.monthly : INTERVALS.annual
        const planName = planToSubscriptionTypeName(plan, desiredInterval)
      
      const customerData = {
            ...R.omit(['vatNumber', 'vatCountry'], values.customer),
            email: account.user.email,
            description: planName
        }
        const taxId = values.customer.vatNumber
            ? {value: values.customer.vatNumber}
            : null

        if (isCompany && !/^[a-zA-Z\s]*$/.test(values.customer.address.city)) {
            alert("City field can not contain numbers and special characters!")
            setError("City field can not contain numbers and special characters!")
            validated = false;
            // return;
        } else if (isCompany && !/^[a-zA-Z\s]*$/.test(values.customer.address.state)) {
            alert("State field can not contain numbers and special characters!")
            setError("State field can not contain numbers and special characters!")
            validated = false;
            // return;
        } else if (isCompany && !/^[a-z0-9][a-z0-9\-]{0,10}[a-z0-9]$/gmi.test(values.customer.address.postalCode)) {
            alert("Your Postal/ZIP code is incorrect, please try again.");
            setError("Your Postal/ZIP code is incorrect, please try again.")
            validated = false;
            // return;
        } else if (isCompany && !("country" in values.customer.address)) {
            alert("Please select a country!");
            setError("Please select a country!")
            validated = false;
            // return;
        } else if (!/^[\s\S]{1,64}$/.test(values.customer.address.line1) || !/^[\s\S]{0,64}$/.test(values.customer.address.line2)) {
            alert("Address fields can only contain up to 64 characters!")
            setError("Address fields can only contain up to 64 characters!")
            validated = false;
        } else if (!/^[A-Za-z0-9'.\-\s,#/\u00c0-\u024f\u1e00-\u1eff]{1,64}$/g.test(values.customer.address.line1)) {
            alert("Address fields can only contain numbers, letters, and ' . or - # / ")
            setError("Address fields can only contain numbers, letters, and ' . or - # /")
            validated = false;
        } else if (!/^[A-Za-z0-9'.\-\s,#/\u00c0-\u024f\u1e00-\u1eff]{0,64}$/g.test(values.customer.address.line2)) {
            alert("Address fields can only contain numbers, letters, and ' . or - # / ")
            setError("Address fields can only contain numbers, letters, and ' . or - # /")
            validated = false;
        } else if (!/^([1-9]|[.]|[-]|[a-zA-Z\u00c0-\u024f\u1e00-\u1eff\s]){0,64}$/.test(values.customer.address.name)) {
            alert("Business name field can only contain up to 64 characters!")
            setError("Business name field can only contain up to 64 characters!")
            validated = false;
        }  else if (!/^[a-zA-Z\u00c0-\u024f\u1e00-\u1eff\s]{1,64}$/.test(values.customer.address.city)) {
            alert("City field can only contain up to 64 characters!")
            setError("City field can only contain up to 64 characters!")
            validated = false;
        } else if (!/^[a-zA-Z\s]{1,64}$/.test(values.customer.address.state)) {
            alert("State field can only contain up to 64 characters!")
            setError("State field can only contain up to 64 characters!")
            validated = false;
        } else if (!/^[\s\S]{0,11}$/.test(values.customer.vatNumber)) {
            alert("VAT Number/Tax ID field can only contain up to 11 characters!")
            setError("VAT Number/Tax ID field can only contain up to 11 characters!")
            validated = false;
        } else if (!/^[a-zA-Z\d\s]{1,11}$/.test(values.customer.vatNumber)) {
            alert("VAT Number/Tax ID is invalid, please try again!")
            setError("VAT Number/Tax ID is invalid, please try again!")
            validated = false;
        } else {
            validated = true;
        }

        const baseVariables = {
            customerId: account.customer ? account.customer.id : null,
            planNames: planName,
            userId: account.user.id,
            interval: selectedInterval
        }

        let customerName = values.name

        if (!/^[a-zA-Z\u00c0-\u024f\u1e00-\u1eff\s]*$/.test(customerName)) {
            alert("Cardholder name can not contain numbers and special characters!")
            setError("Cardholder name can not contain numbers and special characters!")
            validated = false;
        } else if (!/^[a-zA-Z\u00c0-\u024f\u1e00-\u1eff\s]{1,64}$/.test(customerName)) {
            alert("Cardholder name can only contain up to 64 characters!")
            setError("Cardholder name can only contain up to 64 characters!")
            validated = false;
        }

        if (validated) {

            const variables = existingWithSameInterval
                ? baseVariables
                : {
                    ...baseVariables,
                    token: card ? null : await createCardToken(),
                    customerData,
                    taxId
                }
            const mutation = R.partial(createLicenseSubscriptions, [{variables}])

            // @ts-ignore
            mutation()
        }
    }

    //  console.log(createSubsResponse)
    const loading = createSubsResponse.loading
    if (loading) {
        return <Loading message='Processing payment...' open={loading}/>
    }

    if (error) {
        errorMsg = error.message
    }
    const responseError = createSubsResponse.error
    if (responseError) {
        errorMsg = responseError.message.includes('card was declined')
            ? 'Your card was declined. Please check your card details and try again.'
            : responseError.message
    }

    const responseData = createSubsResponse.data
        ? createSubsResponse.data.createLicenseSubscriptions
        : null

    if (responseData) {
        if (
            ['requires_source', 'requires_payment_method'].includes(
                responseData.paymentStatus
            )
        ) {
            console.log('payment failed', responseData)
            addToast({
                message:
                    'Payment failed. Please check your card details and try again.',
                variant: 'warning',
                open: true,
                horizontal: 'right',
                vertical: 'top'
            })
            return <Redirect to='/retry-payment'/>
        }
        if (['requires_source_action'].includes(responseData.paymentStatus)) {
            console.log('requires_source_action', responseData)
            return <Redirect to='/card-auth'/>
        }

        // create subscriptions returned data
        if (!errorMsg) {
            addToast({
                message: `You are now subscribed to ${priceItem.name}`,
                variant: 'success',
                open: true,
                horizontal: 'right',
                vertical: 'top'
            })
            return <Redirect to='/xdn-region'/>
        }
    }

    function handleBusinessInfoChange(field, value) {
        const newValues = {
            ...values,
            customer: {...values.customer, [field]: value}
        }
        console.log('values', newValues)
        setValues(newValues)
    }

    function handleBusinessAddressChange(field, value) {
        const currentAddress = values.customer.address || {}
        const newValues = {
            ...values,
            customer: {
                ...values.customer,
                address: {...currentAddress, [field]: value}
            }
        }
        console.log('values', newValues)
        setValues(newValues)
    }

    return (
        <>
            <OrderSummary
                className="orderSummary"
                prefix='Sign up to'
                serverPlan={plan}
                interval={selectedInterval}
            />
            {!isMonthlyOnly(plan) && (
                <BillingIntervalSelect
                    label='Pay'
                    onIntervalSelected={setInterval}
                    value={selectedInterval}
                />
            )}
            <form onSubmit={handleSubmit}>
                <Card className={classes.purchaseForm}>
                    <CardContent>
                        {!card && (
                            <>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            color='primary'
                                            checked={isCompany}
                                            onChange={e => setIsCompany(!isCompany)}
                                        />
                                    }
                                    label='Purchase for a business'
                                />
                                <Collapse in={isCompany}>
                                    <BusinessInfo
                                        onFieldChange={handleBusinessInfoChange}
                                        onAddressFieldChange={handleBusinessAddressChange}
                                        values={values.customer}
                                        required={isCompany}
                                    />
                                </Collapse>
                            </>
                        )}
                        {existingWithSameInterval && (
                            <Typography>
                                The product will be added to your current{' '}
                                {existingWithSameInterval.description}{' '}
                                {existingWithSameInterval.interval === 'month'
                                    ? 'monthly'
                                    : 'yearly'}{' '}
                                subscription.
                            </Typography>
                        )}
                        {!existingWithSameInterval && (
                            <>
                                <p className={classes.formField}>
                                    Amount due now:{' '}
                                    <b>
                                        $
                                        {selectedInterval === 'year'
                                            ? priceItem.price * 12
                                            : priceItem.price}
                                    </b>
                                </p>
                                {!card && (
                                    <TextField
                                        required={true}
                                        id='cardholder-name'
                                        label='Card holder name'
                                        placeholder='Card holder name'
                                        className={classes.formField}
                                        margin='normal'
                                        value={values.name}
                                        onChange={e =>
                                            setValues({...values, name: e.target.value})
                                        }
                                    />
                                )}
                                {!card && (
                                    <div className={clsx(classes.formField, classes.ccField)}>
                                        <CardElement style={{base: {fontSize: '14px'}}}/>
                                    </div>
                                )}
                                {!!card && (
                                    <div>
                                        {/* <p className={classes.formField}>Pay with card</p> */}
                                        <CreditCard title='Pay with card' card={card} dense/>
                                    </div>
                                )}
                            </>
                        )}
                    </CardContent>
                    <CardActions className={classes.actions}>
                        <Button type='submit' style={{
                            margin: "0 0 5%",
                            width: "100%",
                            backgroundColor: "#DB1F26",
                            border: "#DB1F26",
                            fontFamily: "Lato",
                            fontStyle: "normal",
                            fontWeight: "bold",
                            fontSize: "16px",
                            lineHeight: "40px",
                            textAlign: "center",
                            letterSpacing: "0.05em",
                            textTransform: "uppercase",
                            color: "#FFFFFF",
                        }}
                                variant='contained' color='primary'>
                            Proceed
                        </Button>
                    </CardActions>
                </Card>
                {errorMsg && (
                    <Error
                        message={errorMsg}
                        open={!!errorMsg && !errorDismissed}
                        onClose={() => setErrorDismissed(true)}
                    />
                )}
            </form>
        </>
    )
}

const StripeCheckoutForm = injectStripe(CheckoutForm)

export default function Checkout({match}) {

    const classes = useStyles()
    const { plan, interval } = match.params

    const {user} = useAuth()
    const {loading, error, data} = useQuery(GET_ACCOUNT, {
        variables: {userId: user.id}
    })

    if (loading) return <div>Loading...</div>
    if (error) {
        return <p>Error! {error.message}</p>
    }
    const {serverSub, sdkSub} = sortSubscriptions(
        data.account.subscriptions
    )
    const existingWithSameProduct = plan === 'sdk' ? sdkSub : serverSub

    if (
        existingWithSameProduct &&
        existingWithSameProduct.paymentStatus === 'succeeded'
    ) {
        return <Redirect to='/subscriptions'/>
    }
    // payment method required authorization?
    if (
        existingWithSameProduct &&
        existingWithSameProduct.paymentStatus === 'requires_source_action'
    ) {
        console.log('subscription needs further auth', existingWithSameProduct)
        return <Redirect to='/card-auth'/>
    }
    //REQUIRED FOR PROGRESS BAR
    const progressArrowArray = [false, true, false, false, false, false, false]
    const progressActiveArray = [-1, 1, 0, 0, 0, 0]

    return (
        <div style={{marginTop: "-17px"}}>
            {/*^^stupid card margin bs*/}
            <Grid className={classes.backgroundGrey} container>
                <FlowProgressBar active={progressActiveArray} arrows={progressArrowArray}/>
                <Grid item xs={12} sm={12} md={6} className={classes.borderStyleLeft}>
                    <Grid item xs={8} sm={8} md={6} className={classes.infoBox}>
                        <h3 className={classes.h3Style}><span style={{color: "#DB1F26"}}>Red5</span> XDN</h3>
                        <h5 className={classes.h5Style}>By submitting your credit card you are signing up for a free
                            trial of the Red5 XDN. Your card WILL NOT be charged at this time. At the end of your
                            trial, your card will be charged.
                            <br/><br/>If you would prefer to manually install and self-host, you do not need to submit
                            your credit card information at this time. Sign-up for a trial of Red5 Pro.</h5>
                        <Button className={classes.buttonStyle2} variant="primary" size="lg" block
                                href="https://red5pro.com">
                            Checkout Red5 Pro
                        </Button>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={6} className={classes.borderStyleRight}>
                    <Grid item xs={8} sm={8} md={6} className={classes.paymentBox}>
                        <h4 className={classes.h4Style} style={{marginLeft: "10%"}}>Billing Info</h4>
                        <Elements>
                            <StripeCheckoutForm
                                account={data.account}
                                plan={plan}
                                interval={plan === 'developer' ? 'month' : (interval === 'month' ? 'month' : 'year')}
                            />
                        </Elements>
                        {/*<Button className={classes.buttonStyle1} variant="primary" size="lg" block>*/}
                        {/*    Red5 XDN*/}
                        {/*</Button>*/}
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}
