import React from 'react';
import PubSubContainer from '../PubSubContainer';
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import FlowProgressBar from "../../components/FlowProgressBar";

const backgroundGrey = {
  //    background: "linear-gradient(91.81deg, #E0E0E0 1.29%, #F5F3F3 128.06%), #F3F2F2",
    backgroundImage: "url(/XDNBackground.png)",
    backgroundRepeat: 'repeat',
    backgroundSize: 'cover',
    opacity: "0.8",
  //    height: '100vw',
    height: 'calc(100vh - 128px)',
    fallbacks: [
        {height: '-moz-calc(100vh - 128px)'},
        {height: '-webkit-calc(100vh - 128px)'},
        {height: '-o-calc(100vh - 128px)'}
    ],
    width: "100vw",
}
const borderStyleLeft = {
    // border: "1px solid black",
    // height: "100%",
    // backgroundColor: "white",
  width: "80vw",
  height: "100%",
  position: 'relative'
    // margin: "-15% 0% 0% 0%",
    // padding: "0 0 5% 0",
};
const h3Style = {
    fontFamily: "Lato",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "40px",
    lineHeight: "48px",
    margin: "20px 0px",
    letterSpacing: "0.02em",
    textTransform: "uppercase",
    color: "#000000",
    // margin: "0% 5% 20% 5%",
    textAlign: "center",
};
const h5Style = {
    fontFamily: "Lato",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "24px",
    lineHeight: "56px",
    margin: "10px 0px",
    letterSpacing: "0.02em",
    color: "#000000",
    // margin: "-10% 5% 3% 5%",
    textAlign: "center",
    textTransform: "uppercase",
};

const pStyle = {
    fontFamily: "Lato",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "24px",
    margin: "20px 25% 35px",
    letterSpacing: "0.02em",
    color: "#000000",
    // margin: "-10% 5% 3% 5%",
    textAlign: "center",
  // textTransform: "uppercase",
    width: "50%",
};

//REQUIRED FOR PROGRESS BAR
const progressArrowArray = [false, false, false, true, false, false, false]
const progressActiveArray = [-1, -1, -1, 1, 0, 0]

export default class PubSubTest extends React.Component {

      render() {
        const streamName = JSON.parse(localStorage.getItem('user')).accountID
        return (
            <div>
                <FlowProgressBar active={progressActiveArray} arrows={progressArrowArray}></FlowProgressBar>
            <Grid container style={backgroundGrey}>
                <Grid
                    container
                    alignItems="baseline"
                    justify="center"
                >
                    {/*<Grid xs={12} sm={6} md={4} style={borderStyleLeft}>*/}
                        <div style={borderStyleLeft}>
                          <h3 style={h3Style}><span style={{color: "#DB1F26"}}>Red5</span> XDN</h3>
                          <h5 style={h5Style}>Test Publishing and Subscribing</h5>
                          <p style={pStyle}>You are publishing a video on the left and subscribing to your own video on the right. This is going through your account that you just setup. If you see yourself on both sides please continue to the next page. </p>
                          {/*<!-- TODO: Use current user account to populate these props -->*/}
                          <PubSubContainer
                            streamName={streamName}
                            nextUrl="/xdn-build-app"
                          />
                        </div>
                    {/*</Grid>*/}
                </Grid>
                </Grid>
            </div>
        )
    }
}