import React, {
    useState,
    useEffect
} from 'react';
import {Grid, Button} from "@material-ui/core/";
import {Redirect} from 'react-router-dom'
import LinearProgress from '@material-ui/core/LinearProgress';
import {makeStyles} from "@material-ui/core/styles";
import { useAuth } from '../../auth/useAuth'
import {
  useQuery,
  useApolloClient,
  useLazyQuery
} from '@apollo/react-hooks'
import ErrorModal from '../modals/ErrorModal';
import { Error, Loading } from '../../components/Toast'
import {
  GET_XDN,
  launchXDN,
  red5XDNDeployStatus
} from '../../models/account-api'
import plans from '../../models/plans'
import { useInterval } from '../../models/utils'

import "./xdnStyles.css";
import ding from "./ding-sound-effect_2.mp3";
import FlowProgressBar from "../../components/FlowProgressBar";

const CHECK_DELAY = 10 * 1000 // 10 seconds
/*
const statusEnum = [
  'unlaunched',
  'launching',
  // Terraform status
  'pending', 'planning', 'planned', 'confirmed', 'applying', 'applied', 'errored',
  // Stream Manager status
  'configuring', 'starting', 'launched']
*/
const notWorkingButton = {
  height: "56px",
  backgroundColor: "#DB1F26",
  border: "#DB1F26 1px solid",
  fontFamily: "Lato",
  fontStyle: "normal",
  fontWeight: "bold",
  fontSize: "16px",
  lineHeight: "40px",
  textAlign: "center",
  letterSpacing: "0.05em",
  textTransform: "uppercase",
  color: "white"
}

const statusToProgressValue = {
  errored: 0,
  unlaunched: 0,
  launching: 5,
  plan_queued: 10,
  pending: 25,
  planning: 25,
  planned: 25,
  confirmed: 25,
  applying: 33,
  applied: 50,
  configuring: 66,
  starting: 75,
  launched: 100
}

const useStyles = makeStyles(theme => ({
    formControl: {
        width: "50%",
        height: "50%",
        margin: "5% auto 5% 25%",
    },
    buttonStyle1: {
        // margin: "0 auto 0 calc((650px - 550px) / 2)",
        marginTop: "10px",
        width: "550px",
        backgroundColor: "#DB1F26",
        border: "#DB1F26",
        fontFamily: "Lato",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "16px",
        lineHeight: "40px",
        textAlign: "center",
        letterSpacing: "0.05em",
        textTransform: "uppercase",
        color: "#FFFFFF",
        '&:hover': {
            backgroundColor: "rgba(219, 31, 38, .8)",
            border: "rgba(219, 31, 38, .8)",
        }
    },
    buttonStyle2: {
        // margin: "0 auto 0 calc((650px - 550px) / 2)",
        marginTop: "10px",
        width: "550px",
        backgroundColor: "transparent",
        border: "1px solid #000000",
        fontFamily: "Lato",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "16px",
        lineHeight: "40px",
        textAlign: "center",
        letterSpacing: "0.05em",
        textTransform: "uppercase",
        color: "#FFFFFF",
    },
    buttonStyle3: {
        // margin: "0 auto 0 calc((650px - 550px) / 2)",
        marginTop: "10px",
        width: "550px",
        backgroundColor: "gray",
        border: "1px solid #000000",
        fontFamily: "Lato",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "16px",
        lineHeight: "40px",
        textAlign: "center",
        letterSpacing: "0.05em",
        textTransform: "uppercase",
        color: "#FFFFFF",
    },
    backgroundGrey: {
        background: "#FAFAFA",
        width: "100vw",
        height: '100%',
    },
}))
const borderStyleLeft = {
    // border: "1px solid black",
    // backgroundColor: "white",
    margin: "10% 25% 0% 25%",
    padding: "0 0 5% 0",
};
const h3Style = {
    fontFamily: "Lato",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "40px",
    lineHeight: "48px",
    letterSpacing: "0.02em",
    textTransform: "uppercase",
    color: "#000000",
    margin: "-15% 5% 5% 5%",
    textAlign: "center",
};

const h6Style = {
    fontFamily: "Lato",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "24px",
    lineHeight: "56px",
    /* identical to box height, or 233% */
    letterSpacing: "0.02em",
    textTransform: "capitalize",
    color: "#000000",
    textAlign: "center",
    margin: "1% 10% 1% 10%",
};

const currentProgress = {
  color: "#DB1F26"
}

const nextProgress = {
  color: "#000000"
}

export default function DeployXDN() {

  const dingAudio = new Audio(ding);

  const playSound = (audioFile) => {
    audioFile.play();
  };

  const client = useApolloClient()
  const classes = useStyles()
  const [xdnError, setXDNError] = useState(undefined)
  const [errorDismissed, setErrorDismissed] = useState(false)
  const [redirect, setRedirect] = useState(false)
  const [progress, setProgress] = useState(-1)
  const [stopInterval, setStopInterval] = useState(false)

  const [nextButton, setNextButton] = useState("none");
  const [alertButton, setAlertButton] = useState("unset");
  const [browserAlert, setBrowserAlert] = useState(false);
  const [finished, setFinished] = useState(false)

  useEffect(() => {
    if ((xdnError || finished) && browserAlert) {
      playSound(dingAudio);
      setTimeout(function () {
        alert(xdnError ? xdnError.message : "Your deployment has been completed!");
      }, 1000);
      setBrowserAlert(false)
    }
}, [browserAlert, finished, progress, dingAudio, xdnError])

  const { user } = useAuth()
  const [loadXDNData, { loading, error, data }] = useLazyQuery(GET_XDN)

  useInterval(() => {
    if (!stopInterval && (data && data.red5XDN)) {
      checkActiveLoad(data.red5XDN)
    }
  }, CHECK_DELAY)

  useEffect(() => {
    loadXDNData({
      variables: {
        userId: user.id
      }
    })
  }, [])

  useEffect(() => {

    if (error) {
      displayError(typeof error === 'string' ? error : error.message)
    }

    if (progress >= 0 || !data) return
    async function getOrLaunch () {
      const { red5XDN } = data
      if (['errored', 'unlaunched'].indexOf(red5XDN.status) > -1) {
        try {
          const d = await launchXDN(client, parseInt(red5XDN.id, 10))
          const {
            launchXDN: { status }
          } = d
          updateProgressFromStatus(status)
        } catch (e) {
          displayError(typeof e === 'string' ? e : e.message)
        }
      } else {
        updateProgressFromStatus(red5XDN.status)
      }
    }
    setProgress(0)
    getOrLaunch()

  }, [progress, data, error])

  if (redirect) {
    return <Redirect to='/dashboard'/>
  }
  if (loading) {
    return <Loading msg='Loading...' open={loading} />
  }

  async function checkActiveLoad (xdn) {
    setErrorDismissed(false)
    const { userId } = xdn
    try {
      const payload = await red5XDNDeployStatus(client, userId || user.id)
      const {
        red5XDNDeployStatus: {
          status
        }
      } = payload
      console.log(payload)
      updateProgressFromStatus(status)
      if (['launched'].indexOf(status) > -1) {
        setProgress(100)
        setFinished(true)
        setNextButton("unset")
        setAlertButton("none")
        setFinished(true)
      } else if (['errored'].indexOf(status) > -1) {
        displayError(getErrorMessage(data.red5XDN, payload.red5XDNDeployStatus))
      }
    } catch (e) {
      console.error('[xdn]', e)
      displayError(typeof e === 'string' ? e : e.message)
    }
  }

  function displayError (message) {
    setBrowserAlert(true)
    setXDNError({ message })
    setStopInterval(true)
  }

  function updateProgressFromStatus (status) {
    const p = statusToProgressValue.hasOwnProperty(status) ? statusToProgressValue[status] : 0
    if (progress <= p) {
      console.log(`[xdn] Progress update: ${status}, ${p}`)
      setProgress(p)
    }
  }

  function getErrorMessage (xdn, deployStatus) {
    const { errorMessage } = deployStatus
    let message = 'Error in launch.'
    if (errorMessage) {
      message = `${message} ${errorMessage}`
    }
    if (xdn.lbDomainName || deployStatus.lbDomainName) {
      message = `Could not establish a Stream Manager cluster at ${(xdn.lbDomainName || deployStatus.lbDomainName)}. ${errorMessage||''}`
    } else {
      message = `Could not establish a Stream Manager cluster. No endpoint found. ${errorMessage||''}`
    }
    return message
  }

    //REQUIRED FOR PROGRESS BAR
    const progressArrowArray = [false, false, true, false, false, false, false]
    const progressActiveArray = [-1, -1, 1, 0, 0, 0]

  return (
    <div>
        <FlowProgressBar active={progressActiveArray} arrows={progressArrowArray}></FlowProgressBar>
      <Grid container className={classes.backgroundGrey}>
      <Grid
        container
        alignItems="center"
        justify="center">
        <div style={borderStyleLeft}>
          <h3 style={h3Style}><span style={{color: "#DB1F26"}}>Red5</span> XDN</h3>
            <Grid container className="align-items-center">
              <Grid xs={12}>
                <h6 style={h6Style}>
                  Currently deploying your network...
                </h6>
              </Grid>
              <Grid xs={12}>
                <LinearProgress color="primary" variant="determinate" value={progress}/>
              </Grid>
              <div style={{width: "50vw",}}>
                <Grid style={{width: "100%"}} container className="align-items-center">
                  <Grid style={{width: "50%", textAlign: "center"}} xs={6}>
                    <ul style={{margin: "5% 25%"}}>
                      <li className="progressH4" style={
                          progress >= 25 
                          ? currentProgress
                          : nextProgress
                      }>SM installed</li>
                      <li className="progressH4" style={
                        progress < 33 && progress >= 25
                        ? nextProgress : progress >= 33 ? currentProgress : {color: 'gray'}
                      }>DB created</li>
                      <li className="progressH4" style={
                        progress < 33 && progress >= 25
                        ? nextProgress : progress >= 33 ? currentProgress : {color: 'gray'}
                      }>LB created</li>
                    </ul>
                  </Grid>
                  <Grid style={{width: "50%", textAlign: "center"}} xs={6}>
                    <ul style={{margin: "5% 25%"}}>
                      <li className="progressH4" style={
                        progress < 66 && progress >= 33
                        ? nextProgress : progress >= 66 ? currentProgress : {color: 'gray'}
                      }>Node image created
                      </li>
                      <li className="progressH4" style={
                        progress < 75 && progress >= 66
                        ? nextProgress : progress >= 66 ? currentProgress : {color: 'gray'}
                      }>Node-group created
                      </li>
                      <li className="progressH4" style={
                        progress >= 100 ? currentProgress : {color: 'gray'}
                      }>Nodes Up and running
                      </li>
                    </ul>
                  </Grid>
                </Grid>
                <Grid xs={12} style={{textAlign: "center"}}>
                  {!finished && (
                    <p className="deploy-text">This can take about 15 minutes to setup. Click below if
                    you would like to be alerted in browser when we are done. An email will be sent
                    to the one you signed up with regardless.</p>
                  )}
                  <Button style={{display: nextButton}} className={classes.buttonStyle1}
                    variant="primary" size="lg" block>
                    <a style={{color: "white", textDecoration: "none",}} href="/xdn-pub-sub">Next Step</a>
                  </Button>
                    {!browserAlert &&
                        <Button style={{display: alertButton, color: "black", textDecoration: "none",}}
                                className={classes.buttonStyle2} variant="primary" size="lg" block
                                onClick={() => setBrowserAlert(true)}>
                            Alert when done
                        </Button>
                    }
                    {browserAlert &&
                    <Button style={{display: alertButton, color: "white", textDecoration: "none",}}
                            className={classes.buttonStyle3} variant="primary" size="lg" block
                            onClick={() => setBrowserAlert(false)}>
                        Don't alert me when done
                    </Button>
                    }
                </Grid>
                {!finished && (
                <Grid xs={12} style={{textAlign: "center", paddingTop: "20px"}}>
                  <p className="deploy-text">Experiencing issues with deployment?</p>
                 <Button style={notWorkingButton} variant="primary" size="lg" block>
                    <a style={{color: "white", textDecoration: "none",}} href="https://red5pro.zendesk.com/hc/en-us/requests/new" target="_blank">Contact Support</a>
                  </Button>
                </Grid>
                )}
            </div>
          </Grid>
        </div>
      </Grid>
    </Grid>
    {(xdnError || error) && (
       <ErrorModal 
          title="Launch Error"
          msg={(xdnError||error).message} 
          open={!!(xdnError||error)&& !errorDismissed}
          onClose={() => setErrorDismissed(true)}/>
    )}
    </div>
  )
}
