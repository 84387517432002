import React from 'react'
import Select from 'react-select'

const countries = [
  ['AF', 'Afghanistan'],
  ['AX', 'Åland Islands'],
  ['AL', 'Albania'],
  ['DZ', 'Algeria'],
  ['AS', 'American Samoa'],
  ['AD', 'Andorra'],
  ['AO', 'Angola'],
  ['AI', 'Anguilla'],
  ['AQ', 'Antarctica'],
  ['AG', 'Antigua and Barbuda'],
  ['AR', 'Argentina'],
  ['AM', 'Armenia'],
  ['AW', 'Aruba'],
  ['AU', 'Australia'],
  ['AT', 'Austria'],
  ['AZ', 'Azerbaijan'],
  ['BS', 'Bahamas'],
  ['BH', 'Bahrain'],
  ['BD', 'Bangladesh'],
  ['BB', 'Barbados'],
  ['BY', 'Belarus'],
  ['BE', 'Belgium'],
  ['BZ', 'Belize'],
  ['BJ', 'Benin'],
  ['BM', 'Bermuda'],
  ['BT', 'Bhutan'],
  ['BO', 'Bolivia'],
  ['BA', 'Bosnia and Herzegovina'],
  ['BW', 'Botswana'],
  ['BV', 'Bouvet Island'],
  ['BR', 'Brazil'],
  ['IO', 'British Indian Ocean Territory'],
  ['BN', 'Brunei Darussalam'],
  ['BG', 'Bulgaria'],
  ['BF', 'Burkina Faso'],
  ['BI', 'Burundi'],
  ['KH', 'Cambodia'],
  ['CM', 'Cameroon'],
  ['CA', 'Canada'],
  ['CV', 'Cape Verde'],
  ['KY', 'Cayman Islands'],
  ['CF', 'Central African Republic'],
  ['TD', 'Chad'],
  ['CL', 'Chile'],
  ['CN', 'China'],
  ['CX', 'Christmas Island'],
  ['CC', 'Cocos (Keeling) Islands'],
  ['CO', 'Colombia'],
  ['KM', 'Comoros'],
  ['CG', 'Congo'],
  ['CD', 'Congo, The Democratic Republic of The'],
  ['CK', 'Cook Islands'],
  ['CR', 'Costa Rica'],
  ['CI', "Cote D'ivoire"],
  ['HR', 'Croatia'],
  ['CU', 'Cuba'],
  ['CY', 'Cyprus'],
  ['CZ', 'Czechia'],
  ['DK', 'Denmark'],
  ['DJ', 'Djibouti'],
  ['DM', 'Dominica'],
  ['DO', 'Dominican Republic'],
  ['EC', 'Ecuador'],
  ['EG', 'Egypt'],
  ['SV', 'El Salvador'],
  ['GQ', 'Equatorial Guinea'],
  ['ER', 'Eritrea'],
  ['EE', 'Estonia'],
  ['ET', 'Ethiopia'],
  ['FK', 'Falkland Islands (Malvinas)'],
  ['FO', 'Faroe Islands'],
  ['FJ', 'Fiji'],
  ['FI', 'Finland'],
  ['FR', 'France'],
  ['GF', 'French Guiana'],
  ['PF', 'French Polynesia'],
  ['TF', 'French Southern Territories'],
  ['GA', 'Gabon'],
  ['GM', 'Gambia'],
  ['GE', 'Georgia'],
  ['DE', 'Germany'],
  ['GH', 'Ghana'],
  ['GI', 'Gibraltar'],
  ['GR', 'Greece'],
  ['GL', 'Greenland'],
  ['GD', 'Grenada'],
  ['GP', 'Guadeloupe'],
  ['GU', 'Guam'],
  ['GT', 'Guatemala'],
  ['GG', 'Guernsey'],
  ['GN', 'Guinea'],
  ['GW', 'Guinea-bissau'],
  ['GY', 'Guyana'],
  ['HT', 'Haiti'],
  ['HM', 'Heard Island and Mcdonald Islands'],
  ['VA', 'Holy See (Vatican City State)'],
  ['HN', 'Honduras'],
  ['HK', 'Hong Kong'],
  ['HU', 'Hungary'],
  ['IS', 'Iceland'],
  ['IN', 'India'],
  ['ID', 'Indonesia'],
  ['IR', 'Iran, Islamic Republic of'],
  ['IQ', 'Iraq'],
  ['IE', 'Ireland'],
  ['IM', 'Isle of Man'],
  ['IL', 'Israel'],
  ['IT', 'Italy'],
  ['JM', 'Jamaica'],
  ['JP', 'Japan'],
  ['JE', 'Jersey'],
  ['JO', 'Jordan'],
  ['KZ', 'Kazakhstan'],
  ['KE', 'Kenya'],
  ['KI', 'Kiribati'],
  ['KP', "Korea, Democratic People's Republic of"],
  ['KR', 'Korea, Republic of'],
  ['KW', 'Kuwait'],
  ['KG', 'Kyrgyzstan'],
  ['LA', "Lao People's Democratic Republic"],
  ['LV', 'Latvia'],
  ['LB', 'Lebanon'],
  ['LS', 'Lesotho'],
  ['LR', 'Liberia'],
  ['LY', 'Libyan Arab Jamahiriya'],
  ['LI', 'Liechtenstein'],
  ['LT', 'Lithuania'],
  ['LU', 'Luxembourg'],
  ['MO', 'Macao'],
  ['MK', 'Macedonia, The Former Yugoslav Republic of'],
  ['MG', 'Madagascar'],
  ['MW', 'Malawi'],
  ['MY', 'Malaysia'],
  ['MV', 'Maldives'],
  ['ML', 'Mali'],
  ['MT', 'Malta'],
  ['MH', 'Marshall Islands'],
  ['MQ', 'Martinique'],
  ['MR', 'Mauritania'],
  ['MU', 'Mauritius'],
  ['YT', 'Mayotte'],
  ['MX', 'Mexico'],
  ['FM', 'Micronesia, Federated States of'],
  ['MD', 'Moldova, Republic of'],
  ['MC', 'Monaco'],
  ['MN', 'Mongolia'],
  ['ME', 'Montenegro'],
  ['MS', 'Montserrat'],
  ['MA', 'Morocco'],
  ['MZ', 'Mozambique'],
  ['MM', 'Myanmar'],
  ['NA', 'Namibia'],
  ['NR', 'Nauru'],
  ['NP', 'Nepal'],
  ['NL', 'Netherlands'],
  ['AN', 'Netherlands Antilles'],
  ['NC', 'New Caledonia'],
  ['NZ', 'New Zealand'],
  ['NI', 'Nicaragua'],
  ['NE', 'Niger'],
  ['NG', 'Nigeria'],
  ['NU', 'Niue'],
  ['NF', 'Norfolk Island'],
  ['MP', 'Northern Mariana Islands'],
  ['NO', 'Norway'],
  ['OM', 'Oman'],
  ['PK', 'Pakistan'],
  ['PW', 'Palau'],
  ['PS', 'Palestinian Territory, Occupied'],
  ['PA', 'Panama'],
  ['PG', 'Papua New Guinea'],
  ['PY', 'Paraguay'],
  ['PE', 'Peru'],
  ['PH', 'Philippines'],
  ['PN', 'Pitcairn'],
  ['PL', 'Poland'],
  ['PT', 'Portugal'],
  ['PR', 'Puerto Rico'],
  ['QA', 'Qatar'],
  ['RE', 'Reunion'],
  ['RO', 'Romania'],
  ['RU', 'Russian Federation'],
  ['RW', 'Rwanda'],
  ['SH', 'Saint Helena'],
  ['KN', 'Saint Kitts and Nevis'],
  ['LC', 'Saint Lucia'],
  ['PM', 'Saint Pierre and Miquelon'],
  ['VC', 'Saint Vincent and The Grenadines'],
  ['WS', 'Samoa'],
  ['SM', 'San Marino'],
  ['ST', 'Sao Tome and Principe'],
  ['SA', 'Saudi Arabia'],
  ['SN', 'Senegal'],
  ['RS', 'Serbia'],
  ['SC', 'Seychelles'],
  ['SL', 'Sierra Leone'],
  ['SG', 'Singapore'],
  ['SK', 'Slovakia'],
  ['SI', 'Slovenia'],
  ['SB', 'Solomon Islands'],
  ['SO', 'Somalia'],
  ['ZA', 'South Africa'],
  ['GS', 'South Georgia and The South Sandwich Islands'],
  ['ES', 'Spain'],
  ['LK', 'Sri Lanka'],
  ['SD', 'Sudan'],
  ['SR', 'Suriname'],
  ['SJ', 'Svalbard and Jan Mayen'],
  ['SZ', 'Swaziland'],
  ['SE', 'Sweden'],
  ['CH', 'Switzerland'],
  ['SY', 'Syrian Arab Republic'],
  ['TW', 'Taiwan, Province of China'],
  ['TJ', 'Tajikistan'],
  ['TZ', 'Tanzania, United Republic of'],
  ['TH', 'Thailand'],
  ['TL', 'Timor-leste'],
  ['TG', 'Togo'],
  ['TK', 'Tokelau'],
  ['TO', 'Tonga'],
  ['TT', 'Trinidad and Tobago'],
  ['TN', 'Tunisia'],
  ['TR', 'Turkey'],
  ['TM', 'Turkmenistan'],
  ['TC', 'Turks and Caicos Islands'],
  ['TV', 'Tuvalu'],
  ['UG', 'Uganda'],
  ['UA', 'Ukraine'],
  ['AE', 'United Arab Emirates'],
  ['GB', 'United Kingdom'],
  ['US', 'United States'],
  ['UM', 'United States Minor Outlying Islands'],
  ['UY', 'Uruguay'],
  ['UZ', 'Uzbekistan'],
  ['VU', 'Vanuatu'],
  ['VE', 'Venezuela'],
  ['VN', 'Viet Nam'],
  ['VG', 'Virgin Islands, British'],
  ['VI', 'Virgin Islands, U.S.'],
  ['WF', 'Wallis and Futuna'],
  ['EH', 'Western Sahara'],
  ['YE', 'Yemen'],
  ['ZM', 'Zambia'],
  ['ZW', 'Zimbabwe']
]

const vatCountries = [
  { id: '--', name: '--', format: '000000000' },
  { id: 'AL', name: 'Albania', format: '' },
  { id: 'AD', name: 'Andorra', format: '' },
  { id: 'AT', name: 'Austria', format: 'ATU000000000' },
  { id: 'BY', name: 'Belarus', format: '000000000' },
  { id: 'BE', name: 'Belgium', format: 'BE00000000000' },
  { id: 'BA', name: 'Bosnia and Herzegovina', format: '' },
  { id: 'BG', name: 'Bulgaria', format: 'BG0000000000' },
  { id: 'HR', name: 'Croatia (Hrvatska)', format: 'HR00000000000' },
  { id: 'CY', name: 'Cyprus', format: 'CY000000000' },
  { id: 'CZ', name: 'Czech Republic', format: 'CZ00000000' },
  { id: 'DK', name: 'Denmark', format: 'DK00000000' },
  { id: 'EE', name: 'Estonia', format: 'EE000000000' },
  { id: 'FO', name: 'Faroe Islands', format: '' },
  { id: 'FI', name: 'Finland', format: 'FI00000000' },
  { id: 'FR', name: 'France', format: 'FR000000000' },
  { id: 'DE', name: 'Germany', format: 'DE000000000' },
  { id: 'GI', name: 'Gibraltar', format: '' },
  { id: 'GR', name: 'Greece', format: 'EL000000000' },
  { id: 'GL', name: 'Greenland', format: '' },
  { id: 'VA', name: 'Holy See (Vatican City State)', format: '' },
  { id: 'HU', name: 'Hungary', format: 'HU00000000' },
  { id: 'IS', name: 'Iceland', format: 'IS000000' },
  { id: 'IE', name: 'Ireland', format: 'IE0000000' },
  { id: 'IT', name: 'Italy', format: 'IT00000000000' },
  { id: 'LV', name: 'Latvia', format: 'LV00000000000' },
  { id: 'LI', name: 'Liechtenstein', format: '' },
  { id: 'LT', name: 'Lithuania', format: 'LT000000000' },
  { id: 'LU', name: 'Luxembourg', format: 'LU00000000' },
  { id: 'MK', name: 'Macedonia', format: '' },
  { id: 'MT', name: 'Malta', format: 'MT00000000' },
  { id: 'MD', name: 'Moldova', format: '' },
  { id: 'MC', name: 'Monaco', format: 'FR000000000' },
  { id: 'ME', name: 'Montenegro', format: '' },
  { id: 'NL', name: 'Netherlands', format: 'NL000000000' },
  { id: 'NO', name: 'Norway', format: 'NO000000000' },
  { id: 'PL', name: 'Poland', format: 'PL0000000000' },
  { id: 'PT', name: 'Portugal', format: 'PT000000000' },
  { id: 'RO', name: 'Romania', format: 'RO00000000' },
  { id: 'SM', name: 'San Marino', format: 'SM00000' },
  { id: 'RS', name: 'Serbia', format: 'RS000000000' },
  { id: 'SK', name: 'Slovakia', format: 'SK00000000' },
  { id: 'SI', name: 'Slovenia', format: 'SI00000000' },
  { id: 'ES', name: 'Spain', format: 'ES00000000' },
  { id: 'SJ', name: 'Svalbard and Jan Mayen Islands', format: '' },
  { id: 'SE', name: 'Sweden', format: 'SE000000000000' },
  { id: 'CH', name: 'Switzerland', format: 'CH000000' },
  { id: 'TR', name: 'Turkey', format: 'TR0000000000' },
  { id: 'UA', name: 'Ukraine', format: 'UA0000000000' },
  { id: 'GB', name: 'United Kingdom', format: '000000000' },
]

function CountrySelect({ onChange, value, required }) {
  function getSelectValue() {
    if (!value) return null
    const country = countries.find(c => c[0] === value)
    return { value: country[0], label: country[1] }
  }
  return (
    <Select
      value={getSelectValue()}
      onChange={onChange}
      options={countries.map(c => ({ value: c[0], label: c[1] }))}
      required={required}
    ></Select>
  )
}
function EUCountrySelect({ onChange, value }) {
  function getSelectValue() {
    if (!value) return null
    const country = vatCountries.find(c => c.id === value)
    return { value: country.id, label: country.name }
  }

  return (
    <Select
      value={getSelectValue()}
      onChange={option =>
        onChange(vatCountries.find(c => c.id === option.value))
      }
      options={vatCountries.map(c => ({ value: c.id, label: c.name }))}
    ></Select>
  )
}

export { CountrySelect, EUCountrySelect }
