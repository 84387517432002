import React, { useState, useEffect, useRef } from 'react';
const R = require('ramda')

export const isEmpty = value => !value || value === ''

export const fieldHasEmptyValue = (source, field) =>
  field.required && isEmpty(getValue(field.attr, source))

export const getValue = (attr, source) =>
  attr.split('.').reduce((acc, attr) => acc && acc[attr], source)

export const hasMissingField = (fields, source) =>
  fields.find(R.partial(fieldHasEmptyValue, [source]))

export function setAttr(target, attr, value) {
  const attrs = attr.split('.')
  return attrs.length > 0
    ? R.assocPath(attrs, value, target)
    : R.assoc(attr, value, target)
}

export function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export async function wait (timeout = 5000) {
  return new Promise((resolve) => {
    setTimeout(() => resolve(), timeout)
  })
}
