import Box from '@material-ui/core/Box'
import DeleteIcon from '@material-ui/icons/Delete'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { prices } from '../models/plans'

const useStyles = makeStyles(theme => ({
  title: {},
  card: {marginLeft: "10%"},
  removeButton: {},
  proceedButton: {}
}))
export default function OrderSummary({
  prefix = 'Migration to',
  serverPlan,
  interval,
  onRemoved = null
}) {
  const classes = useStyles({})
  const id = `${serverPlan}_${
    serverPlan !== 'developer' && interval === 'year' ? 'annual' : 'monthly'
  }`
  const price = prices[id]

  return (
    <Box className={classes.card}>
      {serverPlan && (
        <Grid container spacing={1} alignItems='center'>
          <Grid item>
            {prefix} <strong>{price.name}</strong>
          </Grid>
          <Grid item>
            <Typography variant='body2'>{price.priceDesc}</Typography>
          </Grid>
          {onRemoved && (
            <Grid item>
              <IconButton
                className={classes.removeButton}
                aria-label='delete'
                onClick={onRemoved}
              >
                <DeleteIcon />
              </IconButton>
            </Grid>
          )}
        </Grid>
      )}
    </Box>
  )
}
