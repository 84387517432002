export const authReducer = (state, action) => {
  switch (action.type) {
    case 'login':
      const { authResult } = action
        //original value for expires in was 1000, changed to 10000
      const expiresAt = authResult.expiresIn * 10000 + new Date().getTime()

      if (typeof localStorage !== 'undefined') {
        localStorage.setItem('expires_at', JSON.stringify(expiresAt))
        localStorage.setItem('user', JSON.stringify(authResult.user))
        localStorage.setItem('authorization', authResult.token)
      }

      return {
        ...state,
        user: authResult.user,
        expiresAt,
        authResult
      }
    case 'logout':
      if (typeof localStorage !== 'undefined') {
        localStorage.removeItem('expires_at')
        localStorage.removeItem('user')
        localStorage.removeItem('authorization')
      }

      return {
        ...state,
        user: {},
        expiresAt: null,
        authResult: null
      }
    case 'toggleAuthenticating':
      return {
        ...state,
        isAuthenticating: !state.isAuthenticating
      }
    case 'setIsAuthenticating':
      localStorage.removeItem('expires_at')
      localStorage.removeItem('user')
      localStorage.removeItem('authorization')
      return {
        ...state,
        user: {},
        expiresAt: null,
        authResult: null,
        isAuthenticating: true,
        errorType: null
      }
    case 'clearIsAuthenticating':
      return {
        ...state,
        isAuthenticating: false
      }
    case 'error':
      const { errorType, error } = action
      return {
        ...state,
        user: {},
        expiresAt: null,
        authResult: null,
        errorType,
        error,
        isAuthenticating: false
      }
    default:
      return state
  }
}
