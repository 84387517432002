import { fieldHasEmptyValue, getValue, hasMissingField } from '../models/utils'

import React from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import TextField from '@material-ui/core/TextField'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'

const R = require('ramda')

const useStyles = makeStyles(theme => ({
  table: {
    // minWidth: 650
  },
  indent: {
    paddingLeft: 30
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '100%'
  },
  select: {
    border: 'none',
    paddingLeft: theme.spacing(3),
    marginTop: theme.spacing(1),
    paddingBottom: theme.spacing(2)
    // width: '100%'
  },
  cell: {
    border: 'none',
    paddingTop: 0,
    paddingBottom: 0
  },
  headerCell: {
    fontWeight: 'bold'
  },
  mt0: {
    marginTop: 0
  },
  subtitle: {
    paddingTop: theme.spacing(2),
    fontWeight: 'bold'
  },
  subTitle: { paddingTop: theme.spacing(2) }
}))

export default function DataEdit({
  data,
  fields,
  onChange,
  onValidationError,
  alignHeaders = 'left'
}) {
  const classes = useStyles()

  function setValue(attr, subattr, value) {
    const node = data[attr] || {}
    // console.log('node', node)
    const newValues = subattr
      ? { ...data, [attr]: { ...node, [subattr]: value } }
      : { ...data, [attr]: value }
    /*
    console.log(
      `setting value for attribute '${attr}.${subattr || ''}'`,
      newValues
    )
    */
    onChange(newValues)
    onValidationError(hasMissingField(fields, newValues))
  }

  function setAttr(attr, value) {
    // console.log(`setting ${attr} value`, value)
    const attrs = attr.split('.')
    setValue(attrs[0], attrs.length > 1 ? attrs[1] : null, value)
  }

  function renderTextFieldEdit(field) {
    const hasRequiredError = fieldHasEmptyValue(data, field)
    return (
      <TableCell className={classes.cell}>
        <TextField
          error={hasRequiredError || field.error}
          helperText={hasRequiredError ? `${field.label} is required` : ''}
          value={getValue(field.attr, data)}
          onChange={e => setAttr(field.attr, e.target.value)}
          className={classes.textField}
          InputLabelProps={{
            shrink: true
          }}
          margin='normal'
          type={field.type || 'text'}
          readOnly={Boolean(field.readOnly)}
        />
      </TableCell>
    )
  }

  function renderField(field) {
    return (
      <>
        {field.renderEditor ? (
          field.renderEditor({
            value: getValue(field.attr),
            setValue: R.partial(setAttr, [field.attr]),
            setAttr,
            data
          })
        ) : (
          <>
            <TableRow key={field.label}>
              <TableCell
                // className={clsx(classes.cell, field.classes)}
                className={clsx(
                  classes.cell,
                  classes.headerCell,
                  field.classes,
                  field.indent ? classes.indent : '',
                  field.subtitle ? classes.subtitle : ''
                )}
                scope='row'
                align={alignHeaders}
              >
                {field.subtitle || field.label}
              </TableCell>
              {!field.subtitle && renderTextFieldEdit(field)}
            </TableRow>
          </>
        )}
      </>
    )
  }

// console.log('Rendering DataEdit', data)
  return (
    <>
      <Table className={classes.table} aria-label='data table' size='small'>
        <TableBody>{fields.map(f => renderField(f))}</TableBody>
      </Table>
    </>
  )
}
