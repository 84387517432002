import React from "react";
import Grid from "@material-ui/core/Grid";
import {Box, Button, Checkbox, FormControlLabel} from "@material-ui/core";
import map from "../containers/hosted/maps/MAP.png";
import * as PropTypes from "prop-types";

export default class RegionMapSelect extends React.Component {
    render() {
        return <>
            <Grid container
                  alignItems="center"
                  justify="center"
            >
                <Box alignItems="center"
                     justify="center" style={{textAlign: "center",}}>
                    <div style={{maxWidth: "1120px", position: "relative",}}>
                        <img src={map} alt="" style={{height: "592px", width: "1120px"}}/>
                        <div className="lonMapDot" style={{
                            backgroundColor: (this.props.region.includes("london")) ? "transparent" : "#DB1F26",
                            height: (this.props.region.includes("london")) ? "0px" : "7px",
                            width: (this.props.region.includes("london")) ? "0px" : "7px",
                            borderLeft: (this.props.region.includes("london")) ? "25px solid transparent" : "",
                            borderRight: (this.props.region.includes("london")) ? "25px solid transparent" : "",
                            borderTop: (this.props.region.includes("london")) ? "36px solid #DB1F26" : "",
                            borderRadius: (this.props.region.includes("london")) ? "50%" : "",
                            left: (this.props.region.includes("london")) ? "44.7%" : "46.5%",
                            top: (this.props.region.includes("london")) ? "31%" : "36%",
                        }}><span
                            style={{
                                position: "absolute",
                                color: "white",
                                display: (this.props.region.includes("london")) ? "unset" : "none",
                                transform: (this.props.region.includes("london")) ? "translate(-40% ,-150%)" : ""
                            }}>&#10003;</span>
                        </div>
                        <div className="nycMapDot" style={{
                            backgroundColor: (this.props.region.includes("nyc")) ? "transparent" : "#DB1F26",
                            height: (this.props.region.includes("nyc")) ? "0px" : "7px",
                            width: (this.props.region.includes("nyc")) ? "0px" : "7px",
                            borderLeft: (this.props.region.includes("nyc")) ? "25px solid transparent" : "",
                            borderRight: (this.props.region.includes("nyc")) ? "25px solid transparent" : "",
                            borderTop: (this.props.region.includes("nyc")) ? "36px solid #DB1F26" : "",
                            borderRadius: (this.props.region.includes("nyc")) ? "50%" : "",
                            left: (this.props.region.includes("nyc")) ? "24.2%" : "26%",
                            top: (this.props.region.includes("nyc")) ? "37%" : "42%",
                        }}><span
                            style={{
                                position: "absolute",
                                color: "white",
                                display: (this.props.region.includes("nyc")) ? "unset" : "none",
                                transform: (this.props.region.includes("nyc")) ? "translate(-40% ,-150%)" : ""
                            }}>&#10003;</span>
                        </div>
                        <div className="sgpMapDot" style={{
                            backgroundColor: (this.props.region.includes("sgp")) ? "transparent" : "#DB1F26",
                            height: (this.props.region.includes("sgp")) ? "0px" : "7px",
                            width: (this.props.region.includes("sgp")) ? "0px" : "7px",
                            borderLeft: (this.props.region.includes("sgp")) ? "25px solid transparent" : "",
                            borderRight: (this.props.region.includes("sgp")) ? "25px solid transparent" : "",
                            borderTop: (this.props.region.includes("sgp")) ? "36px solid #DB1F26" : "",
                            borderRadius: (this.props.region.includes("sgp")) ? "50%" : "",
                            left: (this.props.region.includes("sgp")) ? "72.7%" : "74.5%",
                            top: (this.props.region.includes("sgp")) ? "55%" : "60%",
                        }}><span
                            style={{
                                position: "absolute",
                                color: "white",
                                display: (this.props.region.includes("sgp")) ? "unset" : "none",
                                transform: (this.props.region.includes("sgp")) ? "translate(-40% ,-150%)" : ""
                            }}>&#10003;</span>
                        </div>
                        <div className="sfMapDot" style={{
                            backgroundColor: (this.props.region.includes("sf")) ? "transparent" : "#DB1F26",
                            height: (this.props.region.includes("sf")) ? "0px" : "7px",
                            width: (this.props.region.includes("sf")) ? "0px" : "7px",
                            borderLeft: (this.props.region.includes("sf")) ? "25px solid transparent" : "",
                            borderRight: (this.props.region.includes("sf")) ? "25px solid transparent" : "",
                            borderTop: (this.props.region.includes("sf")) ? "36px solid #DB1F26" : "",
                            borderRadius: (this.props.region.includes("sf")) ? "50%" : "",
                            left: (this.props.region.includes("sf")) ? "11.7%" : "13.5%",
                            top: (this.props.region.includes("sf")) ? "39.5%" : "44.5%",
                        }}><span style={{
                            position: "absolute",
                            color: "white",
                            display: (this.props.region.includes("sf")) ? "unset" : "none",
                            transform: (this.props.region.includes("sf")) ? "translate(-40% ,-150%)" : ""
                        }}>&#10003;</span></div>
                        <div className="amsMapDot" style={{
                            backgroundColor: (this.props.region.includes("ams")) ? "transparent" : "#DB1F26",
                            height: (this.props.region.includes("ams")) ? "0px" : "7px",
                            width: (this.props.region.includes("ams")) ? "0px" : "7px",
                            borderLeft: (this.props.region.includes("ams")) ? "25px solid transparent" : "",
                            borderRight: (this.props.region.includes("ams")) ? "25px solid transparent" : "",
                            borderTop: (this.props.region.includes("ams")) ? "36px solid #DB1F26" : "",
                            borderRadius: (this.props.region.includes("ams")) ? "50%" : "",
                            left: (this.props.region.includes("ams")) ? "46.2%" : "48%",
                            top: (this.props.region.includes("ams")) ? "31%" : "36%",
                        }}><span
                            style={{
                                position: "absolute",
                                color: "white",
                                display: (this.props.region.includes("ams")) ? "unset" : "none",
                                transform: (this.props.region.includes("ams")) ? "translate(-40% ,-150%)" : ""
                            }}>&#10003;</span>
                        </div>
                        <div className="fraMapDot" style={{
                            backgroundColor: (this.props.region.includes("fra")) ? "transparent" : "#DB1F26",
                            height: (this.props.region.includes("fra")) ? "0px" : "7px",
                            width: (this.props.region.includes("fra")) ? "0px" : "7px",
                            borderLeft: (this.props.region.includes("fra")) ? "25px solid transparent" : "",
                            borderRight: (this.props.region.includes("fra")) ? "25px solid transparent" : "",
                            borderTop: (this.props.region.includes("fra")) ? "36px solid #DB1F26" : "",
                            borderRadius: (this.props.region.includes("fra")) ? "50%" : "",
                            left: (this.props.region.includes("fra")) ? "47.7%" : "49.5%",
                            top: (this.props.region.includes("fra")) ? "31%" : "36%",
                        }}><span style={{
                            position: "absolute",
                            color: "white",
                            display: (this.props.region.includes("fra")) ? "unset" : "none",
                            transform: (this.props.region.includes("fra")) ? "translate(-40% ,-150%)" : ""
                        }}>&#10003;</span></div>
                        <div className="torMapDot" style={{
                            backgroundColor: (this.props.region.includes("tor")) ? "transparent" : "#DB1F26",
                            height: (this.props.region.includes("tor")) ? "0px" : "7px",
                            width: (this.props.region.includes("tor")) ? "0px" : "7px",
                            borderLeft: (this.props.region.includes("tor")) ? "25px solid transparent" : "",
                            borderRight: (this.props.region.includes("tor")) ? "25px solid transparent" : "",
                            borderTop: (this.props.region.includes("tor")) ? "36px solid #DB1F26" : "",
                            borderRadius: (this.props.region.includes("tor")) ? "50%" : "",
                            left: (this.props.region.includes("tor")) ? "23.2%" : "25%",
                            top: (this.props.region.includes("tor")) ? "34%" : "39%",
                        }}><span
                            style={{
                                position: "absolute",
                                color: "white",
                                display: (this.props.region.includes("tor")) ? "unset" : "none",
                                transform: (this.props.region.includes("tor")) ? "translate(-40% ,-150%)" : ""
                            }}>&#10003;</span>
                        </div>
                        <div className="blrMapDot" style={{
                            backgroundColor: (this.props.region.includes("blr")) ? "transparent" : "#DB1F26",
                            height: (this.props.region.includes("blr")) ? "0px" : "7px",
                            width: (this.props.region.includes("blr")) ? "0px" : "7px",
                            borderLeft: (this.props.region.includes("blr")) ? "25px solid transparent" : "",
                            borderRight: (this.props.region.includes("blr")) ? "25px solid transparent" : "",
                            borderTop: (this.props.region.includes("blr")) ? "36px solid #DB1F26" : "",
                            borderRadius: (this.props.region.includes("blr")) ? "50%" : "",
                            left: (this.props.region.includes("blr")) ? "66.2%" : "68%",
                            top: (this.props.region.includes("blr")) ? "50%" : "55%",
                        }}><span
                            style={{
                                position: "absolute",
                                color: "white",
                                display: (this.props.region.includes("blr")) ? "unset" : "none",
                                transform: (this.props.region.includes("blr")) ? "translate(-40% ,-150%)" : ""
                            }}>&#10003;</span>
                        </div>
                    </div>
                </Box>
            </Grid>
            { this.props.showSelect &&
                <Box display="flex" flexDirection="row" justifyContent="center" style={{width: "1120px"}}>
                <Box>
                    <div className="regionSelect">
                        <FormControlLabel
                            style={{margin: "3% auto auto 2%"}}
                            value="london"
                            label="London"
                            id="London"
                            control={<Checkbox style={{color: "#DB1F26"}}/>}
                            checked={this.props.region.includes("london")}
                            onChange={this.props.onChange}
                        />
                    </div>
                    <div className="regionSelect">
                        <FormControlLabel
                            style={{margin: "3% auto auto 2%"}}
                            value="nyc"
                            label="New York City"
                            id="New York City"
                            control={<Checkbox style={{color: "#DB1F26"}}/>}
                            checked={(this.props.region.includes("nyc"))}
                            onChange={this.props.onChange}
                        />
                    </div>
                    <div className="regionSelect">
                        <FormControlLabel
                            style={{margin: "3% auto auto 2%"}}
                            value="sgp"
                            label="Singapore"
                            id="Singapore"
                            control={<Checkbox style={{color: "#DB1F26"}}/>}
                            checked={(this.props.region.includes("sgp"))}
                            onChange={this.props.onChange}
                        />
                    </div>

                </Box>
                <Box>
                    <div className="regionSelect">
                        <FormControlLabel
                            style={{margin: "3% auto auto 2%"}}
                            value="ams"
                            label="Amsterdam"
                            id="Amsterdam"
                            control={<Checkbox style={{color: "#DB1F26"}}/>}
                            checked={(this.props.region.includes("ams"))}
                            onChange={this.props.onChange}
                        />
                    </div>
                    <div className="regionSelect">
                        <FormControlLabel
                            style={{margin: "3% auto auto 2%"}}
                            value="sf"
                            label="San Francisco"
                            id="San Francisco"
                            control={<Checkbox style={{color: "#DB1F26"}}/>}
                            checked={(this.props.region.includes("sf"))}
                            onChange={this.props.onChange}
                        />
                    </div>
                    <div className="regionSelect">
                        <FormControlLabel
                            style={{margin: "3% auto auto 2%"}}
                            value="fra"
                            label="Frankfurt"
                            id="frankfurt"
                            control={<Checkbox style={{color: "#DB1F26"}}/>}
                            checked={(this.props.region.includes("fra"))}
                            onChange={this.props.onChange}
                        />
                    </div>

                </Box>
                <Box>
                    <div className="regionSelect">
                        <FormControlLabel
                            style={{margin: "3% auto auto 2%"}}
                            value="tor"
                            label="Toronto"
                            id="Toronto"
                            control={<Checkbox style={{color: "#DB1F26"}}/>}
                            checked={(this.props.region.includes("tor"))}
                            onChange={this.props.onChange}
                        />
                    </div>
                    <div className="regionSelect">
                        <FormControlLabel
                            style={{margin: "3% auto auto 2%"}}
                            value="blr"
                            label="Bengaluru"
                            id="Bengaluru"
                            control={<Checkbox style={{color: "#DB1F26"}}/>}
                            checked={(this.props.region.includes("blr"))}
                            onChange={this.props.onChange}
                        />
                    </div>
                    <div className="regionSelect">
                        <Button className="lowerButton2" variant="primary" size="lg" block onClick={this.props.onClick}>
                            Next Step
                        </Button>

                    </div>
                </Box>
            </Box>}
            </>;
    }
}

RegionMapSelect.propTypes = {
    region: PropTypes.string,
    onChange: PropTypes.func,
    onClick: PropTypes.func
};