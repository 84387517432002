import { AuthContext } from './AuthProvider'
import { useContext } from 'react'

export const useAuth = () => {
  const { state, authApi, dispatch } = useContext(AuthContext)

  const login = props => {
    //    console.log('useAuth.login', props)
    authApi.login(props)
  }

  const logout = () => {
    console.log('logout')
    authApi.logout()
    dispatch({
      type: 'logout'
    })
  }

  const setLoginInit = () => {
    dispatch({
      type: 'setIsAuthenticating'
    })
  }

  const isAuthenticated = () => {
    return !!(
      state.expiresAt &&
      new Date().getTime() < state.expiresAt &&
      !state.isAuthenticating
    )
  }

  const setAuthenticated = () => {
    dispatch({ type: 'clearIsAuthenticating' })
  }

  return {
    isAuthenticating: state.isAuthenticating,
    isAuthenticated,
    setAuthenticated,
    user: state.user,
    userId: state.user ? state.user.sub : null,
    authResult: state.authResult,
    login,
    logout,
    setLoginInit,
    error: state.error,
    errorType: state.errorType
  }
}
