import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import React from 'react'

export default function MessageDialog({
  title,
  msg,
  open,
  onClose = () => {},
  renderButtons = null,
  renderContent = () => <></>
}) {
  const handleClose = () => {
    console.log('handleClose')
    onClose()
  }

  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth='xs'>
        <DialogTitle id='form-dialog-title'>{title}</DialogTitle>
        <DialogContent>
          {msg && <DialogContentText>{msg}</DialogContentText>}
          {renderContent()}
        </DialogContent>
        <DialogActions>
          {!renderButtons && (
            <Button variant='contained' color='primary' onClick={handleClose}>
              OK
            </Button>
          )}
          {renderButtons && renderButtons()}
        </DialogActions>
      </Dialog>
    </div>
  )
}
