import './index.css'
import 'typeface-roboto'

import * as serviceWorker from './serviceWorker'

import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client'
import { Route, BrowserRouter as Router } from 'react-router-dom'

import { ApolloProvider } from '@apollo/react-hooks'
import App from './App'
import { AuthProvider } from './auth/AuthProvider'
import { IntlProvider } from 'react-intl'
import React from 'react'
import ReactDOM from 'react-dom'
import { StripeProvider } from 'react-stripe-elements'
import { ToastProvider } from './components/toasts'
import { setContext } from 'apollo-link-context'

console.log(`Red5 Pro XDN ${process.env.REACT_APP_XDN_VERSION}`)
console.log('authorization', localStorage.getItem('authorization'))

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API_URL
})

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('authorization')
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token
    }
  }
})

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  defaultOptions: {
    query: {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    }
  }
})

ReactDOM.render(
  <>
    <IntlProvider locale={navigator.language}>
      <StripeProvider apiKey={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}>
        <ApolloProvider client={client}>
          <AuthProvider navigate={() => {}}>
            <ToastProvider>
              <Router>
                <Route component={App} />
              </Router>
            </ToastProvider>
          </AuthProvider>
        </ApolloProvider>
      </StripeProvider>
    </IntlProvider>
  </>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
