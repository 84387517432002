import React from "react";

export default class PricingCell extends React.Component {
    render() {
        return <tr>
            {
                !this.props.bottom &&
                <td className="pricingP"
                    style={{borderBottom: "1px solid rgba(196, 196, 196, .25)", fontSize: "24px"}}>
                    {
                        this.props.bold &&
                        <strong>{this.props.data}</strong>
                    }
                    {
                        !this.props.bold &&
                        this.props.data
                    }
                </td>}
            {
                this.props.bottom &&
                <td className="pricingP"
                    style={{fontSize: "24px"}}>
                    {
                        this.props.bold &&
                        <strong>{this.props.data}</strong>
                    }
                    {
                        !this.props.bold &&
                        this.props.data
                    }
                </td>}
        </tr>;
    }
}