import { CardElement, Elements, injectStripe } from 'react-stripe-elements'
import { Error, Loading } from '../components/Toast'
import React, { useState } from 'react'

import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import ErrorModal from './modals/ErrorModal'
import { GET_ACCOUNT } from '../models/account-api'
import { Redirect } from 'react-router-dom'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import clsx from 'clsx'
import gql from 'graphql-tag'
import { makeStyles } from '@material-ui/core/styles'
import { useAuth } from '../auth/useAuth'
import { useMutation } from '@apollo/react-hooks'
import { useQuery } from '@apollo/react-hooks'
import { useToasts } from '../components/toasts'

const useStyles = makeStyles(theme => ({
  form: {
    width: 500,
    padding: theme.spacing(1),
    marginBottom: 20,
    overflow: 'visible'
  },
  formField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '100%'
  },
  ccField: {
    marginTop: theme.spacing(2),
    border: `1px solid ${theme.palette.secondary[100]}`,
    padding: 10,
    borderRadius: 3
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
}))

const RETRY_PAYMENT = gql`
  mutation($userId: Int!, $token: String!) {
    retryPayment(userId: $userId, token: $token) {
      id
      creationDate
      nextRenewalDate
      subscriptionType
      description
      interval
      active
      suspendedDate
      paymentStatus
      invoiceId
      paymentIntentSecret
      serverLicense {
        id
        licenseKey
        expirationDate
        licenseType
        type {
          id
          name
        }
      }
      sdkLicense {
        id
        licenseType
        expirationDate
        licenseKey
        type {
          id
          name
        }
      }
    }
  }
`

function CreditCardForm({ stripe, subscription, card, user }) {
  const [name, setName] = useState('')
  const [errorDismissed, setErrorDismissed] = useState(false)
  const classes = useStyles({})
  const [retryPayment, retryPaymentResponse] = useMutation(RETRY_PAYMENT)

  async function createCardToken() {
    try {
      const stripeResponse = await stripe.createToken({ name })
      console.log(stripeResponse)
      return stripeResponse.token.id
    } catch (e) {
      // TODO: store error in state
      console.error(e)
    }
  }
  async function handleSubmit(e) {
    e.preventDefault()
    console.log('retrying payment')
    const token = await createCardToken()
    retryPayment({
      variables: {
        userId: user.id,
        token
      }
    })
  }

  function handleCancelClicked() {
    console.log('cancel clicked')
  }

  if (retryPaymentResponse.loading) {
    return (
      <Loading
        message='Retrying payment...'
        open={retryPaymentResponse.loading}
      />
    )
  }

  return (
    <form onSubmit={handleSubmit}>
      <Card className={classes.form}>
        <CardContent>
          <Typography gutterBottom>
            Your credit card was declined. Please enter a new card.
          </Typography>
          <Typography>
            Your failed card was: <b>{card.brand}</b> ... {card.last4}, expires:{' '}
            {card.expMonth} / {card.expYear},
          </Typography>
          <TextField
            id='cardholder-name'
            label='Card holder name'
            placeholder='Card holder name'
            className={classes.formField}
            margin='normal'
            value={name}
            onChange={e => setName(e.target.value)}
          />
          <div className={clsx(classes.formField, classes.ccField)}>
            <CardElement style={{ base: { fontSize: '14px' } }} />
          </div>
        </CardContent>
        <CardActions className={classes.actions}>
          <Button
            onClick={handleCancelClicked}
            variant='contained'
            color='secondary'
          >
            Cancel
          </Button>
          <Button type='submit' variant='contained' color='primary'>
            Retry payment
          </Button>
        </CardActions>
      </Card>
      {retryPaymentResponse.error && (
        <ErrorModal
          msg={retryPaymentResponse.error.message}
          open={!!retryPaymentResponse.error && !errorDismissed}
          onClose={() => setErrorDismissed(true)}
        />
      )}
    </form>
  )
}

const StripeForm = injectStripe(CreditCardForm)

export default function RetryPayment(props) {
  const { addToast } = useToasts()
  const { user } = useAuth()
  const { loading, error, data } = useQuery(GET_ACCOUNT, {
    variables: { userId: user.id }
  })
  if (loading) return <Loading open={loading} />
  if (error) {
    return <Error open={Boolean(error)} message={error.message} />
  }
  const account = data.account
  const subscription = account.subscriptions.find(
    s => s.paymentStatus !== 'succeeded'
  )
  if (!subscription) {
    addToast({
      message: 'Your subscription was created successfully',
      variant: 'success',
      open: true,
      horizontal: 'right',
      vertical: 'top'
    })
    return <Redirect to='/subscriptions' />
  }
  const card = account.customer && account.customer.creditCard
  return (
    <Elements>
      <StripeForm user={user} subscription={subscription} card={card} />
    </Elements>
  )
}
