import React, { useState } from 'react'

import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import DataEdit from './DataEdit'
import DataTable from './DataTable'
import Typography from '@material-ui/core/Typography'
import { hasMissingField } from '../models/utils'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingBottom: theme.spacing(2)
  },
  button: {},
  root: {
    maxWidth: 500,
    overflow: 'visible',
    paddingRight: theme.spacing(2)
  },
  table: {
    // minWidth: 650
  },
  indent: {
    paddingLeft: 30
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '100%'
  },
  title: {
    fontSize: '1.2em'
  }
}))

export default function DataView({
  title,
  titleClass,
  renderHeader,
  data,
  fields,
  onSave,
  onlyEdit = false,
  edit = false,
  cancel = true,
  cancelLabel = 'Cancel',
  editLabel = 'Edit',
  saveLabel = 'Save Changes',
  saveButtonVariant = 'text',
  alignHeaders = 'left'
}) {
  const [editing, setEditing] = useState(edit)
  const [hasValidationError, setValidationError] = useState(false)
  const classes = useStyles()
  let [values, setValues] = useState()
  values = values || data

  function handleCancelClicked() {
    setEditing(false)
    setValues(data)
  }
  function handleSaveClicked(e) {
    e.preventDefault()
    onSave(values)
    setEditing(false)
  }

  return (
    <>
      <Card className={classes.root}>
        <form onSubmit={handleSaveClicked}>
          <CardContent>
            {title && (
              <Typography
                className={titleClass || classes.title}
                variant='h5'
                // align='center'
                gutterBottom
              >
                {title}
              </Typography>
            )}
            {renderHeader && renderHeader()}
            {editing || edit ? (
              <DataEdit
                data={values}
                fields={fields}
                onChange={setValues}
                onValidationError={setValidationError}
                alignHeaders={alignHeaders}
              />
            ) : (
              !onlyEdit && (
                <DataTable
                  data={values}
                  fields={fields}
                  alignHeaders={alignHeaders}
                />
              )
            )}
          </CardContent>

          <Box className={classes.actions}>
            {editing || edit ? (
              <>
                {cancel && (
                  <Button
                    onClick={handleCancelClicked}
                    color='secondary'
                    className={classes.button}
                  >
                    {cancelLabel}
                  </Button>
                )}
                <Button
                  disabled={
                    Boolean(hasValidationError || hasMissingField(fields, values))
                  }
                  color='primary'
                  className={classes.button}
                  variant={saveButtonVariant}
                  type='submit'
                >
                  {saveLabel}
                </Button>
              </>
            ) : (
              <Button
                onClick={e => setEditing(true)}
                color='primary'
                className={classes.button}
              >
                {editLabel}
              </Button>
            )}
          </Box>
        </form>
      </Card>
    </>
  )
}
