import React, { useState, useEffect } from 'react';
import { ReactComponent as LoadingIcon } from "../assets/img/svg/loading.svg";
import {
  RTCPublisher,
  RTCSubscriber,
  setLogLevel,
  getRecordedLogs
} from 'red5pro-webrtc-sdk'
import { useAuth } from '../auth/useAuth'
import {
  useLazyQuery
} from '@apollo/react-hooks'
import {
  GET_XDN
} from '../models/account-api'
import ErrorModal from './modals/ErrorModal';
import { Loading } from '../components/Toast'
import Button from "@material-ui/core/Button";
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { wait } from '../models/utils'

const clipboardIcon = {
  fontSize: '20px',
  cursor: 'pointer'
}
const container = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
}
const videosContainer = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center"
};
 const videoWindow = {
  // border: "1px solid black",
  display: "flex",
  flexDirection: "column",
  margin: "0 10px"
 };
const nextButtonContainer = {
  position: 'relative',
  width: '100%',
  height: '56px',
  marginTop: '20px',
  marginBottom: '20px'
}
const webRTCTitles = {
    border: "1px solid black",
    background: "white",
    width: "100%",
    padding: "0",
    margin: "0",
    fontFamily: "Lato",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "40px",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: "0.05em",
    textTransform: "uppercase",
    color: "#000000",
};
const r5proVideo = {
  backgroundColor: "#000000",
  width: "426px",
  height: "240px"
};
const subDisclaimer = {
  textAlign: 'center',
  width: '100%',
  padding: '10px'
}
const notWorkingLink = {
  width: '100%',
  textDecoration: 'underline',
  textAlign: 'center',
  cursor: 'pointer'
}
const nextButton = {
  //margin: "0 5% 5% 0",
    width: "256px",
    height: "56px",
    backgroundColor: "#DB1F26",
    border: "#DB1F26 1px solid",
    fontFamily: "Lato",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "40px",
    textAlign: "center",
    letterSpacing: "0.05em",
    textTransform: "uppercase",
    color: "white",
    position: "absolute",
    right:    "0",
    top:   "20",
};
const notWorkingButton = {
    margin: "0 0 5% 5%",
    height: "56px",
    backgroundColor: "#DB1F26",
    border: "#DB1F26 1px solid",
    fontFamily: "Lato",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "40px",
    textAlign: "center",
    letterSpacing: "0.05em",
    textTransform: "uppercase",
    color: "white",
    position: "absolute",
    left:    "0",
    bottom:   "0",
};
const helpContainer = {
  textAlign: 'left',
  width: '860px',
  marginBottom: '15%',
}
const logContainer = {
  backgroundColor: 'white',
  padding: '20px',
  maxHeight: '480px',
  whiteSpace: 'nowrap',
  overflowX: 'scroll',
  overflowY: 'scroll',
  borderRadius: '4px',
  border: '1px solid rgb(230, 230, 230)'
}
const logHeader = {
  display: 'flex',
  flexDirection: 'columns',
  fontWeight: '500',
  alignItems: 'center'
}
const copiedText = {
  fontWeight: '400',
  color: '#DB1F26'
}
const logEntry = {
  lineHeight: '16px'
}

const validIP = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/
const localhost = /^localhost/
const isLocalhostOrIP = address => {
  return address.match(validIP) || address.match(localhost)
}
const getHostFromDomainName = domainName => {
  return domainName.replace(/(http|https):\/\//, '')
}
const errors = [] // error logs

export default function PubSubContainer ({ streamName, nextUrl }) {

  const [errorDismissed, setErrorDismissed] = useState(false)
  const [xdnError, setXDNError] = useState(null)
  const [showLogs, setShowLogs] = useState(null)
  const [isCopied, setIsCopied] = useState(false)
  const [showPublishLoad, setShowPublishLoad] = useState(true)
  const [showSubscribeLoad, setShowSubscribeLoad] = useState(true)
  const [logsCollapsed, setLogsCollapsed] = useState(false)

  const [host, setHost] = useState(undefined)
  const [publisher, setPublisher] = useState(null)
  const [subscriber, setSubscriber] = useState(null)

  const { user } = useAuth()
  const [loadXDNData, { loading, error, data }] = useLazyQuery(GET_XDN)

  useEffect(() => {
    loadXDNData({
      variables: {
        userId: user.id 
      },
      fetchPolicy: 'no-cache'
    })
  }, [])

  useEffect(() => {
    if (!data) return
    const displayError = (reason) => {
      onXDNError(`Could not establish a publishing session for ${user.email}: ${reason} Please, copy the logs and contact support.`, `(XND Data) ${JSON.stringify(data)}`)
    }
    try {
      const { red5XDN } = data
      const { lbDomainName } = red5XDN
      if (!lbDomainName) {
        displayError('No streaming endpoint found.')
      } else {
        // Attempt to use the public IP for insecure requests as DNS may not have resolved yet.
        const host = getHostFromDomainName(lbDomainName)
        setHost(host)
      }
    } catch (e) {
      displayError(typeof e === 'string' ? e : e.message)
    }
  }, [user, data])

  useEffect(() => {
    if (host) {
      setLogLevel('debug', true)
      startPublisher(host)
    }
    return () => {
      let element = document.querySelector('#red5pro-publisher')
      if (publisher) {
        publisher.off('*', onPublisherStatus)
        publisher.unpublish()
      } else if (element && element.srcObject) {
        let src = element.srcObject
        src.getTracks().forEach(t => t.stop())
        element.src = null
      }
    }
 }, [host, streamName])

  useEffect(() => {
    if (host) {
      startSubscriber(host)
    }
    return () => {
      console.log('SUBSCRIBER UNMOUNT')
      if (subscriber) {
        subscriber.off('*', onSubscriberStatus)
        subscriber.unsubscribe()
      }
    }
  }, [host, publisher, subscriber])

  useEffect(() => {
    if (errorDismissed) {
      onAccessLogs()
    }
  }, [errorDismissed])

  useEffect(() => {
    if (showLogs) {
      window.scrollTo(0, document.body.scrollHeight)
    }
  }, [showLogs])

  async function getNode (host, app, streamName, action) {
    var apiVersion = process.env.REACT_APP_R5PRO_SM_API_VERSION
    var isSecure = !isLocalhostOrIP(host)
    var baseUrl = `${(isSecure ? 'https' : 'http')}://${host}:${(isSecure ? 443 : 5080)}`
    var url = `${baseUrl}/streammanager/api/${apiVersion}/event/${app}/${streamName}?action=${action}`
    const response = await fetch(url)
    const json = await response.json()
    if (json.errorMessage) {
      throw new Error(json.errorMessage)
    }
    return json
  }

  async function startPublisher (host) {
    let localPublisher
    try {
      const { serverAddress } = await getNode(host, 'live', streamName, 'broadcast')
      if (!serverAddress) {
        throw new Error(`Could not access origin from ${host}.`)
      }
      localPublisher = new RTCPublisher()
      await localPublisher.init({
        host: host,
        protocol: isLocalhostOrIP(host) ? 'ws' : 'wss',
        port: isLocalhostOrIP(host) ? '5080' : '443',
        streamName: streamName,
        app: 'streammanager',
        connectionParams: {
          host: serverAddress,
          app: 'live'
        },
        mediaConstraints: {
          audio: true,
          video: {
            width: 640,
            height: 360
          }
        },
        clearMediaOnUnpublish: true
      })
      localPublisher.on('*', onPublisherStatus)
      await localPublisher.publish()
      setShowPublishLoad(false)
    } catch (e) {
      // TODO: Something with the error.
      console.error(e)
      const err = typeof e === 'string' ? e : e.message
      if (localPublisher) {
        localPublisher.unpublish()
      }
      onXDNError(`Could not establish a publishing session to ${host}: ${err}. Close this window to copy logs and contact support.`, err) 
    }
  }

  async function startSubscriber (host) {
    if (publisher && !subscriber) {
      try {
        await wait(2000)
        const { serverAddress } = await getNode(host, 'live', streamName, 'subscribe')
        if (!serverAddress) {
          throw new Error(`Could not access edge from ${host}.`)
        }
        const s = await new RTCSubscriber().init({
          host: host,
          protocol: isLocalhostOrIP(host) ? 'ws' : 'wss',
          port: isLocalhostOrIP(host) ? '5080' : '443',
          streamName: streamName,
          app: 'streammanager',
          connectionParams: {
            host: serverAddress,
            app: 'live'
          }
        })
        s.on('*', onSubscriberStatus)
        await s.subscribe()
        setShowSubscribeLoad(false)
      } catch (e) {
        console.log(e)
        const err = typeof e === 'string' ? e : e.message
        if (err.match(/Unable to locate stream/) || 
            err.match(/Could not secure minimum number of edges/)) {
          // Maybe has not established across cluster.
          startSubscriber(host)
        } else {
          onXDNError(`Could not start a subscribing session to ${host}: ${err}. Close this window to copy logs and contact support.`, err)
        }
      }
    }
  }

  function onXDNError (message, error) {
    setXDNError({ message })
    setShowPublishLoad(false)
    setShowSubscribeLoad(false)
    errors.push(`[ERROR]: ${error}`)
  }

  function onPublisherStatus (event) {
    const { type } = event
    console.log(`[RTCPublisher] :: ${type}`)
    if (type === 'Publish.Start') {
      setPublisher(event.publisher)
    }
  }

  function onSubscriberStatus (event) {
    const { type } = event
    if (type === 'Subscribe.Time.Update') {
      return
    } else if (type === 'Subscribe.Start') {
      setSubscriber(event.subscriber)
    }
    console.log(`[RTCSubscriber] :: ${type}`)
  }

  function onAccessLogs () {
    const logs = getRecordedLogs() || []
    setShowLogs([...logs, ...errors])
  }

  function onCollapseLogs () {
    setLogsCollapsed(true)
  }

  function onExpandLogs () {
    setLogsCollapsed(false)
  }

  if (loading) {
    return <Loading msg='Loading...' open={loading} />
  }

  async function copyToClipboard () {
    const toCopy = showLogs.join('\r\n')
    try {
      await navigator.clipboard.writeText(toCopy)
      setIsCopied(true)
      // Notify?
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <div style={container}>
      <div style={videosContainer}>
        <div style={videoWindow}>
          <h6 style={webRTCTitles}>Publisher</h6>
          <div style={{position: 'relative'}}>
            <video style={r5proVideo} id="red5pro-publisher" height="260" autoPlay={true} playsInline={true} muted></video>
            <LoadingIcon
              class="loading-icon"
              style={{display: showPublishLoad ? 'unset' : 'none'}}
              />
          </div>
        </div>
        <div style={videoWindow}>
          <h6 style={webRTCTitles}>Subscriber</h6>
          <div style={{position: 'relative'}}>
            <video style={r5proVideo} id="red5pro-subscriber" height="260" autoPlay={true} playsInline={true} controls muted></video>
            <LoadingIcon
              class="loading-icon"
              style={{display: showSubscribeLoad ? 'unset' : 'none'}}
            />
          </div>
          {/*<p style={subDisclaimer}>The playback of the video has been muted.<br/>Unmute in order to hear yourself.</p>*/}
          {/*<p style={notWorkingLink} onClick={onAccessLogs}>Not working?</p>*/}
          {/*<Button style={notWorkingButton} variant="primary" size="lg" block>
            <a style={{color: "white", textDecoration: "none",}} onClick={onAccessLogs}>Not Working?</a>
          </Button>*/}
        </div>
        {(xdnError || error) && (
          <ErrorModal
            title="Streaming Error"
            msg={(xdnError || error).message}
            open={(xdnError || error) && !errorDismissed}
            onClose={() => setErrorDismissed(true)}
          />
        )}
      </div>
      {showLogs && (
        <div style={helpContainer}>
          <p style={logHeader}>
            {!logsCollapsed && ( <KeyboardArrowUpIcon style={{cursor: 'pointer'}} onClick={onCollapseLogs} /> )}
            {logsCollapsed && ( <KeyboardArrowDownIcon style={{cursor: 'pointer'}} onClick={onExpandLogs} />)}
            <span>JavaScript Logs:&nbsp;&nbsp;</span>
            <AssignmentOutlinedIcon style={clipboardIcon} onClick={copyToClipboard}></AssignmentOutlinedIcon>
            {(isCopied && <span style={copiedText}>&nbsp;&nbsp;Copied to Clipboard!</span>)}
          </p>
          {!logsCollapsed && (
            <div style={logContainer}>{showLogs.map(log => <p style={logEntry}>{log}</p>)}</div>
          )}
          <Button style={notWorkingButton} variant="primary" size="lg" block>
            <a style={{color: "white", textDecoration: "none",}} href="https://red5pro.zendesk.com/hc/en-us/requests/new" target="_blank">Not Working? Contact Support</a>
          </Button>
       </div>
       )}
     {nextUrl && !(error||xdnError) && (
       <div style={nextButtonContainer}>
         <Button style={nextButton} variant="primary" size="lg" block>
           <a style={{color: "white", textDecoration: "none",}} href={nextUrl}>Next Step</a>
         </Button>
       </div>
      )}
    </div>

  )
}