import React, {useState, useEffect} from 'react'
import {Box, Grid} from '@material-ui/core'
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import MuiVirtualizedTable from '../../../components/MuiVirtualizedTable'
import ConfirmDialog from '../../../components/ConfirmDialog'
import './../xdnStyles.css';
import {
    useAuth
} from "../../../auth/useAuth";
import {
    // useMutation,
    useApolloClient,
    useQuery
} from "@apollo/react-hooks";
import {useInterval, wait} from '../../../models/utils'
import {statuses} from '../../../models/xdn'
import {
    GET_USER,
    GET_XDN,
    UPDATE_AUTHENTICATION,
    UPDATE_REGION,
    updateRed5XDNAuthorization,
    red5XDNDeployStatus,
} from "../../../models/account-api";

// import {
//     red5XDNAuthorization
// } from "../../../models/network-api";

import {
    createNewNodeGroup,
    deleteNodeGroup,
    getNodeGroups,
    getGroupState,
    getNodeGroupOrigins,
    getNodeGroupEdges,
    getStreamStats,
    getAllUsageStats,
    startOrGetOrigin
} from '../../../models/streammanager-api'
import {Error as ToastError, Loading} from "../../../components/Toast"
import ErrorModal from '../../modals/ErrorModal'
import MessageDialog from '../../modals/MessageDialog'
import {Button, TextField} from "@material-ui/core/";
import {makeStyles, withStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import {Redirect} from 'react-router-dom'
import moment from 'moment'
import {red} from "@material-ui/core/colors";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import NativeSelect from "@material-ui/core/NativeSelect";
import FormControl from "@material-ui/core/FormControl";
import RegionMapSelect from "../../../components/RegionMapSelect";
import {useMutation} from "@apollo/client";

const styles = (theme) => ({
    flexContainer: {
        display: 'flex',
        alignItems: 'center',
        boxSizing: 'border-box',
    },
    table: {
        // temporary right-to-left patch, waiting for
        // https://github.com/bvaughn/react-virtualized/issues/454
        '& .ReactVirtualized__Table__headerRow': {
            flip: false,
            paddingRight: theme.direction === 'rtl' ? '0 !important' : undefined,
        },
    },
    tableRow: {
        cursor: 'pointer',
    },
    tableRowHover: {
        '&:hover': {
            backgroundColor: theme.palette.grey[200],
        },
    },
    tableCell: {
        flex: 1,
    },
    noClick: {
        cursor: 'initial',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
});

const RedCheckbox = withStyles({
    root: {
        color: red[400],
        '&$checked': {
            color: red[600],
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);


const VirtualizedTable = withStyles(styles)(MuiVirtualizedTable);

const UPDATE_INTERVAL = 10000

const notWorkingButton = {
    height: "56px",
    backgroundColor: "#DB1F26",
    border: "#DB1F26 1px solid",
    fontFamily: "Lato",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "40px",
    textAlign: "center",
    letterSpacing: "0.05em",
    textTransform: "uppercase",
    color: "white"
}

const header = {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 'bolder',
    fontSize: '30px',
    lineHeight: '48px',
    letterSpacing: '0.02em',
    color: '#000000',
    textAlign: 'left',
    marginTop: '80px',
    marginLeft: '5%',
    marginBottom: 0,
}

const useStyles = makeStyles({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: "#F1F1F1",
        border: '2px solid #000',
        boxShadow: "0px 5.41176px 37.8824px rgba(0, 0, 0, 0.1)",
        padding: "2%",
    }
})

export default function Dashboard() {

    const previousVersionsArray = ["7.0.0", "7.0.1", "7.5.0"]

    function getDateTime() {
        const today = new Date();

        let month = (today.getMonth() + 1)
        if (month < 10) {
            month = "0" + month
        }

        let minutes = today.getMinutes()
        if (minutes < 10) {
            minutes = "0" + minutes
        }

        let hours = today.getHours()
        if (hours < 10) {
            hours = "0" + hours
        }

        let day = today.getDate()
        if (day < 10) {
            day = "0" + day
        }

        const date = today.getFullYear() + '-' + month + '-' + day;

        const time = hours + ":" + minutes;

        const dateTime = date + 'T' + time;
        return dateTime;
    }

    const dateTime = getDateTime();

    const classes = useStyles()
    const client = useApolloClient()
    const [streamStats, setStreamStats] = useState([])
    const [nodeGroups, setNodeGroups] = useState([])
    const [totalSubscribers, setTotalSubscribers] = useState(0)
    const [totalBandwidth, setTotalBandwidth] = useState(0)
    const [bandwidthUnit, setBandwidthUnit] = useState('GB')
    const [redirectLocation, setRedirectLocation] = useState('deploy')
    const [redirect, setRedirect] = useState(false)
    const [region, setRegion] = useState(['nyc'])
    const [streamManagerHost, setStreamManagerHost] = useState(undefined)
    const [confirmRefresh, setConfirmRefresh] = useState(false)
    const [nodeGroupToRefresh, setNodeGroupToRefresh] = useState(undefined)
    const [nodeGroupAdded, setNodeGroupAdded] = useState(undefined)
    const [nodeGroupsRemoved, setNodeGroupsRemoved] = useState([])
    const [pendingOperation, setPendingOperation] = useState(false)
    const [checkedAuth, setCheckedAuth] = useState(false)
    const {user} = useAuth()
    const [showSettings, setShowSettings] = useState(false)
    const [showHide, setShowHide] = useState("Show Settings")
    const [versionHistory, setVersionHistory] = useState(previousVersionsArray)
    const [versionToUpdateTo, setVersionToUpdateTo] = useState(undefined)
    const [currentVersion, setCurrentVersion] = useState("8.0.0")
    const [latestVersion, setLatestVersion] = useState("9.0.0")
    const [confirmUpdate, setConfirmUpdate] = useState(false)
    const [showSelect, setShowSelect] = useState(false)
    const [confirmToggleAuth, setConfirmToggleAuth] = useState(false)
    const [confirmPasswordAndChecked, setConfirmPasswordAndChecked] = useState(false)
    // const [updateRed5XDNAuthorization, updateRed5XDNAuthorizationResponse] = useMutation(
    //     UPDATE_AUTHENTICATION
    // )
    const [updateRed5XDNAuthorizationYes, updateRed5XDNAuthorizationResponse] = useState(
        false
    )
    // const [updateRegion, updateRegionResponse] = useMutation(
    //     UPDATE_REGION
    // )
    const [id, setId] = useState(0)
    const [enableAuthorization, setEnableAuthorization] = useState(false)
    const [rtaAuthorizationEndpoint, setRtaAuthorizationEndpoint] = useState("")
    const [simpleAuthorizationPassword, setSimpleAuthorizationPassword] = useState("")

    const [endpoint, setEndpoint] = useState("");
    const [endpointPresent, setEndpointPresent] = useState(false);

    const toggleSettings = () => {
        if (showSettings) {
            setShowSettings(false)
            setShowHide("Show Settings")
        } else {
            setShowSettings(true)
            setShowHide("Hide Settings")
        }
    }

    const handleEnableAuth = (event) => {
        if (checkedAuth) {
            setCheckedAuth(false)
        } else {
            setCheckedAuth(true)
        }
    };

    const handleChange = (event) => {
        const version = event.target.value;
        setVersionToUpdateTo(version)
        //code to change version of deployment
        onUpdateVersion(versionToUpdateTo)
    };

    const handlePassword = (event) => {
        const password = event.target.value;
        setSimpleAuthorizationPassword(password)

        if(password !== "" && checkedAuth && rtaAuthorizationEndpoint !== "") {
            setConfirmPasswordAndChecked(true)
        }
    }

    const handleEndpoint = (event) => {
        const endpoint = event.target.value;
        setRtaAuthorizationEndpoint(endpoint)

        if(simpleAuthorizationPassword !== "" && checkedAuth && rtaAuthorizationEndpoint !== "") {
            setConfirmPasswordAndChecked(true)
        }
    }

    const {loading, error, data} = useQuery(GET_XDN, {
        variables: {
            userId: user.id
        },
        fetchPolicy: 'no-cache'
    })
    const [isCurrentlyLaunching, setIsCurrentlyLaunching] = useState(false)
    const [smError, setSMError] = useState(undefined)
    const [launchError, setLaunchError] = useState(undefined)
    const [errorDismissed, setErrorDismissed] = useState(false)
    const [launchErrorDismissed, setLaunchErrorDismissed] = useState(false)
    const [pendingOperationDismissed, setPendingOperationDismissed] = useState(false)

    useInterval(() => {
        if (data && data.red5XDN && streamManagerHost) {
            const {
                red5XDN: {
                    lbDomainName,
                    streamManagerApiToken
                }
            } = data
            getStreamManagerInfo(lbDomainName, streamManagerApiToken)
        }
    }, UPDATE_INTERVAL)

    useEffect(() => {
        //    console.log('[xx] Data?', data)
        if (data) {
            const {red5XDN} = data
            if (!red5XDN) {
              setRedirectLocation('checkout/standard/year')
                setRedirect(true)
                return
            }
            const {
                status,
                regions,
                lbDomainName,
                streamManagerApiToken
            } = red5XDN

            setRegion([regions])
            if (status === statuses.unlaunched) {
                setRedirectLocation('deploy')
                setRedirect(true)
            }
            if (lbDomainName) {
                setStreamManagerHost(lbDomainName)
                getStreamManagerInfo(lbDomainName, streamManagerApiToken)
            }
            console.log(`Data got, not get status`, red5XDN)
            getDeployStatus(red5XDN)
        }
    }, [data])

    if (redirect) {
        return <Redirect to={`/${redirectLocation}`}/>
    }
    if (loading) {
        return <Loading msg='Loading...' open={loading}/>
    }
    if (error) {
        return <ToastError message={error.message} open={!!error}/>
    }

    async function getDeployStatus(xdn) {
        try {
            const {userId} = xdn
            const payload = await red5XDNDeployStatus(client, userId || user.id)
            const {
                red5XDNDeployStatus: {
                    status,
                    errorMessage
                }
            } = payload
            console.log('payload', payload)
            if (status === statuses.errored) {
                const msg = `There has been an error in launching your network!${errorMessage ? ' ' + errorMessage : ''}`
                setLaunchError({message: msg})
            } else {
                setIsCurrentlyLaunching([
                    statuses.errored,
                    statuses.unlaunched,
                    statuses.launched
                ].indexOf(status) === -1)
            }
        } catch (e) {
            console.error(e)
        }
    }

    async function getStreamManagerInfo(sm, token) {
        try {
            // Node Groups.
            let groups = await getNodeGroups(sm, token)
            const originsAndEdges = await Promise.all(
                groups.map(g => {
                    return Promise.all([
                        g.name,
                        getGroupState(sm, token, g.name),
                        getNodeGroupOrigins(sm, token, g.name),
                        getNodeGroupEdges(sm, token, g.name)
                    ])
                })
            )
            let rows = originsAndEdges.map(d => {
                if (d[1].state === 'terminating') return undefined
                return {
                    name: d[0],
                    status: d[1],
                    origins: d[2],
                    edges: d[3],
                    nodes: [...d[2], ...d[3]]
                }
            })
            rows = rows.filter(r => r !== undefined)
            //      console.log(`[xx] set node groups`)
            //      console.log(`[xx]`, rows)
            setNodeGroups(rows)

            // Need to wait until the new node groups are inservice before removing old node group.
            // Terraform limitation.
            const inservice = n => n.state === 'inservice'
            rows.forEach(r => {
                const {name, nodes} = r
                if (name === nodeGroupAdded && nodeGroupToRefresh && !confirmRefresh) {
                    //          console.log(`[xx] node group added ${nodeGroupAdded}`)
                    if (nodes.length === 0 || nodes.every(inservice)) {
                        //            console.log(`[xx] new group ${name} is inservice.`)
                        //            console.log(`[xx] remove node group ${nodeGroupToRefresh.name}`)
                        removeRefreshedNodeGroup(nodeGroupToRefresh.name)
                    }
                }
            })

            // Subscribers + Bandwidth
            const nowUTC = moment.utc(new Date()).valueOf()
            const stats = await getStreamStats(sm, token)
            const data = stats.map(s => {
                return {
                    streamName: s.name,
                    viewers: s.currentSubscribers
                }
            })
            let viewerStats = stats.filter(s => s.currentSubscribers > 0).map(s => {
                return {
                    totalSubscribers: s.currentSubscribers,
                    endTime: nowUTC,
                    startTime: s.startTime
                }
            })
            viewerStats = viewerStats && viewerStats.length > 0 ? viewerStats : []
            setStreamStats(data)

            const usage = await getAllUsageStats(sm, token)
            const totalMap = [...viewerStats, ...usage].map(u => u.totalSubscribers)
            if (totalMap && totalMap.length > 0) {
                const total = totalMap.reduce((a, c) => a + c)
                setTotalSubscribers(total)
            }
            let withSubscribers = usage.filter(u => u.totalSubscribers > 0)
            const bandwidth = [...viewerStats, ...withSubscribers].map(u => {
                return (u.totalSubscribers * (100 / 1024)) * ((u.endTime - u.startTime) / 1000)
            })
            if (bandwidth && bandwidth.length > 0) {
                let accBandwidth = bandwidth.reduce((a, c) => a + c)
                if (accBandwidth < 0.5) {
                    accBandwidth *= 1000
                    setBandwidthUnit('MB')
                } else {
                    setBandwidthUnit('GB')
                }
                setTotalBandwidth(accBandwidth.toPrecision(3))
            }
        } catch (e) {
            console.error(e)
            setSMError({message: typeof e === 'string' ? e : e.message})
            setErrorDismissed(false)
        }
    }

    async function removeRefreshedNodeGroup(groupName) {
        try {
            const {
                red5XDN: {
                    lbDomainName,
                    streamManagerApiToken
                }
            } = data
            //      console.log(`[xx] delete node group ${groupName}`)
            const index = nodeGroups.map(g => g.name).indexOf(groupName)
            const copy = [...nodeGroups]
            //      console.log(`[xx] remove node group at index: ${index}`)
            if (index > -1) {
                copy.splice(index, 1)
                setNodeGroups(copy)
            }
            //      console.log(`[xx] delete node group ${groupName}`)
            await deleteNodeGroup(lbDomainName, streamManagerApiToken, groupName)
            setPendingOperation(false)
        } catch (e) {
            console.error(e)
        }
        setNodeGroupToRefresh(undefined)
    }

    function onRefreshNodeGroup(group) {
        console.log('Group', group)
        setConfirmRefresh(true)
        setNodeGroupToRefresh(group)
    }

    function onUpdateVersion(version) {
        console.log(currentVersion + " -> " + version)
        setConfirmUpdate(true)
    }

    async function onConfirmRefreshNodeGroup(group) {
        console.log('Group', group)
        try {
            const {
                red5XDN: {
                    regions,
                    lbDomainName,
                    streamManagerApiToken
                }
            } = data

            setPendingOperation(true)
            const payload = await createNewNodeGroup(lbDomainName, streamManagerApiToken, regions)
            //      console.log(`[xx] new node group added: ${payload.name}`)
            //      console.log(`[xxx] node group added for removal ${group.name}`)
            nodeGroupsRemoved.push(group.name)
            setNodeGroupsRemoved(nodeGroupsRemoved)
            setNodeGroupAdded(payload.name)

            //      console.log(`[xx] get stream manager info...`)
            await getStreamManagerInfo(lbDomainName, streamManagerApiToken)
            //      console.log(`[xx] start new origin for ${payload.name}`)
            await startOrGetOrigin(lbDomainName, streamManagerApiToken, payload.name)
        } catch (e) {
            setSMError({message: typeof e === 'string' ? e : e.message})
            setErrorDismissed(false)
        }
        setConfirmRefresh(false)
    }

    // TODO: make an async function that updates version server side
    function onConfirmUpdateVersion(version) {
        console.log("Updating version...")
        setVersionHistory([...versionHistory, currentVersion])
        setCurrentVersion(version)
        setConfirmUpdate(false)
    }

    function onDenyRefreshNodeGroup(group) {
        setNodeGroupToRefresh(undefined)
    }

    function onDenyUpdateVersion() {
        setCurrentVersion(currentVersion)
    }

    async function onLaunchNewNodeGroup() {
        try {
            const {
                red5XDN: {
                    regions,
                    lbDomainName,
                    streamManagerApiToken
                }
            } = data
            const payload = await createNewNodeGroup(lbDomainName, streamManagerApiToken, regions)
            await getStreamManagerInfo(lbDomainName, streamManagerApiToken)
            await wait(5000)
            await startOrGetOrigin(lbDomainName, streamManagerApiToken, payload.name)
        } catch (e) {
            console.error(e)
            setSMError({message: typeof e === 'string' ? e : e.message})
            setErrorDismissed(false)
        }
    }

    function onDenyToggleAuth() {
        setConfirmToggleAuth(false)
    }

    async function toggleStreamAuth() {
        try {
            const {red5XDN} = data
            const updatedAuthorization = await updateRed5XDNAuthorization(client, parseInt(red5XDN.id,10), true, rtaAuthorizationEndpoint,
                simpleAuthorizationPassword)
        } catch (e) {
            console.error(e)
            setSMError({message: typeof e === 'string' ? e : e.message})
            setErrorDismissed(false)
            setConfirmToggleAuth(false)
        }
        setConfirmToggleAuth(false)
    }

    async function handleRegionSubmit(e) {
        alert("Submit Region Clicked")
        // try {
        //     const {red5XDN} = data
        //     // const updateRegionResponse = await updateRegion({ variables: { client: client, id: red5XDN.id, regions: region } })
        // } catch (e) {
        //     console.error(e)
        //     setSMError({message: typeof e === 'string' ? e : e.message})
        //     setErrorDismissed(false)
        //     setConfirmToggleAuth(false)
        // }
    }

    function handleRegionSelect(e) {
        const {
            target: {
                value
            }
        } = e
        localStorage.removeItem("region")
        localStorage.setItem('region', JSON.stringify([...region, value]))
        setRegion([...region, value])
        console.log(region)
    }

    return (
        <div className="viewBox">
            <h3 style={header}>Welcome, {user.firstName} {user.lastName}</h3>
            <Grid container>
                <Grid item xs={12} sm={12} md={5}
                      className="dashboardLeft">
                    {isCurrentlyLaunching && (
                        <div>
                            <h4 className="statsText">Your XDN at <a href={streamManagerHost}
                                                                     target="_blank">{streamManagerHost}</a> is
                                currently in deployment...</h4>
                            <Button style={notWorkingButton} variant="primary" size="large" block="true">
                                <a style={{color: "white", textDecoration: "none",}}
                                   href="https://red5pro.zendesk.com/hc/en-us/requests/new" target="_blank">Contact
                                    Support</a>
                            </Button>
                        </div>
                    )}
                    {(!streamManagerHost && !isCurrentlyLaunching) && (
                        <div>
                            <h4 className="statsText">We could not find your XDN deployment!</h4>
                            <Button style={notWorkingButton} variant="primary" size="large" block="true">
                                <a style={{color: "white", textDecoration: "none",}}
                                   href="https://red5pro.zendesk.com/hc/en-us/requests/new" target="_blank">Contact
                                    Support</a>
                            </Button>
                        </div>
                    )}
                    {(streamManagerHost && !isCurrentlyLaunching) && (
                        <h4 className="statsText">Your XDN deployment is at <a href={streamManagerHost}
                                                                               target="_blank">{streamManagerHost}</a>
                        </h4>
                    )}

                    <h5 className="settingsText" onClick={toggleSettings}>{showHide}</h5>
                    {showSettings && <div className="statsBox">
                        <p>Current Red5 Pro Server Version: {currentVersion}</p>
                        {currentVersion !== latestVersion &&
                        <p style={{color: "#DB1F26"}}>There is an update available! Update to {latestVersion}</p>}
                        <FormControlLabel style={{paddingLeft: "20px"}}
                            // className="resolution-image"
                            // value="top"
                                          control={<RedCheckbox checked={checkedAuth}
                                                                onChange={handleEnableAuth}
                                                                name="enableAuth"/>}
                                          label="Enable Authentication"
                            // labelPlacement="start"
                        />
                        <br/>
                        <TextField
                            id="standard-password-input"
                            required
                            label="Password For Auth"
                            type="password"
                            autoComplete="current-password"
                            onChange={handlePassword}
                            style={{marginLeft: 20, marginTop: -10, marginBottom: 10}}
                        />

                        <TextField
                            id="standard-required"
                            label="Custom Endpoint"
                            placeholder="Custom Endpoint"
                            onChange={handleEndpoint}
                            style={{marginLeft: 20, marginTop: -10, marginBottom: 10}}
                        />

                        {confirmPasswordAndChecked && <Button
                                onClick={() => {
                                toggleStreamAuth()
                                    //TODO: add promise handling
                                }}
                        className="refreshNodeButton" style={{padding: '5px 10px !important'}}
                        size="small">
                        Add Auth
                    </Button>}
                        <br/>
                        <span>
                            <FormControl className={classes.formControl} style={{paddingLeft: "20px"}}>
                    <NativeSelect
                        onChange={handleChange}
                        inputProps={{
                            name: "currentVersion",
                            id: 'currentVersion-native-simple',
                        }}
                    >
                      <option value="">Select Red5 Pro Deployment Version</option>
                      <option value="8.0.0">8.0.0</option>
                      <option value="8.1.0">8.1.0</option>
                      <option value="8.2.0">8.2.0</option>
                      <option value="8.3.0">8.3.0</option>
                      <option value="8.4.0">8.4.0</option>
                      <option value="8.5.0">8.5.0</option>
                      <option value="8.6.0">8.6.0</option>
                      <option value="9.0.0">9.0.0</option>
                    </NativeSelect>
                  </FormControl>
                  </span>

                        <p>Previous Versions: <span className="spaces">{
                            versionHistory.map(str => {
                                return (`${str} `)
                            })
                        }</span>
                        </p>

                        <form noValidate style={{paddingLeft: "20px", paddingBottom: "10px"}}>
                            <TextField
                                id="datetime-local"
                                label="Schedule Version Update: "
                                type="datetime-local"
                                defaultValue={dateTime}
                                className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </form>

                    </div>}
                    <h5 className="statsText">Current Statistics</h5>
                    <div className="statsBox">
                        <p>Total Subscriber Count: {totalSubscribers}</p>
                        <p>Total Bandwidth Usage: {totalBandwidth} {bandwidthUnit}</p>
                        <p style={{fontWeight: 'normal'}}>Bandwidth = (Subcribers * (100MB/1024)) * Seconds</p>
                    </div>
                    <div className="regionBox">
                        <div><h5>Regions</h5>
                            <Button
                                onClick={() => {
                                    setShowSelect(!showSelect)
                                }}
                                className="dashButton" size="lg" block>
                                {!showSelect && "Add Additional Region"}
                                {showSelect && "Do Not Add Region"}
                            </Button></div>
                        {/*<img src={map} alt="" style={{height: "auto", width: "100%"}}/>*/}
                        <Box alignItems="center"
                             justify="center" style={{textAlign: "center",}}>
                            <RegionMapSelect showSelect={showSelect} region={region} onChange={handleRegionSelect}
                                             onClick={handleRegionSubmit}/>
                        </Box>
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} md={5} className="dashboardRight">
                    <h5 className="statsText">Node Groups</h5>
                    {nodeGroups.length === 0 && (
                        <div>
                            <p style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-between'
                            }}>
                                <span style={{fontWeight: 'bold'}}>No Nodes Groups Found.</span>
                                {streamManagerHost && (
                                    <span><Button
                                        onClick={() => {
                                            onLaunchNewNodeGroup()
                                        }}
                                        className="refreshNodeButton" style={{padding: '5px 10px !important'}}
                                        size="small">
                          Launch New Group
                        </Button></span>
                                )}
                            </p>
                        </div>
                    )}
                    {nodeGroups.map(g => {
                        return (
                            <div key={g.name}
                                 className={(nodeGroupToRefresh && g.name === nodeGroupToRefresh.name) ? 'nodeGroupRemoval' : ''}>
                                <p style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'space-between'
                                }}>
                                    <span>{((nodeGroupToRefresh && g.name === nodeGroupToRefresh.name) ? 'Removing ' : '') + g.name}</span>
                                    {!nodeGroupToRefresh && (
                                        <span><Button
                                            onClick={() => {
                                                onRefreshNodeGroup(g)
                                            }}
                                            className="refreshNodeButton" style={{padding: '5px 10px !important'}}
                                            size="small">
                            Refresh
                        </Button></span>
                                    )}
                                </p>
                                {!g.nodes || g.nodes.length === 0 && (
                                    <div style={{fontWeight: 'bold'}}>No Active Nodes Found.</div>
                                )}
                                {g.nodes.length > 0 && (
                                    <div className="streamsBox" style={{height: `${(g.nodes.length + 1) * 48}px`}}>
                                        <Paper style={{height: "100%", width: '100%'}}>
                                            <VirtualizedTable
                                                rowCount={g.nodes.length}
                                                rowGetter={({index}) => g.nodes[index]}
                                                columns={[
                                                    {
                                                        width: 500,
                                                        label: 'Node Type',
                                                        dataKey: 'role',
                                                    },
                                                    {
                                                        width: 500,
                                                        label: 'IP',
                                                        dataKey: 'address',
                                                    },

                                                    {
                                                        width: 500,
                                                        label: 'Status',
                                                        dataKey: 'state',
                                                    },
                                                ]}
                                            />
                                        </Paper>
                                    </div>)}
                            </div>
                        )
                    })}
                    <h5 className="statsText">Active Streams</h5>
                    <div className="streamsBox" style={{height: `${(streamStats.length + 1) * 48}px`}}>
                        <Paper style={{height: "100%", width: '100%'}}>
                            <VirtualizedTable
                                rowCount={streamStats.length}
                                rowGetter={({index}) => streamStats[index]}
                                columns={[
                                    {
                                        width: 600,
                                        label: 'Stream Name',
                                        dataKey: 'streamName',
                                    },
                                    {
                                        width: 400,
                                        label: 'Number of Viewers',
                                        dataKey: 'viewers',
                                        numeric: true,
                                    },
                                ]}
                            />
                        </Paper>
                    </div>
                </Grid>
            </Grid>
            {smError && (
                <ErrorModal
                    title="Error"
                    msg={smError.message}
                    open={smError && !errorDismissed}
                    onClose={() => setErrorDismissed(true)}
                />
            )}
            {launchError && (
                <ToastError
                    message={launchError.message}
                    open={!!launchError && !launchErrorDismissed}
                    onClose={() => setLaunchErrorDismissed(true)}
                />
            )}
            <MessageDialog
                title="Warning"
                msg="Please do not navigate away or refresh this page while new Node Groups are being launched!"
                open={pendingOperation && !pendingOperationDismissed}
                onClose={() => setPendingOperationDismissed(true)}
            />
            <ConfirmDialog
                title="Refresh Node Group"
                open={confirmRefresh}
                setOpen={setConfirmRefresh}
                data={nodeGroupToRefresh}
                onConfirm={onConfirmRefreshNodeGroup}
                onDeny={onDenyRefreshNodeGroup}>
                <p>Are you sure you want to refresh the node group?</p>
                <p>Doing so will tear down the current node group and set up a new one.</p>
            </ConfirmDialog>
            <ConfirmDialog
                title="Update Server Version"
                open={confirmUpdate}
                setOpen={setConfirmUpdate}
                data={versionToUpdateTo}
                onConfirm={onConfirmUpdateVersion}
                onDeny={onDenyUpdateVersion}>
                <p>Are you sure you want to update the version of your server deployment?</p>
                <p>Doing so will tear down the current node group and set up a new one with the new version.</p>
            </ConfirmDialog>
            <ConfirmDialog
                title="Toggle Authentication"
                open={confirmToggleAuth}
                setOpen={setConfirmToggleAuth}
                data={versionToUpdateTo}
                onConfirm={toggleStreamAuth}
                onDeny={onDenyToggleAuth}>
                <p>Are you sure you want to add authentication to this cluster?</p>
                <p>Doing so will tear down the current node group and set up a new one with a password.</p>
            </ConfirmDialog>
        </div>
    )
}
