import {Loading, Toast} from '../components/Toast'
import React, {useEffect, useState} from 'react'
import {Redirect, Link as RouterLink} from 'react-router-dom'

import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import FormDialog from '../containers/modals/FormDialog'
import {INIT_PWD_RESET} from '../models/account-api'
import {Info} from '../components/Toast'
import Link from '@material-ui/core/Link'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import {isEmpty} from '../models/utils'
import {makeStyles} from '@material-ui/core/styles'
import {useAuth} from '../auth/useAuth'
import {useMutation} from '@apollo/react-hooks'

import "../index.css";

const useStyles = makeStyles(theme => ({
    image: {
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100vh',
        padding: 0
    },
    container: {
        position: 'relative',
        width: 450,
        top: theme.spacing(5),
        paddingBottom: theme.spacing(3),
        paddingLeft: 70,
        paddingRight: 70,
        margin: '10% auto'
    },
    header: {},
    errors: {
        marginTop: theme.spacing(2),
        borderLeft: `8px solid ${theme.palette.primary[500]}`,
        backgroundColor: theme.palette.secondary[50],
        paddingLeft: theme.spacing(2),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    actions: {
        display: 'flex',
        flexDirection: 'column'
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '100%'
    },
    dense: {
        marginTop: 19
    },
    menu: {
        width: 200
    },
    button: {
        margin: theme.spacing(1),
        backgroundColor: "#DB1F26",
        color: "white",
        border: "1px solid #DB1F26",
        transitionDuration: "0.7s",
        '&:hover': {
            color: "#DB1F26",
            backgroundColor: 'transparent',
            border: "1px solid #DB1F26",
        },
    },
    githubButton: {
        margin: theme.spacing(1),
        color: "#DB1F26",
        border: "1px solid #DB1F26",
        transitionDuration: "0.7s",
        '&:hover': {
            color: "white",
            backgroundColor: 'rgba(219, 31, 38, .8)',
            border: "1px solid #DB1F26",
        },
        '&::before': {
            backgroundColor: '#DB1F26',
            color: "#DB1F26",
        },
        '&::after': {
            backgroundColor: '#DB1F26',
            color: "#DB1F26",
        }
    },
    footer: {
        textAlign: 'center'
    },
    link: {
        textDecoration: 'none'
    },
    divider: {
        display: 'block',
        textAlign: 'center',
        margin: '2em 0',
        color: '#435d63',
        fontSize: '.9em',
        fontWeight: 500,
        width: '86%',
        '&::before, &::after': {
            backgroundColor: '#cad4d7',
            content: '""',
            display: 'inline-block',
            height: 1,
            position: 'relative',
            verticalAlign: 'middle',
            width: '50%'
        },
        '&::before': {
            right: '0.5em',
            marginLeft: '-50%'
        },
        '&::after': {
            left: '0.5em',
            marginRight: '-50%'
        }
    }
}))

export default function Login({history}) {
    const [error, setError] = useState(null)
    const {
        login,
        errorType,
        isAuthenticating,
        isAuthenticated,
        setLoginInit
    } = useAuth()
    const classes = useStyles({})
    const [values, setValues] = useState({
        email: '',
        password: '',
        loginInitiated: false
    })
    const [githubLogin, setGithubLogin] = useState(false)
    const [resetPasswordOpen, setResetPasswordOpen] = useState(false)
    const [email, setEmail] = useState()
    const [pwdResetDismissed, setPwdResetDismissed] = useState(false)
    const [initPasswordReset, initPasswordResetResponse] = useMutation(
        INIT_PWD_RESET
    )

    // calling setLoginInit() allows us to display the login page withour redirecting to the post-login page
    /* eslint-disable */
    useEffect(() => {
        setLoginInit()
    }, [])
    // redirect to the post-login page after login is done
    useEffect(() => {
        if (isAuthenticated() && values.loginInitiated) {
            history.push('/choice')
        }
    }, [values.loginInitiated, isAuthenticated()])

    const handleChange = name => event => {
        setValues({...values, [name]: event.target.value})
    }
    const handleSubmitLogin = e => {
        e.preventDefault()

        if (isEmpty(values.email) && isEmpty(values.password)) {
            setError('Email and password are required')
            return
        } else if (isEmpty(values.email)) {
            setError('Email is required')
            return
        } else if (isEmpty(values.password)) {
            setError('Password is required')
            return
        } else if (!/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)/.test(values.email)) {
            setError("Email is invalid, try again");
            return
        }  else if (error === 'Password is required' && !isEmpty(values.password) && !login({email: values.email, password: values.password})) {
            setError("Invalid credentials")
            return
        }
            setValues({...values, loginInitiated: true})
            login({email: values.email, password: values.password})

    }
    const handleGitHubLoginClicked = e => {
        setGithubLogin(true)
    }
    const handleForgotPasswordClicked = () => {
        setResetPasswordOpen(true)
    }
    const renderResetPasswordModalContent = () => {
        return (
            <>
                <TextField
                    autoFocus
                    margin='dense'
                    id='name'
                    label='Email Address'
                    type='email'
                    fullWidth
                    onChange={e => setEmail(e.target.value)}
                />
            </>
        )
    }
    const handleResetPassword = () => {
        if (typeof email === 'undefined' || email === null || email === "") {
            alert("Email is required, please try again.")
        }
        else if (!/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)/.test(email)) {
            alert("Email is invalid, try again");
        } else {
            console.log(`sending reset password email to ${email}`)
            initPasswordReset({variables: {email}})
            setResetPasswordOpen(false)
            setPwdResetDismissed(false)
        }
    }

    if (githubLogin) {
        return <Redirect to='/github-auth'/>
    }
    return (
        <div className={classes.image}>
            <Info
                message='Logging you in'
                open={isAuthenticating && values.loginInitiated}
            />
            <Card className={classes.container}>
                <form noValidate autoComplete='off' onSubmit={handleSubmitLogin}>
                    <CardContent>
                        <Box className={classes.header}>
                            <Typography align='center' variant='h4'>
                                Login
                            </Typography>
                        </Box>
                        {(error || errorType === 'login') && (
                            <Typography
                                variant='body1'
                                color='primary'
                                className={classes.errors}
                            >
                                {error ? error : 'Invalid credentials'}
                            </Typography>
                        )}
                        <TextField
                            label='Email'
                            className={classes.textField}
                            value={values.email}
                            onChange={handleChange('email')}
                            margin='normal'
                            required={true}
                        />
                        <TextField
                            label='Password'
                            defaultValue=''
                            onChange={handleChange('password')}
                            className={classes.textField}
                            margin='normal'
                            type='password'
                            required={true}
                        />
                    </CardContent>
                    <CardActions className={classes.actions}>
                        <Button
                            variant='contained'
                            color='primary'
                            fullWidth
                            className={classes.button}
                            type='submit'
                        >
                            Login
                        </Button>
                        {/* TODO: Implement Login with Github */}
                        {/*<span className={classes.divider}>or</span>*/}
                        {/*<Button*/}
                        {/*    variant='outlined'*/}
                        {/*    color='primary'*/}
                        {/*    fullWidth*/}
                        {/*    className={classes.githubButton}*/}
                        {/*    onClick={handleGitHubLoginClicked}*/}
                        {/*>*/}
                        {/*    Login with GitHub*/}
                        {/*</Button>*/}
                    </CardActions>
                    <Box className={classes.footer}>
                        <Typography variant='body2' gutterBottom>
                            <Link href='#' onClick={handleForgotPasswordClicked} style={{color: "#DB1F26"}}>
                                Forgot password?
                            </Link>
                        </Typography>
                        <Typography variant='body2'>
                            New to Red5 XDN?{' '}
                            <RouterLink className={classes.link} to='/register'>
                                <Link href='#' style={{color: "#DB1F26"}}>Create account</Link>
                            </RouterLink>
                        </Typography>
                    </Box>
                </form>
            </Card>
            <FormDialog
                title='Reset password'
                confirmButtonTitle='Send reset link'
                renderContent={renderResetPasswordModalContent}
                open={resetPasswordOpen}
                onConfirm={handleResetPassword}
                onCancel={() => setResetPasswordOpen(false)}
            />
            <Loading
                open={initPasswordResetResponse.loading}
                message='Triggering password reset..'
            />
            <Toast
                message='Password reset link was successfully sent to your email.'
                open={
                    initPasswordResetResponse.data &&
                    initPasswordResetResponse.data.initPasswordReset &&
                    !pwdResetDismissed
                }
                onClose={() => setPwdResetDismissed(true)}
                variant='info'
                vertical='top'
                horizontal='center'
            />
        </div>
    )
}
