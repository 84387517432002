import React, {useEffect} from 'react';
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import NativeSelect from "@material-ui/core/NativeSelect";
import FormControl from "@material-ui/core/FormControl";
import {makeStyles} from "@material-ui/core/styles";
import {invert} from "ramda";
import FlowProgressBar from "../../components/FlowProgressBar";

const useStyles = makeStyles(theme => ({
    formControl: {
        width: "50%",
        height: "50%",
        margin: "5% auto 5% 25%",
    },
}))

function strToBool(str) {
    let boolStr = (str === 'true');
    return boolStr;
}


export default function BuildAppXDN({history}) {
    const classes = useStyles()
    
    const [appType, setAppType] = React.useState("")
    const [appHTML, setAppHTML] = React.useState(strToBool(localStorage.getItem("html")))
    const [appIOS, setAppIOS] = React.useState(strToBool(localStorage.getItem("ios")))
    const [appAndroid, setAppAndroid] = React.useState(strToBool(localStorage.getItem("android")))
    const [backgroundColorButtonHTML, setBackgroundColorButtonHTML] = React.useState(localStorage.getItem("htmlBG"))
    const [colorHTML, setColorHTML] = React.useState(localStorage.getItem("htmlText"))
    const [backgroundColorButtonIOS, setBackgroundColorButtonIOS] = React.useState(localStorage.getItem("iosBG"))
    const [colorIOS, setColorIOS] = React.useState(localStorage.getItem("iosText"))
    const [backgroundColorButtonAndroid, setBackgroundColorButtonAndroid] = React.useState(localStorage.getItem("androidBG"))
    const [colorAndroid, setColorAndroid] = React.useState(localStorage.getItem("androidText"))
    const [clickedHTML, setClickedHTML] = React.useState(strToBool(localStorage.getItem("html")))
    const [clickedIOS, setClickedIOS] = React.useState(strToBool(localStorage.getItem("ios")))
    const [clickedAndroid, setClickedAndroid] = React.useState(strToBool(localStorage.getItem("android")))
    const [clicked, setClicked] = React.useState(false)

    const handleChange = (event) => {
        const appType = event.target.name;
        setAppType(appType)
        // setState({
            // ...state,
            // [appType]: event.target.value,
        // });
    };

    const storageUpdate = (key, value) => {
        localStorage.removeItem(key)
        localStorage.setItem(key, value)
    };

    const checkStorage = () => {
        if (localStorage.getItem("html") === "true") {
            setBackgroundColorButtonHTML("#000000")
            setColorHTML("#ffffff")
            setClickedHTML(true)
            setAppHTML(true)
            localStorage.removeItem("tutorialHTML")
            localStorage.setItem("tutorialHTML", "https://www.red5pro.com/docs/quickstarts/gswebrtc/overview/")
            storageUpdate("html", "true")
            storageUpdate("htmlBG", backgroundColorButtonHTML)
            storageUpdate("htmlText", colorHTML)
        } else {
            setBackgroundColorButtonHTML("#ffffff")
            setColorHTML("#000000")
            setClickedHTML(false)
            setAppHTML(false)
            localStorage.removeItem("tutorialHTML")
            storageUpdate("html", "false")
            storageUpdate("htmlBG", backgroundColorButtonHTML)
            storageUpdate("htmlText", colorHTML)
        }
        if (localStorage.getItem("ios") === "true") {
            setBackgroundColorButtonIOS("#000000")
            setColorIOS("#ffffff")
            setClickedIOS(true)
            setAppIOS(true)
            localStorage.removeItem("tutorialIOS")
            localStorage.setItem("tutorialIOS", "https://www.red5pro.com/docs/client/ios/")
            storageUpdate("ios", "true")
            storageUpdate("iosText", colorIOS)
            storageUpdate("iosBG", backgroundColorButtonIOS)
        } else {
            setBackgroundColorButtonIOS("#ffffff")
            setColorIOS("#000000")
            setClickedIOS(false)
            setAppIOS(false)
            localStorage.removeItem("tutorialIOS")
            storageUpdate("ios", "false")
            storageUpdate("iosText", colorIOS)
            storageUpdate("iosBG", backgroundColorButtonIOS)
        }
        if (localStorage.getItem("android") === "true") {
            setBackgroundColorButtonAndroid("#000000")
            setColorAndroid("#ffffff")
            setClickedAndroid(true)
            setAppAndroid(true)
            localStorage.removeItem("tutorialAndroid")
            localStorage.setItem("tutorialAndroid", "https://www.red5pro.com/docs/client/android/")
            storageUpdate("android", "true")
            storageUpdate("androidBG", backgroundColorButtonAndroid)
            storageUpdate("androidText", colorAndroid)
        } else {
            setBackgroundColorButtonAndroid("#ffffff")
            setColorAndroid("#000000")
            setClickedAndroid(false)
            setAppAndroid(false)
            localStorage.removeItem("tutorialAndroid")
            storageUpdate("android", "false")
            storageUpdate("androidBG", backgroundColorButtonAndroid)
            storageUpdate("androidText", colorAndroid)
        }
    }

    useEffect(() => {
        checkStorage()
        clickedBool()
    })

    const android = () => {
        if (clickedAndroid === false) {
                setBackgroundColorButtonAndroid("#000000")
                setColorAndroid("#ffffff")
                setClickedAndroid(true)
                setAppAndroid(true)
            localStorage.removeItem("tutorialAndroid")
            localStorage.setItem("tutorialAndroid", "https://www.red5pro.com/docs/client/android/")
            storageUpdate("android", "true")
            storageUpdate("androidBG", backgroundColorButtonAndroid)
            storageUpdate("androidText", colorAndroid)
        } else {
            setBackgroundColorButtonAndroid("#ffffff")
            setColorAndroid("#000000")
            setClickedAndroid(false)
            setAppAndroid(false)
            localStorage.removeItem("tutorialAndroid")
            storageUpdate("android", "false")
            storageUpdate("androidBG", backgroundColorButtonAndroid)
            storageUpdate("androidText", colorAndroid)
        }}

    const iOS = () => {
        if (clickedIOS === false) {
            setBackgroundColorButtonIOS("#000000")
            setColorIOS("#ffffff")
            setClickedIOS(true)
            setAppIOS(true)
            localStorage.removeItem("tutorialIOS")
            localStorage.setItem("tutorialIOS", "https://www.red5pro.com/docs/client/ios/")
            storageUpdate("ios", "true")
            storageUpdate("iosText", colorIOS)
            storageUpdate("iosBG", backgroundColorButtonIOS)
        } else {
            setBackgroundColorButtonIOS("#ffffff")
            setColorIOS("#000000")
            setClickedIOS(false)
            setAppIOS(false)
            localStorage.removeItem("tutorialIOS")
            storageUpdate("ios", "false")
            storageUpdate("iosText", colorIOS)
            storageUpdate("iosBG", backgroundColorButtonIOS)
        }}


    const htmlFunc = () => {
        if (clickedHTML === false) {
            setBackgroundColorButtonHTML("#000000")
            setColorHTML("#ffffff")
            setClickedHTML(true)
            setAppHTML(true)
            localStorage.removeItem("tutorialHTML")
            localStorage.setItem("tutorialHTML", "https://www.red5pro.com/docs/quickstarts/gswebrtc/overview/")
            storageUpdate("html", "true")
            storageUpdate("htmlBG", backgroundColorButtonHTML)
            storageUpdate("htmlText", colorHTML)
        } else {
            setBackgroundColorButtonHTML("#ffffff")
            setColorHTML("#000000")
            setClickedHTML(false)
            setAppHTML(false)
            localStorage.removeItem("tutorialHTML")
            storageUpdate("html", "false")
            storageUpdate("htmlBG", backgroundColorButtonHTML)
            storageUpdate("htmlText", colorHTML)
        }
    }

    const clickedBool = () => {
        if(clickedHTML || clickedIOS || clickedAndroid) {
            setClicked(true)
        } else {
            setClicked(false)
        }
    }


        const backgroundGrey = {
            background: "linear-gradient(91.81deg, #E0E0E0 1.29%, #F5F3F3 128.06%), #F3F2F2",
            opacity: "0.8",
            height: 'calc(100vh - 128px)',
            fallbacks: [
                {height: '-moz-calc(100vh - 128px)'},
                {height: '-webkit-calc(100vh - 128px)'},
                {height: '-o-calc(100vh - 128px)'}
            ],
            width: "100vw",
            // border: "1px solid black",
        }
        const borderStyleLeft = {
            // border: "1px solid black",
            // backgroundColor: "white",
            margin: "10% 25% 10% 25%",
            padding: "0 0 5% 0",
        };
        const h3Style = {
            fontFamily: "Lato",
            fontStyle: "normal",
            fontWeight: "bold",
            fontSize: "40px",
            lineHeight: "48px",
            letterSpacing: "0.02em",
            textTransform: "uppercase",
            color: "#000000",
            margin: "-15% 5% 5% 5%",
            textAlign: "center",
        };
        const buttonStyle1 = {
            margin: "0 5% 5% 0",
            width: "256px",
            height: "56px",
            backgroundColor: "#DB1F26",
            border: "#DB1F26 1px solid",
            fontFamily: "Lato",
            fontStyle: "normal",
            fontWeight: "bold",
            fontSize: "16px",
            lineHeight: "40px",
            textAlign: "center",
            letterSpacing: "0.05em",
            textTransform: "uppercase",
            color: "white",
            position: "absolute",
            right: "0",
            bottom: "0",
            '&:hover': {
                backgroundColor: "rgba(219, 31, 38, .8)",
                border: "rgba(219, 31, 38, .8)",
            }
        };
        const topBox = {
            margin: "1% 0 0 0",
            backgroundColor: "white",
            width: "100%",
        };
        const bottomBox = {
            backgroundColor: "white",
            margin: "1% 0 0 0",
            width: "100%",
        };
        const titleLeft = {
            margin: "3%",
            fontFamily: "Lato",
            fontStyle: "normal",
            fontWeight: "bold",
            fontSize: "24px",
            lineHeight: "56px",
            /* identical to box height, or 233% */
            letterSpacing: "0.02em",
            color: "#000000",
            textAlign: "center",
        };
        const links = {
            textDecoration: 'none',
            fontFamily: "Lato",
            fontStyle: "normal",
            fontWeight: "bold",
            fontSize: "16px",
            lineHeight: "40px",
            textAlign: "center",
            letterSpacing: "0.05em",
            textTransform: "uppercase",
            color: "#000000",
        };
        const navProg = {
            marginTop: "64px",
            height: "64px",
            width: "100vw",
            color: "black",
            backgroundColor: "#E2E2E2",
        };

        const progLink = {
            fontFamily: "Work Sans",
            height: "64px",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "14px",
            lineHeight: "24px",
            textAlign: "center",
            color: "#A0A0A0",
            mixBlendMode: "normal",
            margin: "16px 0 auto 0",
        };
        const navDark = {
            height: "64px",
            width: "75%",
            backgroundColor: "#171717",
        };
        const arrowBox = {
            width: "0",
            height: "64px",
            borderTop: "32px solid transparent",
            borderBottom: "32px solid transparent",
            borderLeft: "32px solid #171717",
        };
        const progLinkActive = {
            color: "#DB1F26",
            fontFamily: "Work Sans",
            height: "64px",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "14px",
            lineHeight: "24px",
            textAlign: "center",
            mixBlendMode: "normal",
            margin: "16px 0 auto 0",
        };
        const dot = {
            transform: "translateY(20%)",
            marginRight: "5px",
            height: "14px",
            width: "14px",
            backgroundColor: "#DB1F26",
            borderRadius: "50%",
            display: "inline-block",
        };
        const h6Style = {
            fontFamily: "Work Sans",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "16px",
            lineHeight: "32px",
            textAlign: "center",
            color: "#000000",
            opacity: "0.8",
            margin: "1% 10% 1% 10%",
            width: "80%"
        };

    //REQUIRED FOR PROGRESS BAR
    const progressArrowArray = [false, false, false, false, true, false, false]
    const progressActiveArray = [-1, -1, -1, -1, 1, 0]

        return (
            <div>
                <FlowProgressBar active={progressActiveArray} arrows={progressArrowArray}/>
                <Grid container style={backgroundGrey}>
                    <Grid
                        container
                        alignItems="center"
                        justify="center"
                    >
                        <div style={borderStyleLeft}>
                            <h3 style={h3Style}><span style={{color: "#DB1F26"}}>Red5</span> XDN</h3>
                            <div style={bottomBox}>
                                <Grid container><Grid xs={12}>
                                    <h6 style={titleLeft}>Select your platform</h6>
                                </Grid>
                                    <Grid xs={12}>
                                        <Button style={{height: "56px",
                                            margin: "2% 10%",
                                            width: "80%",
                                            border: "black 1px solid",
                                            borderRadius: "0",
                                            backgroundColor:  backgroundColorButtonHTML,
                                            color:  colorHTML
                                        }}
                                                onClick={htmlFunc}>
                                            html5
                                        </Button>
                                    </Grid>
                                    <Grid xs={12}>
                                        <Button style={{height: "56px",
                                            margin: "2% 10%",
                                            width: "80%",
                                            border: "black 1px solid",
                                            borderRadius: "0",
                                            backgroundColor:  backgroundColorButtonIOS,
                                            color:  colorIOS
                                        }}
                                                onClick={iOS}>
                                            iOS
                                        </Button>
                                    </Grid>
                                    <Grid style={{marginBottom: "5%"}} xs={12}>
                                        <Button style={{
                                            height: "56px",
                                            margin: "2% 10%",
                                            width: "80%",
                                            border: "black 1px solid",
                                            borderRadius: "0",
                                            backgroundColor:  backgroundColorButtonAndroid,
                                            color:  colorAndroid
                                        }}
                                                onClick={android}>
                                            Android
                                        </Button>
                                    </Grid>
                                </Grid>
                            </div>
                            <div style={topBox}>
                                <Grid container className="align-items-center">
                                    <Grid xs={12}>
                                        <h6 style={h6Style}>Select all the platforms that you would like to support.
                                            <br/> For
                                            those building a browser-based web app, select HTML5. For a native mobile
                                            application, you will need to select iOS and/or Android.
                                            <br/>
                                            Pick the type of applications you will be building so we can link you to
                                            documentation and examples to get you started.
                                            <br/>
                                            Or if you prefer you can go directly to our <a style={{color: "#DB1F26"}}
                                                                                           href="https://www.red5pro.com/developer-zone/">full
                                                documentation page.</a>
                                        </h6>
                                    </Grid>
                                    <Grid xs={12}>

                                        <FormControl className={classes.formControl}>
                                            <NativeSelect
                                                // value={ cloudProvider}
                                                onChange={handleChange}
                                                inputProps={{
                                                    name: "appType",
                                                    id: 'appType-native-simple',
                                                }}
                                            >
                                                <option value="">Select App</option>
                                                <option value="liveOneToManySports">Live Event One to Many/ Sports
                                                </option>
                                                <option value="liveOneToManyAnimalRacing">Live Event One to Many/
                                                    Horse/Dog
                                                    Racing
                                                </option>
                                                <option value="liveOneToManyConcertss">Live Event One to Many/ Concerts
                                                </option>
                                                <option value="liveOneToManyComedy">Live Event One to Many/ Comedy
                                                </option>
                                                <option value="liveOneToManyEsports">Large Live Event One to Many/
                                                    Esports
                                                </option>
                                                <option value="liveOneToFewSports">Small Live Event One to Few/ Esports
                                                </option>
                                                <option value="fewToFewVideoCalling">Video Calling (Few to Few)</option>
                                                <option value="oneToOneVideoCalling">Video Calling (one to one)</option>
                                                <option value="sportsBetting">Sports Betting</option>
                                                <option value="liveShopping">Live Shopping</option>
                                                <option value="watchParty">Watch Party</option>
                                                <option value="fanWall">Fan Wall</option>
                                                <option value="triviaGame">Trivia Game</option>
                                                <option value="socialMediaLiveStreaming">Social Media Live Streaming
                                                </option>
                                                <option value="telemedicine">Telemedicine</option>
                                                <option value="manyToFewSecurity">Monitoring / security (many to few)
                                                </option>
                                                <option value="virtualCasinoTables">Virtual Casino Tables</option>
                                                <option value="liveVideoEditing">Live Video Editing</option>
                                                <option value="liveAuctions">Live Auctions</option>
                                                <option value="webinar">Webinar</option>
                                                <option value="remoteLearning">Education / Remote Learning</option>
                                                <option value="camsite">Camsite</option>
                                                <option value="virtualConference">Virtual Conference</option>
                                                <option value="virtualTours">Virtual Tours</option>
                                                <option value="other">Other</option>
                                            </NativeSelect>
                                        </FormControl>

                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                        <Grid xs={8}>
                            { clicked &&
                            <Button style={buttonStyle1} variant="primary" size="lg" block onClick={() => {
                                console.log(appType)
                                localStorage.setItem("tutorial", "https://www.red5pro.com/docs/quickstarts/gswebrtc/overview/")
                                if (appHTML) {
                                    localStorage.removeItem("tutorialHTML")
                                    localStorage.setItem("tutorialHTML", "https://www.red5pro.com/docs/quickstarts/gswebrtc/overview/")
                                } else if (appIOS) {
                                    localStorage.removeItem("tutorialIOS")
                                    localStorage.setItem("tutorialIOS", "https://www.red5pro.com/docs/client/ios/")
                                } else if (appAndroid) {
                                    localStorage.removeItem("tutorialAndroid")
                                    localStorage.setItem("tutorialAndroid", "https://www.red5pro.com/docs/client/android/")
                                } else {
                                    localStorage.removeItem("tutorialAndroid")
                                    localStorage.removeItem("tutorialIOS")
                                    localStorage.removeItem("tutorialHTML")
                                    localStorage.removeItem("tutorial")
                                    localStorage.setItem("tutorial", "https://red5pro.com")
                                }
                                history.push("/finished-xdn")
                            }}
                            >Next Step
                                {/*<a style={{color: "white", textDecoration: "none",}} href="/finished-xdn">Next Step</a>*/}
                            </Button>
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        )
    }