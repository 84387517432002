import React from 'react';
import {Grid, Button} from "@material-ui/core";
import FlowProgressBar from "../../components/FlowProgressBar";

export default function FinishedXDN({history}) {

        let html = localStorage.getItem("html")
        let iOS = localStorage.getItem("ios")
        let android = localStorage.getItem("android")

        const backgroundGrey = {
            backgroundColor: "#FAFAFA",
            height: '100%',
            width: "100vw",
        }
        const borderStyleLeft = {
            // border: "1px solid black",
            height: "100%",
            // backgroundColor: "white",
            margin: "15% 2% 10% 5%",
            padding: "0 0 5% 0",
        };
        const h3Style = {
            fontFamily: "Lato",
            fontStyle: "normal",
            fontWeight: "bold",
            fontSize: "40px",
            lineHeight: "48px",
            letterSpacing: "0.02em",
            textTransform: "uppercase",
            color: "#000000",
            margin: "-15% 5% 5% 5%",
            textAlign: "center",
        };
        const h6Style = {
            fontFamily: "Lato",
            fontStyle: "normal",
            fontWeight: "bold",
            fontSize: "25px",
            lineHeight: "56px",
            /* identical to box height, or 233% */
            textAlign: "center",
            letterSpacing: "0.02em",
            textTransform: "uppercase",
            color: "#000000",
        };
        const buttonStyle1 = {
            margin: "5% 25% 5% 25%",
            width: "50%",
            backgroundColor: "#DB1F26",
            border: "#DB1F26",
            fontFamily: "Lato",
            fontStyle: "normal",
            fontWeight: "bold",
            fontSize: "16px",
            lineHeight: "40px",
            textAlign: "center",
            letterSpacing: "0.05em",
            textTransform: "uppercase",
            color: "#FFFFFF",
            borderRadius: "0",
        };

    //REQUIRED FOR PROGRESS BAR
    const progressArrowArray = [false, false, false, false, false, true, false]
    const progressActiveArray = [-1, -1, -1, -1, -1, 1]

        return (
            <div>
                <FlowProgressBar active={progressActiveArray} arrows={progressArrowArray}/>
                <Grid container style={backgroundGrey}>
                    <Grid
                        container
                        alignItems="center"
                        justify="center"
                    >
                        <Grid xs={6} style={borderStyleLeft}>
                            <h3 style={h3Style}><span style={{color: "#DB1F26"}}>Red5</span> XDN</h3>

                            <h6 style={h6Style}>CONGRATULATIONS! YOU ARE NOW RUNNING ON THE <span style={{color: "#DB1F26"}}>RED5</span> XDN.</h6>
                            {html === "true" && <h6 style={h6Style}>Here is your <a style={{color: "#DB1F26"}} href={localStorage.getItem("tutorialHTML")} target="popup" onClick={() => {window.open(localStorage.getItem("tutorialHTML"),'name','width=900,height=900')}}>HTML Tutorial</a></h6>}
                            {iOS === "true" && <h6 style={h6Style}>Here is your <a style={{color: "#DB1F26"}} href={localStorage.getItem("tutorialIOS")} target="popup" onClick={() => {window.open(localStorage.getItem("tutorialIOS"),'name','width=900,height=900')}}>iOS Tutorial</a></h6>}
                            {android === "true" && <h6 style={h6Style}>Here is your <a style={{color: "#DB1F26"}} href={localStorage.getItem("tutorialAndroid")} target="popup" onClick={() => {window.open(localStorage.getItem("tutorialAndroid"),'name','width=900,height=900')}}>Android Tutorial</a></h6>}


                            <Button style={buttonStyle1} variant="primary" size="lg" block>
                                <a style={{color: "white", textDecoration: "none",}} onClick={() => {
                                    localStorage.removeItem("finishedFlow");
                                    localStorage.setItem("finishedFlow", "true");
                                    history.push("/dashboard");
                                }}>Continue To Red5 XDN Dashboard</a>
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>

            </div>
        )
}