import * as R from 'ramda'

import React from 'react'
import { Toast } from './Toast'

// @ts-ignore
const Ctx = React.createContext()

export function ToastProvider({ children }) {
  const [toasts, setToasts] = React.useState([])

  const addToast = props => {
    if (toasts.find(t => t.id === props.id)) return

    const id = props.id || toasts.length + 1
    const toast = { id, ...props }
    setToasts([...toasts, toast])
  }

  const addLoading = props =>
    addToast(
      R.omit(['loading'], {
        ...props,
        message: 'Loading',
        variant: 'info',
        vertical: 'top',
        horizontal: 'center',
        open: props.loading || props.open
      })
    )

  const removeToast = id => {
    console.log('removing toast with id ' + id)
    const newToasts = toasts.filter(t => t.id !== id)
    if (newToasts.length !== toasts.length) {
      setToasts(newToasts)
    }
  }

  const onDismiss = id => () => removeToast(id)

  console.log('toasts', toasts)
  return (
    <Ctx.Provider value={{ addToast, addLoading, removeToast }}>
      {children}
      <>
        {toasts.map(({ id, ...rest }) => (
          <Toast key={id} onClose={onDismiss(id)} {...rest} />
        ))}
      </>
    </Ctx.Provider>
  )
}

export const useToasts = () => React.useContext(Ctx)
