import CardActions from '@material-ui/core/CardActions'
import { CardElement } from 'react-stripe-elements'
import React from 'react'
import TextField from '@material-ui/core/TextField'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  form: {
    width: 500,
    marginTop: 0,
    paddingRight: theme.spacing(2),
    marginBottom: 20,
    overflow: 'visible'
  },
  formField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '100%'
  },
  ccField: {
    marginTop: theme.spacing(2),
    border: `1px solid ${theme.palette.secondary[100]}`,
    padding: 10,
    borderRadius: 3
  },
  actions: {
    margin: theme.spacing(1),
    float: 'right'
  }
}))

export default function CreditCardEdit({ name, setName }) {
  const classes = useStyles({})

  return (
    <>
      <TextField
        id='cardholder-name'
        label='Card holder name'
        placeholder='Card holder name'
        className={classes.formField}
        margin='normal'
        value={name}
        onChange={e => setName(e.target.value)}
      />
      <div className={clsx(classes.formField, classes.ccField)}>
        <CardElement style={{ base: { fontSize: '14px' } }} />
      </div>
      <CardActions className={classes.actions}></CardActions>
    </>
  )
}
