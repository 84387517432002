import CardAuth from './containers/CardAuth'
import Checkout from './containers/Checkout'
import CssBaseline from '@material-ui/core/CssBaseline'
import Dashboard from './containers/hosted/dashboard/Dashboard'
// import Downloads from './containers/downloads/Downloads'
import {Elements} from 'react-stripe-elements'
import {GET_ACCOUNT} from './models/account-api'
import GithubAuthCallback from './auth/GithubAuthCallback'
import IconButton from '@material-ui/core/IconButton'
// import LaunchWizard from './containers/hosted/LaunchWizard'
import Login from './containers/Login'
import { XDN_PLANS } from './models/plans'
import {
    Link,
    // NavLink,
    Route, useLocation
} from 'react-router-dom'
import {MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles'

import AppBar from '@material-ui/core/AppBar'
import BillingView from './containers/billing/BillingView'
import MenuIcon from '@material-ui/icons/Menu'
// import MigrationCheckout from './containers/purchase/MigrationCheckout'
// import NetworkSettings from './containers/hosted/settings/NetworkSettings'
import PasswordReset from './containers/PasswordReset'
import ProfileView from './containers/profile/ProfileView'
import React from 'react'
import RegisterView from './containers/register/RegisterView'
import RetryPayment from './containers/RetryPayment'
// import SelectPlan from './containers/SelectPlan'
import StripeHookProvider from './components/StripeHookProvider'
import Toolbar from '@material-ui/core/Toolbar'
import blue from '@material-ui/core/colors/blue'
import blueGrey from '@material-ui/core/colors/blueGrey'
import {makeStyles} from '@material-ui/core/styles'
import red from '@material-ui/core/colors/red'
import {useAuth} from './auth/useAuth'
import {useLazyQuery} from '@apollo/react-hooks'
import Choice from './containers/register/Choice'
// import DeployPro from './containers/DeployPro'
// import ProCloudDeploy from "./containers/ProCloudDeploy";
// import FinishedPro from "./containers/FinishedPro";
import BuildAppXDN from "./containers/hosted/BuildAppXDN";
import FinishedXDN from "./containers/hosted/FinishedXDN";
import RegionPick from "./containers/hosted/RegionPick";
import PricingPage from "./containers/hosted/PricingPage";
import DeployXDN from "./containers/hosted/DeployXDN";
import PubSubTest from "./containers/hosted/PubSubTest";


import {ReactComponent as XDNLogo} from "../src/assets/img/svg/Red5XDN.svg";

const theme = createMuiTheme({
    palette: {
        primary: blue,
        secondary: blueGrey
    }
})
const drawerWidth = 150

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        zIndex: '1'
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0
        }
    },
    appBar: {
        display: 'none',
        marginLeft: drawerWidth,
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`
        },
        [theme.breakpoints.down('xs')]: {
            display: 'flex'
        }
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            display: 'none'
        }
    },
    // toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth
    },
    content: {
        width: '100vw',
        // border: '2px solid black',
        // flexGrow: 1
        // padding: theme.spacing(3)
    },
    iconRoot: {
        width: 20,
        minWidth: 20,
        color: '#eee'
    },
    profileIcon: {
        width: 30,
        minWidth: 30
    },
    // logo: {
    //   width: '100%'
    // },
    profile: {
        backgroundColor: '#333',
        color: '#eee',
        paddingBottom: theme.spacing(1)
    },
    profileItem: {
        paddingTop: 0,
        paddingBottom: 0
    },
    accountItem: {
        color: '#eee'
    },
    accountItemLink: {
        paddingLeft: 10,
        textDecoration: 'none'
    },
    username: {
        fontSize: 12
    },
    sidebarLink: {
        textDecoration: 'none',
        color: red[600],
        margin: 0
    },
    activeSidebarLink: {
        textWeight: 800,
        borderBottom: `4px solid ${red[200]}`
    },
    listItemDense: {
        paddingTop: 0,
        paddingBottom: 0
    },
    linkStyling: {
        margin: '0 5% 0',
        color: '#C0C0C0',
        textDecoration: 'none',
    },


    navbar: {
        height: "64px",
        backgroundColor: "#171717",
        width: "100%",
        margin: "0",
        padding: "0",
        color: "white",
        position: "fixed",
        top: "0",
        zIndex: "10000",
    },
    logoStyle: {
        marginTop: "16px",
        height: "32px",
    },
    logo: {
        float: "left",
        margin: "0 3% 0",
    },
    mobileLogoStyle: {
        position: "relative",
        left: "50%",
        top: "25%",
        WebkitTransform: "translateX(-50%)",
        MsTransform: "translateX(-50%)",
        transform: "translateX(-50%)",
        height: "32px",
    },
    mobileLogo: {
        height: "64px",
        width: "100%",
        backgroundColor: "#171717",
    },
    links: {
        float: "right",
        width: "40%",
        margin: "20px 0 auto 0",
    },
}))

const useContentAreaStyles = makeStyles(theme => ({
    contentRoot: {
        marginTop: theme.spacing(2),
        //        maxWidth: 1000,
        // padding: 16,
        // marginLeft: 32
    }
}))

function wrapContent(Component) {
    return props => {
        const classes = useContentAreaStyles()
        return (
            <div className={classes.contentRoot}>
                <Component {...props} />
            </div>
        )
    }
}

const routeConf = [
    // {
    //     route: '/',
    //     component: Login,
    //     exact: true,
    //     public: true
    // },
    {
        route: '/',
        component: wrapContent(Choice),
        exact: true,
        public: true
    },
    {
        route: '/oauth',
        component: GithubAuthCallback,
        public: true
    },
    {
        route: '/github-auth',
        component: () => {
            window.location.href =
                `${process.env.REACT_APP_GITHUB_AUTH_URL}?` +
                `response_type=code` +
                `&client_id=${process.env.REACT_APP_GITHUB_CLIENT_ID}` +
                `&redirect_uri=${process.env.REACT_APP_GITHUB_REDIRECT_URI}`
            return null
        },
        public: true
    },
    {
        route: '/passwordreset/:email/:token',
        component: wrapContent(PasswordReset),
        public: true
    },
    {
        text: 'Dashboard',
        route: '/dashboard',
        component: wrapContent(Dashboard)
    },
    {
        text: 'Subscriptions',
        route: '/subscriptions',
        component: wrapContent(Dashboard)
    },
    {
        route: '/checkout/:plan/:interval',
        component: wrapContent(Checkout)
    },
    {
        route: '/retry-payment',
        component: wrapContent(RetryPayment)
    },
    {
        route: '/card-auth',
        component: wrapContent(CardAuth)
    },
    {
        route: '/billing',
        component: wrapContent(BillingView)
    },
    {
        route: '/profile',
        component: wrapContent(ProfileView)
    },
    {
        route: '/register',
        exact: true,
        component: RegisterView,
        public: true
    },
    {
        route: '/register/:plan/:interval',
        component: RegisterView,
        public: true
    },
    {
        route: '/choice',
        component: wrapContent(Choice)
    },
    {
        route: '/xdn-build-app',
        component: wrapContent(BuildAppXDN)
    },
    {
        route: "/finished-xdn",
        component: wrapContent(FinishedXDN)
    },
    {
        route: "/xdn-pub-sub",
        component: wrapContent(PubSubTest)
    },
    {
        route: "/xdn-region",
        component: wrapContent(RegionPick)
    },
    {
        route: "/xdn-pricing",
        component: wrapContent(PricingPage),
        public: true
    },
    {
        route: "/deploy",
        component: wrapContent(DeployXDN)
    }
]

function setInitialStorage() {
  if (localStorage.getItem("set") !== process.env.REACT_APP_XDN_VERSION) {
        localStorage.setItem("recommendedPlan", XDN_PLANS.standard.title)
        localStorage.setItem("streamingEvents", "50")
        localStorage.setItem("duration", "25")
        localStorage.setItem("publishers", "0")
        localStorage.setItem("subscribers", "100")
        localStorage.setItem("liveStreamingHours", "500")
        localStorage.setItem("viewerHours", "125000")
        localStorage.setItem("bandwidthHour", ".0001875")
        localStorage.setItem("CDNBandwidth", "141250")
        localStorage.setItem("egress", "20250")
        localStorage.setItem("ingest", "405")
        localStorage.setItem("bandwidthTotal", "20655")
        localStorage.setItem("basicFee", "99")
        localStorage.setItem("additionalHours", "0")
        localStorage.setItem("additionalBandwidth", "0")
        localStorage.setItem("additionalEgress", "0")
        localStorage.setItem("abr", "false")
        localStorage.setItem("sdk", "false")
        localStorage.setItem("archive", "false")
        localStorage.setItem("mixer", "false")
        localStorage.setItem("sdkPrice", "0")
        localStorage.setItem("archivePrice", "0")
        localStorage.setItem("mixerPrice", "0")
        localStorage.setItem("cost", "99")
        localStorage.setItem("region", 'nyc')
        //currently only use the region item but when we allow multiple regions
        //this next code will come in handy
        localStorage.setItem("london", "false")
        localStorage.setItem("nyc", "true")
        localStorage.setItem("sgp", "false")
        localStorage.setItem("ams", "false")
        localStorage.setItem("sf", "false")
        localStorage.setItem("fra", "false")
        localStorage.setItem("tor", "false")
        localStorage.setItem("blr", "false")
        localStorage.setItem("set", "true")
        localStorage.setItem("ios", "false")
        localStorage.setItem("iosBG", "#ffffff")
        localStorage.setItem("iosText", "#000000")
        localStorage.setItem("html", "false")
        localStorage.setItem("htmlBG", "#ffffff")
        localStorage.setItem("htmlText", "#000000")
        localStorage.setItem("android", "false")
        localStorage.setItem("androidBG", "#ffffff")
        localStorage.setItem("androidText", "#000000")
        localStorage.setItem("finishedFlow", "false")
        localStorage.setItem("set", process.env.REACT_APP_XDN_VERSION)
    }
}


function App({container, match, history}) {


    function getRandomInt(max) {
        return Math.floor(Math.random() * Math.floor(max));
    }

    const mobileWarnings = [
        "Look we know how attached you are to your phone… but we really need you to put it away and view this site on a desktop.",
        "What’s the opposite of mobile-first-design? This. Switch to desktop for a better experience.",
        "Designed for mobile this is not. Switch to desktop you must. ",
        "Our mobile UX team is taking a nap. Please switch to a desktop… quietly.",
        "What kind of rinky-dink operation do you think we’re running here? Look at this on a full-sized desktop.",
        "FORBIDDEN CONTENT… actually we’re just not mobile optimized; only dramatic. Switch to a desktop to continue … to our forbidden content.",
        "You're gonna want to see this... on a bigger screen. Please switch to a desktop.",
        "This tiny screen cannot contain us. Gaze upon our full glory from a desktop.",
        "No-no-no-no-no. We need a bigger stage to do our thing. Please switch to a desktop to view this site.",
        "The future is vast and wide — not constrained on tiny little screens. Switch to a desktop. ",
        "For the last time: put your phone away. Switch to a desktop if you want to see this.",
        "Hey! Watch it! No, like really watch it… on a desktop.",
        "You caught us: we’re not mobile optimized. Please switch to a desktop.",
        "We refused to be constrained! Well, to a small screen at least. Please switch to a desktop to continue.",
    ]

    const i = getRandomInt(mobileWarnings.length)
    setInitialStorage()

    const classes = useStyles()
    const [mobileOpen, setMobileOpen] = React.useState(false)
    const {user, isAuthenticated, logout} = useAuth()
    const [getAccount, {data, error, called}] = useLazyQuery(GET_ACCOUNT)
    const customer = data && data.account.customer
    console.log('routeroute:', match)

    if (isAuthenticated && user && user.id && !called) {
        getAccount({
            variables: {userId: user.id}
        })
    }
    const getRoutes = () => routeConf.filter(r => isAuthenticated() || r.public)

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen)
    }

    const handleLogoutClicked = () => {
        logout()
        history.push('/login')
    }

    if (error) {
        // Check if server rejects our auth token. Our isAuthenticated() hook function only checks the token age.
        console.log('aijaijai error:', error)
    }

    const location = useLocation();
    console.log(location.pathname);

    return (
        <div className={classes.root}>
            <div className="mobile-warning">
                <div className={classes.mobileLogo}><a href="https://red5pro.com"><XDNLogo
                    className={classes.mobileLogoStyle}/></a></div>
                <p className="mobile-text">{
                    mobileWarnings[i]
                }</p></div>
            <div className="hide-on-mobile">
                <MuiThemeProvider theme={theme}>
                    <Elements>
                        <StripeHookProvider>
                            <>
                                <CssBaseline/>
                                <AppBar
                                    position='fixed'
                                    className={classes.appBar}
                                    color='primary'
                                >
                                    <Toolbar>
                                        <IconButton
                                            color='inherit'
                                            aria-label='open drawer'
                                            edge='start'
                                            onClick={handleDrawerToggle}
                                            className={classes.menuButton}
                                        >
                                            <MenuIcon/>
                                        </IconButton>
                                    </Toolbar>
                                </AppBar>
                                <main className={classes.content}>
                                    <nav>
                                        <div className={classes.navbar}>
                                            <div className={classes.logo}><a href="/"><XDNLogo
                                                className={classes.logoStyle}/></a></div>
                                            <div className={classes.links}>
                                                <a className={classes.linkStyling}
                                                   href="https://github.com/red5pro/red5pro-installer">Downloads</a>
                                                <a className={classes.linkStyling}
                                                   href="https://www.red5pro.com/docs/">Documentation</a>
                                                {isAuthenticated() && localStorage.getItem("finishedFlow") === "true" && (
                                                    <>
                                                        <Link className={classes.linkStyling} to='/dashboard'>
                                                            Dashboard
                                                        </Link>
                                                    </>
                                                )}
                                                {isAuthenticated() &&
                                                <>
                                                    <Link className={classes.linkStyling} href="/#"
                                                          onClick={handleLogoutClicked}>Logout</Link>
                                                </>
                                                }
                                                {!isAuthenticated() && (
                                                    <>
                                                        <Link className={classes.linkStyling}
                                                              to='/login'>Login</Link>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                        {/* <div className={classes.toolbar} /> */}
                                        {/* main content area */}
                                        <Route path='/login' component={Login}/>
                                        {

                                            getRoutes().map(link => (
                                                <Route
                                                    key={link.route}
                                                    path={link.route}
                                                    exact={link.exact || false}
                                                    component={link.component}
                                                />
                                            ))}
                                    </nav>
                                </main>
                            </>
                        </StripeHookProvider>
                    </Elements>
                </MuiThemeProvider>
            </div>
        </div>
    )
}

export default App