import * as R from 'ramda'

import gql from 'graphql-tag'

const plans = require('./plans')

export const GITHUB_LOGIN = gql`
  mutation($code: String!) {
    githubLogin(code: $code) {
      ... on User {
        id
        firstName
        lastName
        sessionToken
      }
      ... on UserStub {
        firstName
        lastName
        githubLogin
      }
    }
  }
`
export const INIT_PWD_RESET = gql`
  mutation($email: String!) {
    initPasswordReset(email: $email)
  }
`
export const GET_RESET_PASSWORD_TOKEN_STATUS = gql`
  query($email: String!, $token: String!) {
    getPasswordResetTokenStatus(email: $email, token: $token)
  }
`
export const RESET_PASSWORD = gql`
  mutation($email: String!, $token: String!, $password: String!) {
    resetPassword(email: $email, token: $token, password: $password)
  }
`
const AccountFields = gql`
  fragment AccountFields on AccountOverview {
    id
    user {
      id
      firstName
      lastName
      email
    }
    customer {
      id
      name
      address {
        city
        country
        line1
        line2
        postalCode
        state
      }
      creditCard {
        id
        customerId
        expMonth
        expYear
        last4
        brand
      }
      taxId {
        id
        country
        type
        value
        verificationStatus
      }
    }
    licenses {
      sdk {
        id
        expirationDate
        licenseKey
        licenseType
        type {
          id
          name
        }
      }
      server {
        id
        expirationDate
        licenseKey
        licenseType
        type {
          id
          name
        }
      }
    }
    subscriptions {
      id
      extCustomerId
      extSubscriptionId
      creationDate
      nextRenewalDate
      subscriptionType
      description
      interval
      active
      suspendedDate
      paymentStatus
      paymentIntentSecret
      invoiceId
      serverLicense {
        id
        licenseKey
        expirationDate
        type {
          id
          name
        }
      }
      sdkLicense {
        id
        licenseType
        expirationDate
        licenseKey
        type {
          id
          name
        }
      }
    }
  }
`

export const GET_ACCOUNT = gql`
  query($userId: Int!) {
    account(userId: $userId) {
      ...AccountFields
    }
  }
  ${AccountFields}
`

export const GET_ACCOUNT_BILLING_SUBSCRIPTIONS = gql`
  query($userId: Int!) {
    account(userId: $userId) {
      id
      customer {
        id
        name
        address {
          city
          country
          line1
          line2
          postalCode
          state
        }
        creditCard {
          id
          customerId
          expMonth
          expYear
          last4
          brand
        }
        taxId {
          id
          country
          type
          value
          verificationStatus
        }
      }
      subscriptions {
        id
        extCustomerId
        extSubscriptionId
        subscriptionType
        interval
        active
      }
      billingSubscriptions {
        id
        created
        interval
        currentPeriodEnd
        status
        items
      }
    }
  }
`

/*
export const CREATE_NETWORK_SUBSCRIPTION = gql`
  mutation(
    $token: String
    $customerId: String
    $customer: CustomerInput
    $taxId: TaxIdInput
    $userId: Int!
  ) {
    createNetworkSubscription(
      token: $token
      customerId: $customerId
      customer: $customer
      taxId: $taxId
      userId: $userId
    ) {
      invoiceId
      invoiceStatus
      paymentStatus
      paymentIntentSecret
      account {
        ...AccountFields
      }
    }
  }
  ${AccountFields}
`
*/

export const CREATE_SUBSCRIPTIONS = gql`
  mutation(
    $token: String
    $customerId: String
    $customer: CustomerInput
    $taxId: TaxIdInput
    $planNames: String!
    $userId: Int!
    $interval: String!
  ) {
    createLicenseSubscriptions(
      token: $token
      customerId: $customerId
      customer: $customer
      taxId: $taxId
      planNames: $planNames
      userId: $userId
      interval: $interval
    ) {
      status
      invoiceStatus
      paymentStatus
      invoiceId
      paymentIntentSecret
      account {
        id
        customer {
          id
          creditCard {
            id
            customerId
            expMonth
            expYear
            last4
            brand
          }
        }
        subscriptions {
          id
          creationDate
          nextRenewalDate
          subscriptionType
          description
          planName
          interval
          active
          suspendedDate
          serverLicense {
            id
            licenseKey
            expirationDate
            licenseType
            type {
              id
              name
            }
          }
          sdkLicense {
            id
            licenseKey
            expirationDate
            licenseType
            type {
              id
              name
            }
          }
        }
      }
    }
  }
`

export const CANCEL_SUBSCRIPTION = gql`
  mutation($id: Int!) {
    cancelSubscription(id: $id)
  }
`
export const UPDATE_SUBSCRIPTION = gql`
  mutation(
    $id: Int!
    $planName: String!
    $interval: String!
    $prorationDate: Float!
  ) {
    updateSubscription(
      id: $id
      planName: $planName
      interval: $interval
      prorationDate: $prorationDate
    ) {
      id
      interval
      active
      nextRenewalDate
      paymentStatus
      paymentIntentSecret
      invoiceId
      subscriptionType
      serverLicense {
        id
        licenseKey
        expirationDate
        licenseType
        type {
          id
          name
        }
      }
      sdkLicense {
        id
        licenseKey
        expirationDate
        licenseType
        type {
          id
          name
        }
      }
    }
  }
`

export const UPDATE_BILLING_INTERVAL = gql`
  mutation($extSubscriptionId: String!, $interval: String!) {
    updateBillingInterval(
      extSubscriptionId: $extSubscriptionId
      interval: $interval
    ) {
      id
      nextRenewalDate
      subscriptionType
      planName
      interval
    }
  }
`

export const UPDATE_CUSTOMER = gql`
  mutation($id: String!, $customer: CustomerInput!) {
    updateCustomer(id: $id, customer: $customer) {
      id
      name
      address {
        city
        country
        line1
        line2
        postalCode
        state
      }
      taxId {
        id
        country
        type
        value
      }
    }
  }
`
export const ADD_CARD = gql`
  mutation($userId: Int!, $token: String!) {
    addCard(userId: $userId, token: $token)
  }
`

export const GET_USER = gql`
  query($id: Int) {
    user(id: $id) {
      id
      accountID
      creationDate
      email
      firstName
      lastName
      githubLogin
      betaAccess
      userRole
      organization {
        id
        name
        website
      }
    }
  }
`
export const UPDATE_USER = gql`
  mutation(
    $id: Int
    $firstName: String
    $lastName: String
    $email: String!
    $password: String
    $organizationName: String
    $organizationUrl: String
  ) {
    updateUser(
      id: $id
      firstName: $firstName
      lastName: $lastName
      email: $email
      password: $password
      organizationName: $organizationName
      organizationUrl: $organizationUrl
    ) {
      id
      accountID
      creationDate
      email
      firstName
      lastName
      githubLogin
      betaAccess
      userRole
      organization {
        id
        name
        website
      }
    }
  }
`

export const CREATE_USER = gql`
  mutation(
    $firstName: String!
    $lastName: String!
    $email: String!
    $password: String!
    $organizationName: String
    $organizationUrl: String
    $githubLogin: String
  ) {
    createUser(
      firstName: $firstName
      lastName: $lastName
      email: $email
      password: $password
      organizationName: $organizationName
      organizationUrl: $organizationUrl
      githubLogin: $githubLogin
    ) {
      id
      accountID
      creationDate
      email
      firstName
      lastName
      githubLogin
      betaAccess
      userRole
      sessionToken
      organization {
        id
        name
        website
      }
    }
  }
`

export const CREATE_XDN_IF_NOT = gql`
  mutation(
    $userId: Int!
    $tag: String!
    $recommendedPlanId: Int!
    $regions: String!
    $instanceType: String!
    $adaptiveBitrate: Int
    $mixer: Int
    $trafficVariance: String
  ) {
    createRed5XDNIfNot(
      userId: $userId
      tag: $tag
      recommendedPlanId: $recommendedPlanId
      regions: $regions
      instanceType: $instanceType
      adaptiveBitrate: $adaptiveBitrate
      mixer: $mixer
      trafficVariance: $trafficVariance
    ) {
      id
      tag
      regions
      instanceType
      lbPublicIP
      lbDomainName
      streamManagerApiToken
      nodeApiToken
      nodeImageName
      status
    }
  }
`

export const UPDATE_XDN = gql`
  mutation(
    $id: Int!
    $tag: String
    $recommendedPlanId: Int
    $regions: String
    $instanceType: String
    $adaptiveBitrate: Int
    $mixer: Int
    $trafficVariance: String
    $lbPublicIP: String
    $lbDomainName: String
    $streamManagerApiToken: String
    $nodeApiToken: String
    $nodeImageName: String
    $status: String
  ) {
    updateRed5XDN(
      id: $id
      tag: $tag
      recommendedPlanId: $recommendedPlanId
      regions: $regions
      instanceType: $instanceType
      adaptiveBitrate: $adaptiveBitrate
      mixer: $mixer
      trafficVariance: $trafficVariance
      lbPublicIP: $lbPublicIP
      lbDomainName: $lbDomainName
      streamManagerApiToken: $streamManagerApiToken
      nodeApiToken: $nodeApiToken
      nodeImageName: $nodeImageName
      status: $status
    ) {
      id
      tag
    }
  }
`

export const LAUNCH_XDN = gql`
  mutation(
    $id: Int!
  ) {
    launchXDN(id: $id) {
      id
      status
    }
  }
`

export const GET_XDN = gql`
  query($userId: Int!) {
    red5XDN(userId: $userId) {
      id
      tag
      regions
      instanceType
      lbPublicIP
      lbDomainName
      streamManagerApiToken
      nodeApiToken
      nodeImageName
      status
      errorMessage
      sm1PublicName
    }
  }
`

export const GET_XDN_DEPLOY_STATUS = gql`
  query($userId: Int!) {
    red5XDNDeployStatus(userId: $userId) {
      id
      status
      lbDomainName
      isActivelyDeploying
      errorMessage
    }
  }
`

export const GET_DOWNLOADS = gql`
  query {
    downloads {
      serverUrl
    }
  }
`
export const GET_INVOICES = gql`
  query($extSubscriptionId: String!, $startingAfter: String, $limit: Int) {
    getInvoices(
      extSubscriptionId: $extSubscriptionId
      startingAfter: $startingAfter
      limit: $limit
    ) {
      totalCount
      invoices {
        id
        created
        amountDue
        amountPaid
        amountRemaining
        paid
        invoiceUrl
        invoicePdf
      }
    }
  }
`

export const GET_INVOICE_PREVIEW = gql`
  query(
    $filter: SubscriptionFilter!
    $plan: String
    $interval: String!
    $prorationDate: Float!
    $addPlan: Boolean!
  ) {
    invoicePreview(
      filter: $filter
      plan: $plan
      interval: $interval
      prorationDate: $prorationDate
      addPlan: $addPlan
    ) {
      amountDue
      nextPaymentAttempt
      prorationDate
      prorationCost
      startingBalance
      endingBalance
      tax
      subtotal
      total
      lines {
        amount
        description
        proration
        plan
        periodStart
      }
    }
  }
`

export const UPDATE_AUTHENTICATION = gql`
  mutation($xdnId: Int!, 
           $enableAuthorization: Boolean!,
           $rtaAuthorizationEndpoint: String,
           $simpleAuthorizationPassword: String) {
    updateRed5XDNAuthorization(
      id: $xdnId
      enableAuthorization: $enableAuthorization,
      rtaAuthorizationEndpoint: $rtaAuthorizationEndpoint,
      simpleAuthorizationPassword: $simpleAuthorizationPassword
    ) {
      id
      enableAuthorization
      rtaAuthorizationEndpoint
      simpleAuthorizationPassword
    }
  }
`

export async function launchXDN(client, xdnId) {
    const resp = await client.mutate({
        mutation: gql`
      mutation {
        launchXDN(id: ${xdnId}) {
          id
          status
        }
      }`
    })
    return resp.data
}

export async function red5XDNDeployStatus(client, userId) {
    const resp = await client.query({
        query: gql`
      query {
        red5XDNDeployStatus(userId: ${userId}) {
          id
          userId
          status
          lbDomainName
          isActivelyDeploying
          errorMessage
        }
      }`
    })
    return resp.data
}

export async function activateSubscriptions(client, invoiceId) {
    const resp = await client.mutate({
        // @ts-ignore
        mutation: gql`
      mutation {
        activateSubscriptions(
          invoiceId: "${invoiceId}"
        ) {
        id
        creationDate
        nextRenewalDate
        subscriptionType
        description
        interval
        active
        suspendedDate
        paymentStatus
        invoiceId
        paymentIntentSecret
        serverLicense {
          id
          licenseKey
          expirationDate
          licenseType
          type {
            id
            name
          }
        }
        sdkLicense {
          id
          licenseKey
          expirationDate
          licenseType
          type {
            id
            name
          }
        }
      }
    }`
    })
    return resp.data.activateSubscriptions
}

export function isServerSub(subscription) {
    return isServerType(subscription.subscriptionType)
}

export function isSdkSub(subscription) {
    return [4, 11].indexOf(subscription.subscriptionType) >= 0
}

export function isServerType(type) {
    return [4, 11, 20].indexOf(type) < 0
}

export function sortSubscriptions(subscriptions) {
    return {
        serverSub: subscriptions.find(isServerSub),
        sdkSub: subscriptions.find(isSdkSub)
    }
}

export function canChangeInterval({
                                      extSubscriptionId,
                                      subscriptionId,
                                      subscriptions
                                  }) {
    if (subscriptions.length <= 1) return true

    const sub = subscriptionId
        ? subscriptions.find(R.propEq('id', subscriptionId))
        : {extSubscriptionId}

    const combinedSubs = subscriptions.filter(
        R.propEq('extSubscriptionId', sub.extSubscriptionId)
    )
    const log = msg => R.tap(R.partial(console.log, [msg]))
    console.log('conmbinesSubs', combinedSubs)

    const hasPlan = plan =>
        !!R.pipe(
            R.map(R.prop('subscriptionType')),
            log('subscription types'),
            R.map(plans.getPrice),
            log('prices'),
            R.find(R.propEq('plan', plan))
        )(combinedSubs)

    // interval switching is not possible with the network and developer plans
    console.log('has combined network plan', hasPlan('network'))
    console.log('has combined developer plan', hasPlan('developer'))

    return !(hasPlan('network') || hasPlan('developer'))
}

export function hasCombinedSubs(subscriptionId, subscriptions) {
    const sub = subscriptions.find(R.propEq('id', subscriptionId))
    if (!sub) return false
    const combinedSubs = subscriptions.filter(
        R.propEq('extSubscriptionId', sub.extSubscriptionId)
    )
    return combinedSubs.length > 1
}

export async function updateRed5XDNAuthorization(client, xdnId, authEnabled, endpoint, password) {
    const resp = await client.mutate({
        // @ts-ignore
        mutation: gql`
      mutation {
        updateRed5XDNAuthorization(
        id: "${xdnId}", 
        enableAuthorization: "${authEnabled}", 
        rtaAuthorizationEndpoint: "${endpoint}", 
        simpleAuthorizationPassword: "${password}") {
          id,
          enableAuthorization,
          rtaAuthorizationEndpoint,
          simpleAuthorizationPassword
        }
      }`
    })
    return resp.data
}