import React, { useState, useEffect } from 'react'
import Grid from "@material-ui/core/Grid";
import {Button, Checkbox, FormControlLabel, Box} from "@material-ui/core";
import {Redirect} from 'react-router-dom'
import { useAuth } from '../../auth/useAuth'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { Error, Loading } from '../../components/Toast'
import { useToasts } from '../../components/toasts'
import {
  GET_XDN,
  UPDATE_XDN
} from '../../models/account-api'
import {
  instanceTypeFromRecommendation,
  trafficVarianceFromRecommendation,
  xdnStatusIsUnlaunched
} from '../../models/xdn'
import {
  planIdFromRecommendation
} from '../../models/plans'

import "./xdnStyles.css";

import map from "./maps/MAP.png"
import FlowProgressBar from "../../components/FlowProgressBar";
import * as PropTypes from "prop-types";
import RegionMapSelect from "../../components/RegionMapSelect";

const R = require('ramda')

const regionMap = Object.freeze({
  london: false,
  nyc: false,
  sgp: false,
  ams: false,
  sf: false,
  fra: false,
  tor: false,
  blr: false
})

// RegionMapSelect.propTypes = {
//     region: PropTypes.string,
//     onChange: PropTypes.func,
//     onClick: PropTypes.func
// };
export default function RegionPick(useRegion = "nyc") {

  const { user } = useAuth()
  const {addToast} = useToasts()

  const [errorDismissed, setErrorDismissed] = useState(false)

  const {loading, error, data} = useQuery(GET_XDN, {
      variables: { userId: user.id }
    }
  )
  const [redirect, setRedirect] = useState(false)
  const [region, setRegion] = useState('nyc')
  const [updateRed5XDN, updateRed5XDNResponse] = useMutation(
      UPDATE_XDN
  )

  let errorMsg = null

  useEffect(() => {
    if (loading === false && data) {
      console.log(data)
      const { red5XDN } = data
      if (red5XDN && !xdnStatusIsUnlaunched(red5XDN)) {
        setRedirect(true)
      } else if (red5XDN && red5XDN.regions) {
        const { regions } = red5XDN
        if (regionMap.hasOwnProperty(regions)) {
          localStorage.setItem('region', regions)
          setRegion(regions)
        }
      }
    }
  }, [loading, data])

  if (loading) return <div>Loading...</div>
  if (error) {
    const e = error
    errorMsg = e.message || e
  }

  if (updateRed5XDNResponse.loading) {
    return <Loading message='Processing XDN...' open={updateRed5XDNResponse.loading}/>
  }
  if (updateRed5XDNResponse.error) {
    const e = updateRed5XDNResponse.error
    errorMsg = e.message || e
  }

  function handleRegionSelect (e) {
    const {
      target: {
        value
      }
    } = e
    localStorage.setItem('region', value)
    setRegion(value)
  }

  async function handleRegionSubmit (e) {
    e.preventDefault()
    const {
      red5XDN
    } = data
    const recommendedPlan = localStorage.getItem("recommendedPlan")
    const variables = {
      id: red5XDN.id,
      regions: region,
      recommendedPlanId: planIdFromRecommendation(recommendedPlan),
      instanceType: instanceTypeFromRecommendation(recommendedPlan),
      trafficVariance: trafficVarianceFromRecommendation(recommendedPlan),
      adaptiveBitrate: 0, // TODO?
      mixer: 0 // TODO?
    }
    const mutation = R.partial(updateRed5XDN, [
      { variables }
    ])
    mutation()
  }

  const responseData = updateRed5XDNResponse && updateRed5XDNResponse.data
    ? updateRed5XDNResponse.data.updateRed5XDN
    : null

  if (!!responseData || redirect) {
    return <Redirect to='/deploy'/>
  }

    const progressArrowArray = [false, true, false, false, false, false, false]
    const progressActiveArray = [-1, 1, 0, 0, 0, 0]

  return (
    <div>
        <div>
            <FlowProgressBar active={progressActiveArray} arrows={progressArrowArray}></FlowProgressBar>
            <Grid container
                  className="backgroundGrey"
                  alignItems="center"
                  justify="center"
            >
                <Box
                    display="flex" flexDirection="row" justifyContent="left"
                    style={{width: "1120px", marginBottom: "-5%"}}>
                    <h3 className="h3Style">Pick Your Region</h3>
                </Box>
                <Grid container
                      alignItems="center"
                      justify="center"
                >
                    <Box display="flex" flexDirection="row" justifyContent="center" style={{width: "1120px"}}>
                        <Grid item xs={12} md={8}>
                            <h5
                                className="h5Style"
                            >Click on a region in which you would like your application to be installed. Please select
                                only one region. Once your account is set up, you can add more regions if needed.</h5>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            {/*<div className="{*/}
                            {/*    width: "352px", height: "48px", border: "1px solid #CECECE",*/}
                            {/*    boxSizing: "border-box",*/}
                            {/*    borderRadius: "100px",*/}
                            {/*}}>Search Bar*/}
                            {/*</div>*/}
                        </Grid>
                    </Box>
                </Grid>
                <RegionMapSelect showSelect={true} region={region} onChange={handleRegionSelect} onClick={handleRegionSubmit}/>
            </Grid>
        </div>
        {errorMsg && (
            <Error
                message={errorMsg}
                open={!!errorMsg && !errorDismissed}
                onClose={() => setErrorDismissed(true)}
            />
        )}
    </div>
        )

}