import React from 'react'

export default function DateFormatted({ value }) {
  return (
    <span>
      {value
        ? new Intl.DateTimeFormat('en-GB', {
            year: 'numeric',
            month: 'long',
            day: '2-digit'
          }).format(value)
        : ''}
    </span>
  )
}
