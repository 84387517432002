import React from "react";
import {Grid} from '@material-ui/core/';
import "./componentStyles.css"

class NavDark extends React.Component {
    render() {
        return <>
            <Grid item xs={3} className="navDark"> </Grid>
            {this.props.navArrow &&
            <div className="arrowBox"/>
            }
        </>
    }
}

class ProgressChunk extends React.Component {
    render() {
        return <>
            {this.props.active === 1 &&
            <>
                <Grid item xs={1} sm={1} md={1} lg={1}>
                    <h6 className="progLinkActive"><span className="dot"/>{this.props.text}</h6>
                </Grid>
            </>
            }
            {this.props.active === -1 &&
                <>
                    <Grid item xs={1} sm={1} md={1} lg={1} className="navDark">
                        <h6 className="progLink">✓{this.props.text}</h6>
                    </Grid>
                    {this.props.progArrow &&
                    <div className="arrowBox"/>
                    }
                </>
            }
            {this.props.active === 0 &&
            <>
                <Grid item xs={1} sm={1} md={1} lg={1}>
                    <h6 className="progLink">{this.props.text}</h6>
                </Grid>
            </>
            }
        </>
    }
}

export default class FlowProgressBar extends React.Component
{
    render()
    {
        return <Grid container className="navProg">
            <NavDark navArrow={this.props.arrows[0]}/>
            {/*this.props.active = 1 -> active 0 -> default -1 -> previous*/}
            <ProgressChunk active={this.props.active[0]} progArrow={this.props.arrows[1]} text={"Registration"}/>
            <ProgressChunk active={this.props.active[1]} progArrow={this.props.arrows[2]} text={"Setup Service"}/>
            <ProgressChunk active={this.props.active[2]} progArrow={this.props.arrows[3]} text={"Deploy"}/>
            <ProgressChunk active={this.props.active[3]} progArrow={this.props.arrows[4]} text={"Test Streams"}/>
            <ProgressChunk active={this.props.active[4]} progArrow={this.props.arrows[5]} text={"Create Your App"}/>
            <ProgressChunk active={this.props.active[5]} progArrow={this.props.arrows[6]} text={"Done"}/>
        </Grid>
    }
}